.m-top-0 {
    margin-top: 0;
}

.m-top-5 {
    margin-top: 5px;
}

.m-top-10 {
    margin-top: 10px;
}

.m-top-15 {
    margin-top: 15px;
}

.m-top-20 {
    margin-top: 20px;
}

.m-top-25 {
    margin-top: 25px;
}

.m-top-30 {
    margin-top: 30px;
}

.m-top-35 {
    margin-top: 35px;
}

.m-top-40 {
    margin-top: 40px;
}

.m-top-45 {
    margin-top: 45px;
}

.m-top-50 {
    margin-top: 50px;
}

.m-top-55 {
    margin-top: 55px;
}

.m-top-60 {
    margin-top: 60px;
}

.m-top-65 {
    margin-top: 65px;
}

.m-top-70 {
    margin-top: 70px;
}

.m-top-75 {
    margin-top: 75px;
}

.m-top-80 {
    margin-top: 80px;
}

.m-top-85 {
    margin-top: 85px;
}

.m-top-90 {
    margin-top: 90px;
}

.m-top-95 {
    margin-top: 95px;
}

.m-top-100 {
    margin-top: 100px;
}

.m-top-105 {
    margin-top: 105px;
}

.m-top-110 {
    margin-top: 110px;
}

.m-top-115 {
    margin-top: 115px;
}

.m-top-120 {
    margin-top: 120px;
}

.m-top-125 {
    margin-top: 125px;
}

.m-top-130 {
    margin-top: 130px;
}

.m-top-135 {
    margin-top: 135px;
}

.m-top-140 {
    margin-top: 140px;
}

.m-top-145 {
    margin-top: 145px;
}

.m-top-150 {
    margin-top: 150px;
}

.m-right-0 {
    margin-right: 0;
}

.m-right-5 {
    margin-right: 5px;
}

.m-right-10 {
    margin-right: 10px;
}

.m-right-15 {
    margin-right: 15px;
}

.m-right-20 {
    margin-right: 20px;
}

.m-right-25 {
    margin-right: 25px;
}

.m-right-30 {
    margin-right: 30px;
}

.m-right-35 {
    margin-right: 35px;
}

.m-right-40 {
    margin-right: 40px;
}

.m-right-45 {
    margin-right: 45px;
}

.m-right-50 {
    margin-right: 50px;
}

.m-right-55 {
    margin-right: 55px;
}

.m-right-60 {
    margin-right: 60px;
}

.m-right-65 {
    margin-right: 65px;
}

.m-right-70 {
    margin-right: 70px;
}

.m-right-75 {
    margin-right: 75px;
}

.m-right-80 {
    margin-right: 80px;
}

.m-right-85 {
    margin-right: 85px;
}

.m-right-90 {
    margin-right: 90px;
}

.m-right-95 {
    margin-right: 95px;
}

.m-right-100 {
    margin-right: 100px;
}

.m-right-105 {
    margin-right: 105px;
}

.m-right-110 {
    margin-right: 110px;
}

.m-right-115 {
    margin-right: 115px;
}

.m-right-120 {
    margin-right: 120px;
}

.m-right-125 {
    margin-right: 125px;
}

.m-right-130 {
    margin-right: 130px;
}

.m-right-135 {
    margin-right: 135px;
}

.m-right-140 {
    margin-right: 140px;
}

.m-right-145 {
    margin-right: 145px;
}

.m-right-150 {
    margin-right: 150px;
}

.m-bottom-0 {
    margin-bottom: 0;
}

.m-bottom-5 {
    margin-bottom: 5px;
}

.m-bottom-10 {
    margin-bottom: 10px;
}

.m-bottom-15 {
    margin-bottom: 15px;
}

.m-bottom-20 {
    margin-bottom: 20px;
}

.m-bottom-25 {
    margin-bottom: 25px;
}

.m-bottom-30 {
    margin-bottom: 30px;
}

.m-bottom-35 {
    margin-bottom: 35px;
}

.m-bottom-40 {
    margin-bottom: 40px;
}

.m-bottom-45 {
    margin-bottom: 45px;
}

.m-bottom-50 {
    margin-bottom: 50px;
}

.m-bottom-55 {
    margin-bottom: 55px;
}

.m-bottom-60 {
    margin-bottom: 60px;
}

.m-bottom-65 {
    margin-bottom: 65px;
}

.m-bottom-70 {
    margin-bottom: 70px;
}

.m-bottom-75 {
    margin-bottom: 75px;
}

.m-bottom-80 {
    margin-bottom: 80px;
}

.m-bottom-85 {
    margin-bottom: 85px;
}

.m-bottom-90 {
    margin-bottom: 90px;
}

.m-bottom-95 {
    margin-bottom: 95px;
}

.m-bottom-100 {
    margin-bottom: 100px;
}

.m-bottom-105 {
    margin-bottom: 105px;
}

.m-bottom-110 {
    margin-bottom: 110px;
}

.m-bottom-115 {
    margin-bottom: 115px;
}

.m-bottom-120 {
    margin-bottom: 120px;
}

.m-bottom-125 {
    margin-bottom: 125px;
}

.m-bottom-130 {
    margin-bottom: 130px;
}

.m-bottom-135 {
    margin-bottom: 135px;
}

.m-bottom-140 {
    margin-bottom: 140px;
}

.m-bottom-145 {
    margin-bottom: 145px;
}

.m-bottom-150 {
    margin-bottom: 150px;
}

.m-left-0 {
    margin-left: 0;
}

.m-left-5 {
    margin-left: 5px;
}

.m-left-10 {
    margin-left: 10px;
}

.m-left-15 {
    margin-left: 15px;
}

.m-left-20 {
    margin-left: 20px;
}

.m-left-25 {
    margin-left: 25px;
}

.m-left-30 {
    margin-left: 30px;
}

.m-left-35 {
    margin-left: 35px;
}

.m-left-40 {
    margin-left: 40px;
}

.m-left-45 {
    margin-left: 45px;
}

.m-left-50 {
    margin-left: 50px;
}

.m-left-55 {
    margin-left: 55px;
}

.m-left-60 {
    margin-left: 60px;
}

.m-left-65 {
    margin-left: 65px;
}

.m-left-70 {
    margin-left: 70px;
}

.m-left-75 {
    margin-left: 75px;
}

.m-left-80 {
    margin-left: 80px;
}

.m-left-85 {
    margin-left: 85px;
}

.m-left-90 {
    margin-left: 90px;
}

.m-left-95 {
    margin-left: 95px;
}

.m-left-100 {
    margin-left: 100px;
}

.m-left-105 {
    margin-left: 105px;
}

.m-left-110 {
    margin-left: 110px;
}

.m-left-115 {
    margin-left: 115px;
}

.m-left-120 {
    margin-left: 120px;
}

.m-left-125 {
    margin-left: 125px;
}

.m-left-130 {
    margin-left: 130px;
}

.m-left-135 {
    margin-left: 135px;
}

.m-left-140 {
    margin-left: 140px;
}

.m-left-145 {
    margin-left: 145px;
}

.m-left-150 {
    margin-left: 150px;
}

.p-left-0 {
    padding-left: 0;
}

.p-left-5 {
    padding-left: 5px;
}

.p-left-10 {
    padding-left: 10px;
}

.p-left-15 {
    padding-left: 15px;
}

.p-left-20 {
    padding-left: 20px;
}

.p-left-25 {
    padding-left: 25px;
}

.p-left-30 {
    padding-left: 30px;
}

.p-left-35 {
    padding-left: 35px;
}

.p-left-40 {
    padding-left: 40px;
}

.p-left-45 {
    padding-left: 45px;
}

.p-left-50 {
    padding-left: 50px;
}

.p-left-55 {
    padding-left: 55px;
}

.p-left-60 {
    padding-left: 60px;
}

.p-left-65 {
    padding-left: 65px;
}

.p-left-70 {
    padding-left: 70px;
}

.p-left-75 {
    padding-left: 75px;
}

.p-left-80 {
    padding-left: 80px;
}

.p-left-85 {
    padding-left: 85px;
}

.p-left-90 {
    padding-left: 90px;
}

.p-left-95 {
    padding-left: 95px;
}

.p-left-100 {
    padding-left: 100px;
}

.p-left-105 {
    padding-left: 105px;
}

.p-left-110 {
    padding-left: 110px;
}

.p-left-115 {
    padding-left: 115px;
}

.p-left-120 {
    padding-left: 120px;
}

.p-left-125 {
    padding-left: 125px;
}

.p-left-130 {
    padding-left: 130px;
}

.p-left-135 {
    padding-left: 135px;
}

.p-left-140 {
    padding-left: 140px;
}

.p-left-145 {
    padding-left: 145px;
}

.p-left-150 {
    padding-left: 150px;
}

.p-right-0 {
    padding-right: 0;
}

.p-right-5 {
    padding-right: 5px;
}

.p-right-10 {
    padding-right: 10px;
}

.p-right-15 {
    padding-right: 15px;
}

.p-right-20 {
    padding-right: 20px;
}

.p-right-25 {
    padding-right: 25px;
}

.p-right-30 {
    padding-right: 30px;
}

.p-right-35 {
    padding-right: 35px;
}

.p-right-40 {
    padding-right: 40px;
}

.p-right-45 {
    padding-right: 45px;
}

.p-right-50 {
    padding-right: 50px;
}

.p-right-55 {
    padding-right: 55px;
}

.p-right-60 {
    padding-right: 60px;
}

.p-right-65 {
    padding-right: 65px;
}

.p-right-70 {
    padding-right: 70px;
}

.p-right-75 {
    padding-right: 75px;
}

.p-right-80 {
    padding-right: 80px;
}

.p-right-85 {
    padding-right: 85px;
}

.p-right-90 {
    padding-right: 90px;
}

.p-right-95 {
    padding-right: 95px;
}

.p-right-100 {
    padding-right: 100px;
}

.p-right-105 {
    padding-right: 105px;
}

.p-right-110 {
    padding-right: 110px;
}

.p-right-115 {
    padding-right: 115px;
}

.p-right-120 {
    padding-right: 120px;
}

.p-right-125 {
    padding-right: 125px;
}

.p-right-130 {
    padding-right: 130px;
}

.p-right-135 {
    padding-right: 135px;
}

.p-right-140 {
    padding-right: 140px;
}

.p-right-145 {
    padding-right: 145px;
}

.p-right-150 {
    padding-right: 150px;
}

.p-top-0 {
    padding-top: 0;
}

.p-top-5 {
    padding-top: 5px;
}

.p-top-10 {
    padding-top: 10px;
}

.p-top-15 {
    padding-top: 15px;
}

.p-top-20 {
    padding-top: 20px;
}

.p-top-25 {
    padding-top: 25px;
}

.p-top-30 {
    padding-top: 30px;
}

.p-top-35 {
    padding-top: 35px;
}

.p-top-40 {
    padding-top: 40px;
}

.p-top-45 {
    padding-top: 45px;
}

.p-top-50 {
    padding-top: 50px;
}

.p-top-55 {
    padding-top: 55px;
}

.p-top-60 {
    padding-top: 60px;
}

.p-top-65 {
    padding-top: 65px;
}

.p-top-70 {
    padding-top: 70px;
}

.p-top-75 {
    padding-top: 75px;
}

.p-top-80 {
    padding-top: 80px;
}

.p-top-85 {
    padding-top: 85px;
}

.p-top-90 {
    padding-top: 90px;
}

.p-top-95 {
    padding-top: 95px;
}

.p-top-100 {
    padding-top: 100px;
}

.p-top-105 {
    padding-top: 105px;
}

.p-top-110 {
    padding-top: 110px;
}

.p-top-115 {
    padding-top: 115px;
}

.p-top-120 {
    padding-top: 120px;
}

.p-top-125 {
    padding-top: 125px;
}

.p-top-130 {
    padding-top: 130px;
}

.p-top-135 {
    padding-top: 135px;
}

.p-top-140 {
    padding-top: 140px;
}

.p-top-145 {
    padding-top: 145px;
}

.p-top-150 {
    padding-top: 150px;
}

.p-bottom-0 {
    padding-bottom: 0;
}

.p-bottom-5 {
    padding-bottom: 5px;
}

.p-bottom-10 {
    padding-bottom: 10px;
}

.p-bottom-15 {
    padding-bottom: 15px;
}

.p-bottom-20 {
    padding-bottom: 20px;
}

.p-bottom-25 {
    padding-bottom: 25px;
}

.p-bottom-30 {
    padding-bottom: 30px;
}

.p-bottom-35 {
    padding-bottom: 35px;
}

.p-bottom-40 {
    padding-bottom: 40px;
}

.p-bottom-45 {
    padding-bottom: 45px;
}

.p-bottom-50 {
    padding-bottom: 50px;
}

.p-bottom-55 {
    padding-bottom: 55px;
}

.p-bottom-60 {
    padding-bottom: 60px;
}

.p-bottom-65 {
    padding-bottom: 65px;
}

.p-bottom-70 {
    padding-bottom: 70px;
}

.p-bottom-75 {
    padding-bottom: 75px;
}

.p-bottom-80 {
    padding-bottom: 80px;
}

.p-bottom-85 {
    padding-bottom: 85px;
}

.p-bottom-90 {
    padding-bottom: 90px;
}

.p-bottom-95 {
    padding-bottom: 95px;
}

.p-bottom-100 {
    padding-bottom: 100px;
}

.p-bottom-105 {
    padding-bottom: 105px;
}

.p-bottom-110 {
    padding-bottom: 110px;
}

.p-bottom-115 {
    padding-bottom: 115px;
}

.p-bottom-120 {
    padding-bottom: 120px;
}

.p-bottom-125 {
    padding-bottom: 125px;
}

.p-bottom-130 {
    padding-bottom: 130px;
}

.p-bottom-135 {
    padding-bottom: 135px;
}

.p-bottom-140 {
    padding-bottom: 140px;
}

.p-bottom-145 {
    padding-bottom: 145px;
}

.p-bottom-150 {
    padding-bottom: 150px;
}

.h-5 {
    height: 5px;
}

.h-10 {
    height: 10px;
}

.h-15 {
    height: 15px;
}

.h-20 {
    height: 20px;
}

.h-25 {
    height: 25px;
}

.h-30 {
    height: 30px;
}

.h-35 {
    height: 35px;
}

.h-40 {
    height: 40px;
}

.h-45 {
    height: 45px;
}

.h-50 {
    height: 50px;
}

.h-55 {
    height: 55px;
}

.h-60 {
    height: 60px;
}

.h-65 {
    height: 65px;
}

.h-70 {
    height: 70px;
}

.h-75 {
    height: 75px;
}

.h-80 {
    height: 80px;
}

.h-85 {
    height: 85px;
}

.h-90 {
    height: 90px;
}

.h-95 {
    height: 95px;
}

.h-100 {
    height: 100px;
}

.h-105 {
    height: 105px;
}

.h-110 {
    height: 110px;
}

.h-115 {
    height: 115px;
}

.h-120 {
    height: 120px;
}

.h-125 {
    height: 125px;
}

.h-130 {
    height: 130px;
}

.h-135 {
    height: 135px;
}

.h-140 {
    height: 140px;
}

.h-145 {
    height: 145px;
}

.h-150 {
    height: 150px;
}

.cart-table .cart-single .remove a,
.color-primary,
.counter.counter--4 .counter_single .value,
.error-wrapper .error-contents p a:hover,
.nav_right_content .cart_module .cart__items .cart_info p span,
.nav_right_content .cart_module .cart__items .items .item_info>a:hover,
.nav_right_content .cart_module .cart__items .items .item_remove span,
.post--card .card-body .post-meta li a:hover,
.pricing .pricing__title h4,
.tab-content.tab--content2 .tab-pane .tab_text_module .tab_text_module__subtitle,
.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link.active,
.team-five .content h6 a:hover,
.timeline1 .happening .happening__period .wrapper .year,
footer.footer--light .widget ul.links li a:hover,
footer.footer4 .footer__small .info_links li a:hover,
footer.footer4.footer--light .footer__small .info_links li a:hover,
footer.footer4.footer--light .footer_menu_items ul li a:hover {
    color: #413aa4;
}

.bg-primary,
.dot-light .owl-dot.active span,
.filter-wrapper .filter-sort ul li.active,
.filter-wrapper .filter-sort ul li:hover,
.flip-card .flip-overlay2,
.go_top,
.intro-three:before,
.intro-two,
.nav-circle button:hover,
.nav-circle.nav-circle--light button:hover,
.nav-circle.nav-circle--lighten button:hover,
.nav-pills .nav-item .nav-link.active,
.nav-pills .nav-item .nav-link:hover,
.nav-square button:hover,
.nav-square.nav-square-dark button:hover,
.nav_right_content .cart_module .cart__items .items .item_remove:hover span,
.owl-dots .owl-dot.active span,
.play-btn:hover,
.portfolio-carousel-single figure figcaption .link:hover,
footer.footer4 .social.square ul li a:hover,
footer.footer4.footer--colored {
    background: #413aa4;
}

.blog-carousel-one .post-single .post-bottom a:hover,
.card .card-body h6 a:hover,
.card-four .card-body h5:hover a,
.card-product .card-body h6 a:hover,
.color-secondary,
.content--block4 h3 span,
.content-block--12 .highlighted-text .content p span,
.counter.counter--5 .counter_single .value,
.counter.counter--7 .counter_single .value,
.cta--five p span,
.error-wrapper .error-contents p a,
.icon-list2 .icon,
.intro-five h1 span,
.intro-four .intro-four--contents h1 span,
.intro-one h1 span,
.intro-three .intro-three--contents h1 span,
.list-inline-wrapper .list--inline li .icon span,
.nav-tabs .nav-item .nav-link.active,
.number-list li.numbers-roman:before,
.number-list li:before,
.post--card .card-body .post-meta li a,
.project-single .project-info .info-box h6,
.subtext,
.tab.tab--1 .tab_nav .nav .nav-link.active,
.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link.active,
.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link:hover,
.tab.tab--3 .tab_nav3 .nav li a.active,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link.active,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link:hover,
.tab.tab--7 .tab_nav7 .nav .nav-item .nav-link.active,
.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link.active,
.tab_event .tab_event__module .event_meta p span.la,
.team-five .content h6 a,
.timeline1 .happening .happening__period .wrapper .date,
.timeline2 .happening2 .happening2__period,
.video--one h3 span,
footer .footer__big .widget--latest-news ul li a:hover,
footer.footer--dark .footer__big .text_widget a,
footer.footer2 .widget.twit--widget a,
footer.footer2 .widget.twit--widget span.fab,
footer.footer4.footer--light .footer_menu_items ul li a {
    color: #06b590;
}

.bg-secondary,
.bullet_list:before,
.card--team1 .card-body .team-social li a:hover,
.custom-radio-three .custom-control-input:checked~.custom-control-label::after,
.custom-radio-two .custom-control-input:checked~.custom-control-label::after,
.dot-secondary.dot-light .owl-dot.active span,
.event-single .event-date,
.go_top:hover,
.icon-light .content-list .icon-circle span,
.nav-circle button,
.nav-square.nav-square-dark button,
.nav_right_content .cart_module .cart_count,
.owl-dots.dot-secondary .owl-dot.active span,
.portfolio-carousel-single figure figcaption .link,
.pricing .pricing__price,
.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link.active:before,
.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link:hover:before,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link.active:before,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link:hover:before,
.tab.tab--7 .tab_nav7 .nav .nav-item .nav-link.active:before,
.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link.active:before,
.toggle-switch input:checked+.slider,
footer.footer4.footer--colored .footer--small,
footer.footer4.footer--light .footer__small .social.square li a:hover {
    background: #413aa4;
}

.color-success {
    color: #35b53e;
}

.bg-success {
    background: #35b53e;
}

.apply-form .apply-form--header p span,
.blog-single-wrapper .post-pagination .next-post p a,
.blog-single-wrapper .post-pagination .prev-post p a,
.color-info,
.sidebar-post .post-single P span a {
    color: #377dff;
}

.bg-info {
    background: #377dff;
}

.color-warning,
.twitter-feeds .feed-single .feed-content .tags {
    color: #ffaf00;
}

.bg-warning {
    background: #ffaf00;
}

.color-danger {
    color: #e23636;
}

.bg-danger {
    background: #e23636;
}

.color-light,
.nav_right_content .cart_module .cart__items .items .item_remove:hover span,
footer .footer__big .widget--contact_info ul li span.la,
footer.footer--dark .footer__big .text_widget a:hover,
footer.footer2.footer--dark .widget.twit--widget a:hover {
    color: #fff;
}

.bg-light,
.event-single {
    background: #fff;
}

.blog-carousel-one .post-single .post-bottom a,
.card .card-body h6 a,
.card--eight .card-body h5 a,
.card--ten .card-body h6 a,
.color-dark,
.counter.counter--5 .counter_single .title,
.tab.tab--1 .tab_nav .nav .nav-link,
footer.footer2 .widget.twit--widget a:hover {
    color: #202428;
}

.bg-dark,
footer.footer2 .footer__small {
    background: #202428;
}

.color-footer-dark-text,
footer.footer--dark,
footer.footer--dark .footer__big .text_widget p,
footer.footer--dark .footer__big .widget ul.links li a,
footer.footer4 .footer4_text,
footer.footer4 .footer__small .info_links li a,
footer.footer4.footer--colored,
footer.footer5.footer--bw,
footer.footer5.footer--bw .footer__big .widget ul.links a {
    color: rgba(255, 255, 255, 0.5);
}

.bg-footer-dark-text,
footer.footer5.footer--bw .footer__big .widget .widget__title:before {
    background: rgba(255, 255, 255, 0.5);
}

.color-footer-dark {
    color: #111014;
}

.bg-footer-dark,
footer.footer--dark {
    background: #111014;
}

.btn-primary {
    -webkit-box-shadow: 0 10px 20px rgba(65, 58, 164, 0.2);
    box-shadow: 0 10px 20px rgba(65, 58, 164, 0.2);
}

.btn-secondary {
    -webkit-box-shadow: 0 10px 20px rgba(6, 181, 144, 0.2);
    box-shadow: 0 10px 20px rgba(6, 181, 144, 0.2);
}

.btn-success {
    -webkit-box-shadow: 0 10px 20px rgba(53, 181, 62, 0.2);
    box-shadow: 0 10px 20px rgba(53, 181, 62, 0.2);
}

.btn-info {
    -webkit-box-shadow: 0 10px 20px rgba(55, 125, 255, 0.2);
    box-shadow: 0 10px 20px rgba(55, 125, 255, 0.2);
}

.btn-warning {
    -webkit-box-shadow: 0 10px 20px rgba(255, 175, 0, 0.2);
    box-shadow: 0 10px 20px rgba(255, 175, 0, 0.2);
}

.btn-danger {
    -webkit-box-shadow: 0 10px 20px rgba(226, 54, 54, 0.2);
    box-shadow: 0 10px 20px rgba(226, 54, 54, 0.2);
}

.btn-light {
    -webkit-box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

.btn-dark {
    -webkit-box-shadow: 0 10px 20px rgba(32, 36, 40, 0.2);
    box-shadow: 0 10px 20px rgba(32, 36, 40, 0.2);
}

.outline-thick-primary {
    border: 2px solid rgba(65, 58, 164, 0.2);
    background: 0 0;
    color: #413aa4;
}

.outline-thick-secondary {
    border: 2px solid rgba(6, 181, 144, 0.2);
    background: 0 0;
    color: #06b590;
}

.outline-thick-success {
    border: 2px solid rgba(53, 181, 62, 0.2);
    background: 0 0;
    color: #35b53e;
}

.outline-thick-info {
    border: 2px solid rgba(55, 125, 255, 0.2);
    background: 0 0;
    color: #377dff;
}

.outline-thick-warning {
    border: 2px solid rgba(255, 175, 0, 0.2);
    background: 0 0;
    color: #ffaf00;
}

.outline-thick-danger {
    border: 2px solid rgba(226, 54, 54, 0.2);
    background: 0 0;
    color: #e23636;
}

.outline-thick-light {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: 0 0;
    color: #fff;
}

.outline-thick-dark {
    border: 2px solid rgba(32, 36, 40, 0.2);
    background: 0 0;
    color: #202428;
}

.checkbox-primary .custom-control-label::before {
    background: #413aa4;
}

.checkbox-primary .custom-control-input:checked~.custom-control-label::before {
    background: #413aa4;
    border: 1px solid #413aa4;
}

.checkbox-primary .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-secondary .custom-control-label::before {
    background: #06b590;
}

.checkbox-secondary .custom-control-input:checked~.custom-control-label::before {
    background: #06b590;
    border: 1px solid #06b590;
}

.checkbox-secondary .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-success .custom-control-label::before {
    background: #35b53e;
}

.checkbox-success .custom-control-input:checked~.custom-control-label::before {
    background: #35b53e;
    border: 1px solid #35b53e;
}

.checkbox-success .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-info .custom-control-label::before {
    background: #377dff;
}

.checkbox-info .custom-control-input:checked~.custom-control-label::before {
    background: #377dff;
    border: 1px solid #377dff;
}

.checkbox-info .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-warning .custom-control-label::before {
    background: #ffaf00;
}

.checkbox-warning .custom-control-input:checked~.custom-control-label::before {
    background: #ffaf00;
    border: 1px solid #ffaf00;
}

.checkbox-warning .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-danger .custom-control-label::before {
    background: #e23636;
}

.checkbox-danger .custom-control-input:checked~.custom-control-label::before {
    background: #e23636;
    border: 1px solid #e23636;
}

.checkbox-danger .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-light .custom-control-label::before {
    background: #fff;
}

.checkbox-light .custom-control-input:checked~.custom-control-label::before {
    background: #fff;
    border: 1px solid #fff;
}

.checkbox-light .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-dark .custom-control-label::before {
    background: #202428;
}

.checkbox-dark .custom-control-input:checked~.custom-control-label::before {
    background: #202428;
    border: 1px solid #202428;
}

.checkbox-dark .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-outline-primary .custom-control-label:before {
    border: 1px solid #413aa4;
}

.checkbox-outline-primary .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #413aa4;
}

.checkbox-outline-primary .custom-control-input:checked~.custom-control-label::after {
    color: #413aa4;
}

.checkbox-outline-secondary .custom-control-label:before {
    border: 1px solid #06b590;
}

.checkbox-outline-secondary .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #06b590;
}

.checkbox-outline-secondary .custom-control-input:checked~.custom-control-label::after {
    color: #06b590;
}

.checkbox-outline-success .custom-control-label:before {
    border: 1px solid #35b53e;
}

.checkbox-outline-success .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #35b53e;
}

.checkbox-outline-success .custom-control-input:checked~.custom-control-label::after {
    color: #35b53e;
}

.checkbox-outline-info .custom-control-label:before {
    border: 1px solid #377dff;
}

.checkbox-outline-info .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #377dff;
}

.checkbox-outline-info .custom-control-input:checked~.custom-control-label::after {
    color: #377dff;
}

.checkbox-outline-warning .custom-control-label:before {
    border: 1px solid #ffaf00;
}

.checkbox-outline-warning .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #ffaf00;
}

.checkbox-outline-warning .custom-control-input:checked~.custom-control-label::after {
    color: #ffaf00;
}

.checkbox-outline-danger .custom-control-label:before {
    border: 1px solid #e23636;
}

.checkbox-outline-danger .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #e23636;
}

.checkbox-outline-danger .custom-control-input:checked~.custom-control-label::after {
    color: #e23636;
}

.checkbox-outline-light .custom-control-label:before {
    border: 1px solid #fff;
}

.checkbox-outline-light .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #fff;
}

.checkbox-outline-light .custom-control-input:checked~.custom-control-label::after {
    color: #fff;
}

.checkbox-outline-dark .custom-control-label:before {
    border: 1px solid #202428;
}

.checkbox-outline-dark .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #202428;
}

.checkbox-outline-dark .custom-control-input:checked~.custom-control-label::after {
    color: #202428;
}

.biz_overlay {
    position: relative;
    height: 100%;
    width: 100%;
}

.biz_overlay:before {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
}

.biz_overlay.overlay--primary:before {
    background-color: rgba(65, 58, 164, 0.85);
}

.biz_overlay.overlay--secondary:before {
    background-color: rgba(6, 181, 144, 0.8);
}

.biz_overlay.overlay--secondary2:before {
    background: rgba(27, 28, 57, 0.8);
}

.biz_overlay.overlay--dark:before {
    background-color: rgba(32, 36, 40, 0.6);
}

.biz_overlay.overlay--dark2:before {
    background: rgba(29, 30, 51, 0.7);
}

.gradient-overlay {
    position: relative;
}

.gradient-overlay:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(310deg, rgba(65, 58, 164, 0.9) 0, rgba(65, 58, 164, 0.9) 25%, rgba(6, 181, 144, 0.9) 100%);
    background: -o-linear-gradient(310deg, rgba(65, 58, 164, 0.9) 0, rgba(65, 58, 164, 0.9) 25%, rgba(6, 181, 144, 0.9) 100%);
    background: linear-gradient(140deg, rgba(65, 58, 164, 0.9) 0, rgba(65, 58, 164, 0.9) 25%, rgba(6, 181, 144, 0.9) 100%);
    z-index: 1;
}

html {
    font-size: 15px;
}

body {
    direction: ltr;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.la.la-search {
    -webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

img {
    max-width: 100%;
}

a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a:hover {
    color: #06b590;
}

sup {
    top: -0.66667rem;
}

.showcase {
    margin: 4.66667rem 0 4rem 0;
    border-bottom: 1px solid #e4e8ed;
    font-size: 1.6rem;
    line-height: 48px;
    padding: 2rem 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.showcase h3 {
    color: #202428;
    font-weight: 400;
    margin: 0;
    font-size: 1.46667rem;
    text-align: center;
}

.showcase.showcase--title2 {
    background: #fff;
}

p {
    line-height: 2rem;
}

.sectionbg {
    background: #f5f7fc;
}

.bgimage {
    position: relative;
}

.bg_image_holder {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    z-index: 0;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    opacity: 0;
    overflow: hidden;
}

.bg_image_holder img {
    display: none;
}

.content_above {
    z-index: 2;
    position: relative;
}

ul {
    list-style: none;
    padding: 0;
}

.flex-1 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.cardify {
    background: #fff;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    padding: 2rem;
}

.text-box {
    padding: 3rem 3.33333rem;
}

.text-box p {
    margin: 0;
    line-height: 2.4rem;
    font-size: 1.46667rem;
    font-weight: 500;
    color: #fff;
}

.accordion-styles2 {
    background: #f4f4f4;
    padding: 0 0 5.33333rem 0;
    margin-top: 5.33333rem;
}

.alert-types {
    margin: 6.66667rem 0 3.33333rem 0;
}

.shadow-types {
    padding: 4.66667rem 0 1.33333rem;
}

.shadow-types .showcase-box {
    background: 0 0;
}

.shadow-types .showcase-box .showcase-header {
    background: 0 0;
}

.shadow-types .showcase-box .showcase-body ul {
    padding: 2.33333rem 2rem;
    background: #fff;
    margin: 0;
}

.shadow-types .showcase-box .showcase-body ul li {
    margin-bottom: 0.8rem;
    visibility: hidden;
}

.shadow-types .showcase-box .showcase-body ul li:last-child {
    margin-bottom: 0;
}

.shadow-types .showcase-box .showcase-body ul li span {
    min-width: 10rem;
    display: inline-block;
}

.shadow-types.shadow-types2 {
    margin-top: 3.33333rem;
    background: #f4f4f4;
}

.showcase-box {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
    margin-bottom: 50px;
}

.showcase-box .showcase-header {
    padding: 2rem 3rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #e4e8ed;
}

.showcase-box .showcase-header h4,
.showcase-box .showcase-header h5 {
    margin: 0;
    font-size: 1.33333rem;
    font-weight: 400;
    color: #202428;
}

.showcase-box .showcase-header p {
    margin: 10px 0 0 0;
}

.showcase-box .showcase-body {
    padding: 3rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.showcase-box:last-child .button-group {
    margin-bottom: 0;
}

.buttons-wrapper {
    padding: 70px 0 20px;
}

.buttons-wrapper .button-group {
    margin-bottom: -21px;
}

.buttons-wrapper .button-group button {
    margin: 0 17px 21px 0;
}

.shadow-default {
    -webkit-box-shadow: 0 5px 15px rgba(32, 36, 40, 0.1) !important;
    box-shadow: 0 5px 15px rgba(32, 36, 40, 0.1) !important;
}

.btn-shadow {
    -webkit-box-shadow: 0 10px 20px rgba(41, 41, 59, 0.15) !important;
    box-shadow: 0 10px 20px rgba(41, 41, 59, 0.15) !important;
}

.color-group {
    margin: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.color-group li {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.color-group li:first-child span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 25px 30px;
}

.color-group li:last-child {
    width: 80px;
    margin-left: 55px;
}

.color-group li:last-child span {
    display: block;
    margin: 10px 0;
}

.color-group.other-colors li:first-child span {
    color: #202428;
}

.color-group .bg-light-gray {
    background: #9192a3;
}

.color-group .bg-light-default {
    background: #5a6671;
}

.color-group .bg-line {
    background: #e4e8ed;
}

.color-group .bg-default {
    background: #f5f7fc;
}

.color-group .color-default {
    color: #5a6671;
}

.color-group .color-light-gray {
    color: #9192a3;
}

.bg-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#413aa4), to(#06b590)) !important;
    background: -webkit-linear-gradient(left, #413aa4, #06b590) !important;
    background: -o-linear-gradient(left, #413aa4, #06b590) !important;
    background: linear-gradient(to right, #413aa4, #06b590) !important;
}

.bg-gradient-corner {
    background: -webkit-linear-gradient(45deg, #413aa4, #06b590);
    background: -o-linear-gradient(45deg, #413aa4, #06b590);
    background: linear-gradient(45deg, #413aa4, #06b590);
}

.color-light-gray {
    color: #9192a3;
}

.flip-boxes {
    background: #f5f4f7;
}

.section-bg {
    background: #f5f7fc;
}

iframe {
    width: 100%;
}

.section-padding {
    padding: 7.33333rem 0;
}

.section-padding2 {
    padding: 7.33333rem 0 5.33333rem;
}

.section-padding3 {
    padding: 6.66667rem 0 1.33333rem 0;
}

.section-padding4 {
    padding: 7.33333rem 0 6.33333rem 0;
}

.section-padding5 {
    padding: 7.33333rem 0 3.33333rem 0;
}

.section-padding-top {
    padding: 7.33333rem 0 0 0;
}

.section-padding-sm {
    padding: 6rem 0;
}

.section-split {
    position: relative;
}

.section-split:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    background: #f5f7fc;
}

.section-split2 {
    position: relative;
}

.section-split2:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #e2e1ea;
}

.bg-half-gray,
.bg-half-secondary {
    position: relative;
}

.bg-half-gray:before,
.bg-half-secondary:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    left: 0;
    top: 0;
}

.bg-half-secondary:before {
    background: #413aa4;
}

.bg-half-gray:before {
    background: #f5f7fc;
    top: auto;
    bottom: 0;
    height: 48%;
}

.list-types ul {
    margin-bottom: 2rem;
}

.card--team1 .card-body .team-social li a.list-types:hover,
.custom-radio-three .custom-control-input:checked~.list-types.custom-control-label::after,
.custom-radio-two .custom-control-input:checked~.list-types.custom-control-label::after,
.dot-secondary.dot-light .owl-dot.active span.list-types,
.event-single .list-types.event-date,
.icon-light .content-list .icon-circle span.list-types,
.list-types.bg-dark,
.list-types.bg-secondary,
.list-types.bullet_list:before,
.list-types.go_top:hover,
.nav-circle button.list-types,
.nav-square.nav-square-dark button.list-types,
.nav_right_content .cart_module .list-types.cart_count,
.owl-dots.dot-secondary .owl-dot.active span.list-types,
.portfolio-carousel-single figure figcaption .list-types.link,
.pricing .list-types.pricing__price,
.tab.tab--2 .tab_nav2 .nav .nav-item .list-types.nav-link.active:before,
.tab.tab--2 .tab_nav2 .nav .nav-item .list-types.nav-link:hover:before,
.tab.tab--6 .tab_nav2 .nav .nav-item .list-types.nav-link.active:before,
.tab.tab--6 .tab_nav2 .nav .nav-item .list-types.nav-link:hover:before,
.tab.tab--7 .tab_nav7 .nav .nav-item .list-types.nav-link.active:before,
.tab.tab--8 .tab_nav7 .nav .nav-item .list-types.nav-link.active:before,
.toggle-switch input:checked+.list-types.slider,
footer.footer2 .list-types.footer__small,
footer.footer4.footer--colored .list-types.footer--small,
footer.footer4.footer--light .footer__small .social.square li a.list-types:hover {
    padding: 4.66667rem 0 2.33333rem;
}

.card--team1 .card-body .team-social li a.list-types:hover ol,
.card--team1 .card-body .team-social li a.list-types:hover ul,
.custom-radio-three .custom-control-input:checked~.list-types.custom-control-label::after ol,
.custom-radio-three .custom-control-input:checked~.list-types.custom-control-label::after ul,
.custom-radio-two .custom-control-input:checked~.list-types.custom-control-label::after ol,
.custom-radio-two .custom-control-input:checked~.list-types.custom-control-label::after ul,
.dot-secondary.dot-light .owl-dot.active span.list-types ol,
.dot-secondary.dot-light .owl-dot.active span.list-types ul,
.event-single .list-types.event-date ol,
.event-single .list-types.event-date ul,
.icon-light .content-list .icon-circle span.list-types ol,
.icon-light .content-list .icon-circle span.list-types ul,
.list-types.bg-dark ol,
.list-types.bg-dark ul,
.list-types.bg-secondary ol,
.list-types.bg-secondary ul,
.list-types.bullet_list:before ol,
.list-types.bullet_list:before ul,
.list-types.go_top:hover ol,
.list-types.go_top:hover ul,
.nav-circle button.list-types ol,
.nav-circle button.list-types ul,
.nav-square.nav-square-dark button.list-types ol,
.nav-square.nav-square-dark button.list-types ul,
.nav_right_content .cart_module .list-types.cart_count ol,
.nav_right_content .cart_module .list-types.cart_count ul,
.owl-dots.dot-secondary .owl-dot.active span.list-types ol,
.owl-dots.dot-secondary .owl-dot.active span.list-types ul,
.portfolio-carousel-single figure figcaption .list-types.link ol,
.portfolio-carousel-single figure figcaption .list-types.link ul,
.pricing .list-types.pricing__price ol,
.pricing .list-types.pricing__price ul,
.tab.tab--2 .tab_nav2 .nav .nav-item .list-types.nav-link.active:before ol,
.tab.tab--2 .tab_nav2 .nav .nav-item .list-types.nav-link.active:before ul,
.tab.tab--2 .tab_nav2 .nav .nav-item .list-types.nav-link:hover:before ol,
.tab.tab--2 .tab_nav2 .nav .nav-item .list-types.nav-link:hover:before ul,
.tab.tab--6 .tab_nav2 .nav .nav-item .list-types.nav-link.active:before ol,
.tab.tab--6 .tab_nav2 .nav .nav-item .list-types.nav-link.active:before ul,
.tab.tab--6 .tab_nav2 .nav .nav-item .list-types.nav-link:hover:before ol,
.tab.tab--6 .tab_nav2 .nav .nav-item .list-types.nav-link:hover:before ul,
.tab.tab--7 .tab_nav7 .nav .nav-item .list-types.nav-link.active:before ol,
.tab.tab--7 .tab_nav7 .nav .nav-item .list-types.nav-link.active:before ul,
.tab.tab--8 .tab_nav7 .nav .nav-item .list-types.nav-link.active:before ol,
.tab.tab--8 .tab_nav7 .nav .nav-item .list-types.nav-link.active:before ul,
.toggle-switch input:checked+.list-types.slider ol,
.toggle-switch input:checked+.list-types.slider ul,
footer.footer2 .list-types.footer__small ol,
footer.footer2 .list-types.footer__small ul,
footer.footer4.footer--colored .list-types.footer--small ol,
footer.footer4.footer--colored .list-types.footer--small ul,
footer.footer4.footer--light .footer__small .social.square li a.list-types:hover ol,
footer.footer4.footer--light .footer__small .social.square li a.list-types:hover ul {
    margin: 0;
}

.bg-image {
    /* background: url(img/list-bg.jpg) center/cover no-repeat; */
    padding: 6rem;
}

.bg-image ol,
.bg-image ul {
    margin: 0;
}

.bg-image.list-types {
    padding: 6rem 0 4rem;
}

.bg-gray-light {
    background: #f5f7fc;
}

.bg-gradient--light {
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fbfaff));
    background: -webkit-linear-gradient(top, #fff, #fbfaff);
    background: -o-linear-gradient(top, #fff, #fbfaff);
    background: linear-gradient(to bottom, #fff, #fbfaff);
}

.hr-divider {
    border-top: 1px solid #e4e8ed;
    height: 1px;
    margin-top: 4rem;
}

.hr-divider2 {
    border-top: 1px solid #e4e8ed;
    height: 1px;
    margin: 3.6rem 0 3.53333rem 0;
}

.hr-divider3 {
    border-top: 1px solid #d8d9e4;
    height: 1px;
    margin: 2.66667rem 0 5.33333rem 0;
}

.hr-divider-gray {
    background: rgba(100, 103, 145, 0.5);
    width: 100%;
    height: 1px;
    margin: 5.33333rem 0 4.33333rem;
}

.hr-line {
    background: #e4e8ed;
    height: 1px;
}

.form-elements .form-tabs li {
    margin-left: -1px;
}

.form-elements .form-tabs li:first-child a {
    -webkit-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.form-elements .form-tabs li:last-child a {
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.form-elements .form-tabs li a {
    border: 1px solid #e4e8ed;
    padding: 13px 15px;
    color: #5a6671;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
}

.form-elements .form-tabs li a.active {
    background: #413aa4;
    color: #fff;
    border-color: #413aa4;
}

.form-elements .form-tabs li a:hover {
    color: #fff;
    background: #413aa4;
    border-color: #413aa4;
}

.tab--3 .tab-content>.tab-pane {
    display: block;
    height: 0;
    visibility: hidden;
}

.tab--3 .tab-content>.active {
    height: auto;
    visibility: visible;
}

.go_top {
    line-height: 2.66667rem;
    cursor: pointer;
    width: 2.66667rem;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: fixed;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 1.66667rem 0 0 1.66667rem;
    border-radius: 1.66667rem 0 0 1.66667rem;
    right: 0;
    z-index: 111;
    bottom: 30px;
    text-align: center;
}

.btn-primary {
    -webkit-box-shadow: 0 10px 20px rgba(65, 58, 164, 0.2);
    box-shadow: 0 10px 20px rgba(65, 58, 164, 0.2);
}

.btn-secondary {
    -webkit-box-shadow: 0 10px 20px rgba(6, 181, 144, 0.2);
    box-shadow: 0 10px 20px rgba(6, 181, 144, 0.2);
}

.btn-success {
    -webkit-box-shadow: 0 10px 20px rgba(53, 181, 62, 0.2);
    box-shadow: 0 10px 20px rgba(53, 181, 62, 0.2);
}

.btn-info {
    -webkit-box-shadow: 0 10px 20px rgba(55, 125, 255, 0.2);
    box-shadow: 0 10px 20px rgba(55, 125, 255, 0.2);
}

.btn-warning {
    -webkit-box-shadow: 0 10px 20px rgba(255, 175, 0, 0.2);
    box-shadow: 0 10px 20px rgba(255, 175, 0, 0.2);
}

.btn-danger {
    -webkit-box-shadow: 0 10px 20px rgba(226, 54, 54, 0.2);
    box-shadow: 0 10px 20px rgba(226, 54, 54, 0.2);
}

.btn-light {
    -webkit-box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

.btn-dark {
    -webkit-box-shadow: 0 10px 20px rgba(32, 36, 40, 0.2);
    box-shadow: 0 10px 20px rgba(32, 36, 40, 0.2);
}

.outline-thick-primary {
    border: 2px solid rgba(65, 58, 164, 0.2);
    background: 0 0;
    color: #413aa4;
}

.outline-thick-secondary {
    border: 2px solid rgba(6, 181, 144, 0.2);
    background: 0 0;
    color: #06b590;
}

.outline-thick-success {
    border: 2px solid rgba(53, 181, 62, 0.2);
    background: 0 0;
    color: #35b53e;
}

.outline-thick-info {
    border: 2px solid rgba(55, 125, 255, 0.2);
    background: 0 0;
    color: #377dff;
}

.outline-thick-warning {
    border: 2px solid rgba(255, 175, 0, 0.2);
    background: 0 0;
    color: #ffaf00;
}

.outline-thick-danger {
    border: 2px solid rgba(226, 54, 54, 0.2);
    background: 0 0;
    color: #e23636;
}

.outline-thick-light {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: 0 0;
    color: #fff;
}

.outline-thick-dark {
    border: 2px solid rgba(32, 36, 40, 0.2);
    background: 0 0;
    color: #202428;
}

.btn {
    text-transform: capitalize;
    font-size: 0.93333rem;
    font-weight: 500;
}

.btn.btn--rounded {
    -webkit-border-radius: 200px;
    border-radius: 200px;
}

.btn.btn-sm {
    font-size: 0.86rem;
}

.btn.btn-lg {
    font-size: 1rem;
    white-space: normal;
}

.btna {
    color: #fff;
}

.btn.btn-light {
    -webkit-box-shadow: 0 10px 20px rgba(41, 41, 59, 0.15);
    box-shadow: 0 10px 20px rgba(41, 41, 59, 0.15);
}

.btn.btn-outline-light {
    border: 1px solid #e4e8ed;
    color: #e4e8ed;
}

.btn.btn-outline-light:hover {
    color: #212529;
    background: #e4e8ed;
}

.btn.btn-arrow {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn.btn-arrow i {
    margin-left: 0.66rem;
}

.btn.btn-icon {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.btn.btn-icon.icon-left i,
.btn.btn-icon.icon-left span {
    margin-right: 6px;
}

.btn.btn-icon.icon-right i,
.btn.btn-icon.icon-right span {
    margin-left: 6px;
}

.btn.like-btn {
    width: 2.73333rem;
    height: 2.73333rem;
    font-size: 1.2rem;
    color: #06b590;
    border: 1px solid rgba(255, 255, 255, 0.3);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(6, 181, 144, 0.1);
}

.btn.like-btn:focus {
    outline: 0;
}

.btn.like-btn:hover {
    color: #fff;
    background: #06b590;
}

.btn.filter-btn {
    font-size: 1rem;
    min-width: auto;
    padding: 0 1.33333rem;
    font-weight: 400;
    text-transform: capitalize;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.filter-btn:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#413aa4), to(#06b590));
    background: -webkit-linear-gradient(left, #413aa4, #06b590);
    background: -o-linear-gradient(left, #413aa4, #06b590);
    background: linear-gradient(to right, #413aa4, #06b590);
    color: #fff;
    border: none;
    -webkit-box-shadow: 0 10px 15px rgba(6, 181, 144, 0.2);
    box-shadow: 0 10px 15px rgba(6, 181, 144, 0.2);
}

.btn.btn-icon-only {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
}

.play-btn-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #06b590;
    font-weight: 500;
    margin-left: 1rem;
}

.play-btn-wrapper .play-btn {
    margin-right: 1.33333rem;
}

.play-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    font-size: 2rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
}

.play-btn .svg {
    width: 1.2rem;
    fill: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.play-btn:hover {
    color: #fff;
}

.play-btn.play-btn--lg {
    width: 4.66667rem;
    height: 4.66667rem;
}

.play-btn.play-btn--sm {
    width: 3.33333rem;
    height: 3.33333rem;
    font-size: 1.53333rem;
}

.play-btn.play-btn--sm .svg {
    width: 1rem;
}

.play-btn.play-btn-light {
    background: #fff;
    color: #202428;
}

.play-btn.play-btn-light:hover {
    background: #06b590;
    color: #fff;
}

.play-btn.play-btn-light:hover .svg {
    fill: #fff;
}

.play-btn.play-btn-light .svg {
    fill: #06b590;
}

.play-btn.play-btn--gesture {
    color: #06b590;
    font-size: 1.86667rem;
}

.play-btn.play-btn--gesture:before {
    position: absolute;
    content: "";
    width: 130%;
    height: 130%;
    left: -15%;
    top: -15%;
    background: rgba(6, 181, 144, 0.15);
    -webkit-border-radius: 20rem;
    border-radius: 20rem;
}

.play-btn.play-btn--gesture:after {
    position: absolute;
    content: "";
    width: 160%;
    height: 160%;
    left: -30%;
    top: -30%;
    background: rgba(6, 181, 144, 0.05);
    -webkit-border-radius: 20rem;
    border-radius: 20rem;
}

.play-btn.play-btn--gesture:hover {
    background: inherit;
}

.play-btn.play-btn--primary {
    background: #413aa4;
}

.play-btn.play-btn--secondary {
    background: #06b590;
}

.action-btns ul li {
    margin-right: 5px;
}

.action-btns ul li:last-child {
    margin-right: 0;
}

.action-btns ul li.share-btn {
    position: relative;
    padding-bottom: 1rem;
}

.action-btns ul li.share-btn ul {
    position: absolute;
    right: 0;
    top: 4rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 2rem;
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.action-btns ul li.share-btn ul:before {
    position: absolute;
    content: "";
    border-bottom: 0.66667rem solid #fff;
    border-right: 0.53333rem solid transparent;
    border-left: 0.53333rem solid transparent;
    right: 1.13333rem;
    bottom: 100%;
}

.action-btns ul li.share-btn ul li {
    margin-right: 1rem;
}

.action-btns ul li.share-btn ul li:last-child {
    margin-right: 0;
}

.action-btns ul li.share-btn ul li a {
    font-size: 1rem;
    color: #06b590;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.action-btns ul li.share-btn ul li a:hover {
    color: #413aa4;
}

.action-btns ul li.share-btn:hover ul {
    opacity: 1;
    visibility: visible;
}

.input_with_embed {
    position: relative;
}

.input_with_embed .embed_button,
.input_with_embed .embed_icon {
    position: absolute;
}

.input_with_embed .embed_icon {
    right: 1.33rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.input_with_embed .embed_icon button {
    background: 0 0;
    border: 0;
}

input.input--rounded {
    -webkit-border-radius: 13.33333rem;
    border-radius: 13.33333rem;
}

.custom-radio {
    margin-bottom: 1rem;
    padding-left: 10px;
}

.custom-radio:last-child {
    margin-bottom: 0;
}

.custom-radio input:active {
    background: 0 0;
}

.custom-radio .custom-control-label {
    padding-left: 2rem;
    position: relative;
    min-width: 100px;
}

.custom-radio .custom-control-label:before {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0.33333rem solid #cbd0e3;
    top: 1px;
    left: 0;
}

.custom-radio .custom-control-label:after {
    width: auto;
    height: auto;
    background-image: none;
}

.custom-radio .custom-control-label span {
    position: relative;
    top: 0rem;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background: 0 0;
    border-color: #06b590;
    content: "";
    font-family: LineAwesome;
    color: #fff;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.8rem;
}

.custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-radio-two .custom-control-label:before {
    border: 0.2rem solid #cbd0e3;
    width: 1.2rem;
    height: 1.2rem;
}

.custom-radio-two .custom-control-input:checked~.custom-control-label::after {
    width: 0.4rem;
    height: 0.4rem;
    -webkit-border-radius: 20rem;
    border-radius: 20rem;
    left: 0.4rem;
    top: 0.46667rem;
}

.custom-radio-three .custom-control-label:before {
    border: 1px solid #cbd0e3;
    width: 1.2rem;
    height: 1.2rem;
}

.custom-radio-three .custom-control-input:checked~.custom-control-label::after {
    width: 0.4rem;
    height: 0.4rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    left: 0.4rem;
    top: 0.46667rem;
}

.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .form-control {
        padding: 0.5rem 1.2rem 1rem;
    }
}

.form-control:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control::-webkit-input-placeholder {
    color: #9192a3;
}

.form-control::-ms-input-placeholder {
    color: #9192a3;
}

.form-control::placeholder {
    color: #9192a3;
}

.form-control.fc--squared {
    -webkit-border-radius: 0;
    border-radius: 0;
}

.form-control.fc--rounded {
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    padding-left: 1.66667rem;
}

.form-control.fc--solid-bg {
    background: #f5f4f7;
}

.form-control.form-outline {
    border: 0 none;
    border-bottom: 1px solid #e4e8ed;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding-left: 0;
}

.form-control.form-outline:focus {
    border-bottom-color: #06b590;
}

.form-control--color {
    min-height: 3rem;
    padding: 0.66667rem;
    background: 0 0;
}

.form-group {
    margin-bottom: 2rem;
}

.form-group label {
    color: #202428;
}

.range-input:after {
    content: "";
    display: block;
    clear: both;
}

.range-input .custom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: 0 0;
}

.range-input .custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 0.33333rem solid #413aa4;
    background: #e3e6ef;
    margin-top: -6px;
}

.range-input .custom-range::-webkit-slider-runnable-track {
    background: #e3e6ef;
    height: 0.4rem;
    width: 100%;
    cursor: pointer;
}

.input-group-light .form-control {
    -webkit-border-radius: 3px !important;
    border-radius: 3px !important;
}

.input-group-light span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
    font-size: 1.2rem;
}

.input-group-light span.icon-left {
    left: 1.33333rem;
}

.input-group-light span.icon-left+.form-control {
    padding-left: 3rem;
}

.input-group-light span.icon-right {
    right: 1rem;
}

.input-group-light span.icon-right+.form-control {
    padding-right: 2.66667rem;
}

.checkbox-group .input-group-text {
    line-height: normal;
    padding: 0 0.8rem;
}

.checkbox-group .input-group-text .custom-control {
    padding-left: 1rem;
}

.checkbox-group .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    padding-left: 1px;
    padding-top: 2px;
}

.radio-group .input-group-text {
    line-height: normal;
    padding: 0 0.8rem;
}

.radio-group .input-group-text .custom-control {
    padding-left: 0.53333rem;
}

.radio-group .custom-control-label {
    padding-left: 0;
    width: 1.46667rem;
    height: 1.53333rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: -0.46667rem;
}

.custom-checkbox {
    margin-bottom: 2.33333rem;
}

.custom-checkbox:last-child {
    margin-bottom: 0;
}

.custom-checkbox .custom-control-label {
    min-width: 6.66667rem;
    padding-left: 2.33333rem;
    position: relative;
}

.custom-checkbox .custom-control-label::before {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 1.46667rem;
    height: 1.46667rem;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    top: 0;
    left: 0;
}

.custom-checkbox .custom-control-label::after {
    width: 1.4rem;
    height: 1.4rem;
    top: 0;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    content: "\f17b";
    font-family: LineAwesome;
    left: 0;
    font-size: 0.86667rem;
    text-align: center;
    top: 2px;
    font-weight: 600;
}

.checkbox-outline .custom-control-label:before {
    background: 0 0;
}

.checkbox-outline .custom-control-input:checked~.custom-control-label::before {
    background: 0 0;
}

.checkbox-outline .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    content: "\f17b";
    font-family: LineAwesome;
    left: 0;
    font-size: 0.86667rem;
    text-align: center;
    top: 2px;
    font-weight: 600;
}

.checkbox-outline-gray .custom-control-label:before {
    border: 1px solid #e4e8ed;
}

.checkbox-outline-gray .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid #e4e8ed;
}

.checkbox-outline-gray .custom-control-input:checked~.custom-control-label::after {
    color: #5a6671;
}

.checkbox-rounded .custom-control-label:before {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 2rem;
}

.toggle-switch input {
    display: none;
}

.toggle-switch .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #cbd0e3;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 1.6rem;
    width: 1.6rem;
    left: 0.2rem;
    bottom: 0.2rem;
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.toggle-switch input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
}

.toggle-switch input:focus+.slider {
    -webkit-box-shadow: 0 0 1px #2196f3;
    box-shadow: 0 0 1px #2196f3;
}

.toggle-switch .slider.round {
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
}

.toggle-switch .slider.round:before {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.toggle-switch-text {
    width: 4.66667rem;
}

.toggle-switch-text input:checked+.slider:before {
    -webkit-transform: translateX(2.66667rem);
    -ms-transform: translateX(2.66667rem);
    transform: translateX(2.66667rem);
}

.toggle-switch-text input:checked+.slider .on {
    visibility: visible;
    opacity: 1;
}

.toggle-switch-text input:checked+.slider .off {
    visibility: hidden;
}

.toggle-switch-text .slider.round .on {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.toggle-switch-text .slider.round .off,
.toggle-switch-text .slider.round .on {
    padding-left: 0.2rem;
    margin-top: 0.4rem;
    font-size: 0.86667rem;
    text-transform: uppercase;
    display: inline-block;
}

.toggle-switch-text .slider.round .on {
    padding-left: 0.66667rem;
    color: #fff;
}

.toggle-switch-bordered .slider {
    background: 0 0;
    border: 1px solid #cbd0e3;
}

.toggle-switch-bordered .slider:before {
    height: 2rem;
    width: 2rem;
    left: -0.06667rem;
    bottom: -0.06667rem;
    border: 1px solid #cbd0e3;
}

.toggle-switch-bordered input:checked+.slider {
    border-color: #06b590;
    background: 0 0;
}

.toggle-switch-bordered input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    border-color: #06b590;
}

select.form-control {
    min-height: 3rem;
    color: #9192a3;
}

.select-basic {
    position: relative;
}

.select-basic select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-basic:before {
    position: absolute;
    content: "\f110";
    right: 1rem;
    font-size: 0.8rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: LineAwesome;
}

.form--rounded {
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    border: 0 none;
}

.input-group-subscribe {
    display: block;
    position: relative;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
}

.input-group-subscribe input {
    width: 100%;
    border: 0 none;
    padding-left: 2rem;
    padding-right: 7.66667rem;
    height: 4rem;
}

.input-group-subscribe button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: 0 none;
    padding: 0 1.66667rem;
    background: #06b590;
    color: #fff;
    cursor: pointer;
}

.input-group-subscribe .text-field {
    position: relative;
}

.input-group-subscribe .text-field:before {
    position: absolute;
    content: "\f1c6";
    font-family: LineAwesome;
    left: 1.66667rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 1.46667rem;
    display: none;
}

.input-group-subscribe.subscribe--rounded input {
    -webkit-border-radius: 2rem;
    border-radius: 2rem;
}

.input-group-subscribe.subscribe--rounded button {
    -webkit-border-radius: 0 2rem 2rem 0;
    border-radius: 0 2rem 2rem 0;
}

select[multiple]::-webkit-scrollbar-track {
    background-color: rgba(32, 36, 40, 0.05);
}

select[multiple]::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

select[multiple]::-webkit-scrollbar-thumb {
    background-color: #e4e8ed;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.form-wrapper .cardify h5 {
    font-weight: 600;
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
    position: absolute;
    content: "\f110";
    right: 1rem;
    font-weight: 400;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: LineAwesome;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: block;
    width: 100% !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin: 5px;
    background: #f1f3f8;
    border: 0;
    color: #9192a3;
    padding: 0 10px;
}

.select2-results__options::-webkit-scrollbar-track {
    background-color: rgba(32, 36, 40, 0.05);
}

.select2-results__options::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.select2-results__options::-webkit-scrollbar-thumb {
    background-color: #e4e8ed;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.custom_select_with_icon .select2-results .select2-results__options .select2-results__option.select2-results__option--highlighted span span.la {
    color: #06b590;
}

.custom_select_with_icon .select2-results .select2-results__options .select2-results__option span span.la {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;
    margin-right: 4px;
    color: #9192a3;
}

.custom-photo-upload {
    position: relative;
}

.custom-photo-upload input {
    width: 13.33333rem;
    height: 13.33333rem;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    margin: 0;
    cursor: pointer;
}

.custom-photo-upload .custom-upload-box .image-box {
    width: 13.33333rem;
    height: 13.33333rem;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.custom-photo-upload .custom-upload-box .image-box .icon {
    font-size: 6.66667rem;
    color: #e4e8ed;
}

.custom-photo-upload .custom-upload-box .image-box span {
    color: #9192a3;
}

.custom-photo-upload .custom-upload-box .image-box span.file-name {
    text-align: center;
}

.custom-photo-upload .custom-upload-box .image-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 2rem;
}

.custom-photo-upload .custom-upload-box .image-info span {
    color: #9192a3;
}

.custom-upload {
    position: relative;
}

.custom-upload input {
    width: 13.33333rem;
    height: 3.33333rem;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    margin: 0;
    cursor: pointer;
}

.custom-upload .custom-upload-box .upload-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 2rem;
}

.custom-upload .custom-upload-box .upload-info span {
    color: #9192a3;
}

textarea.form-control {
    padding-top: 1rem;
}

.form-control-lg {
    font-size: 1rem;
}

.heading-styles .card-body {
    padding: 3rem 3rem 1.8rem;
}

.heading-styles .card-body h1,
.heading-styles .card-body h2,
.heading-styles .card-body h3,
.heading-styles .card-body h4,
.heading-styles .card-body h5,
.heading-styles .card-body h6 {
    color: #202428;
}

.sub_heading-group {
    margin-bottom: 2.66rem;
}

.sub_heading-group:last-child {
    margin-bottom: 0;
}

.sub_heading-group h1,
.sub_heading-group h2,
.sub_heading-group h3,
.sub_heading-group h4,
.sub_heading-group h5,
.sub_heading-group h6 {
    margin-bottom: 1.5rem;
}

.sub_heading-group .subHeading-top {
    font-size: 1.33rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
}

.sub_heading-group .subHeading-bottom {
    font-size: 1.2rem;
    margin-bottom: 0;
}

.lead {
    line-height: 2.4rem;
}

.lead--medium {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.93rem;
}

p {
    line-height: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

.blockquote {
    border: 1px solid #e4e8ed;
    padding: 2.5rem 3.33333rem;
    -webkit-border-radius: 0.3rem;
    border-radius: 0.3rem;
    margin: 0;
}

.blockquote p {
    margin: 0;
    font-size: 1.33rem;
    color: #202428;
    line-height: 2.4rem;
    font-weight: 500;
}

.blockquote .quote-author {
    margin-top: 1.66rem;
}

.blockquote .quote-author p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #9192a3;
    position: relative;
    display: inline-block;
    padding-left: 3.66667rem;
}

.blockquote .quote-author p:before {
    position: absolute;
    content: "";
    width: 2.66667rem;
    height: 2px;
    background: #06b590;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.blockquote .quote-author p span {
    font-weight: 500;
    color: #202428;
}

.blockquote.blockquote1 .quote-author {
    text-align: left;
}

.blockquote.blockquote-sm p {
    font-size: 1.06667rem;
    line-height: 1.86667rem;
}

.blockquote.blockquote-sm .quote-author p {
    padding: 0;
}

.blockquote.blockquote-sm .quote-author p:before {
    content: none;
}

.blockquote.blockquote-sm .quote-author p span {
    font-size: 1rem;
}

.blockquote.blockquote3,
.blockquote.blockquote4 {
    border: 0 none;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: -webkit-gradient(linear, left top, right top, from(#413aa4), to(#06b590));
    background: -webkit-linear-gradient(left, #413aa4, #06b590);
    background: -o-linear-gradient(left, #413aa4, #06b590);
    background: linear-gradient(to right, #413aa4, #06b590);
}

.blockquote.blockquote3 p,
.blockquote.blockquote4 p {
    font-weight: 400;
    color: #fff;
}

.blockquote.blockquote3 .quote-author p,
.blockquote.blockquote4 .quote-author p {
    color: rgba(255, 255, 255, 0.7);
}

.blockquote.blockquote3 .quote-author span,
.blockquote.blockquote4 .quote-author span {
    color: #fff;
}

.blockquote.blockquote3 .quote-author {
    text-align: left;
}

.blockquote.blockquote1,
.blockquote.blockquote3 {
    position: relative;
}

.blockquote.blockquote1:before,
.blockquote.blockquote3:before {
    position: absolute;
    content: "\f2ce";
    font-family: LineAwesome;
    color: rgba(255, 255, 255, 0.1);
    font-size: 8rem;
    left: 2.33333rem;
    bottom: 0.66667rem;
}

.quotes-with-image {
    background: -webkit-gradient(linear, left top, right top, from(#413aa4), to(#06b590));
    background: -webkit-linear-gradient(left, #413aa4, #06b590);
    background: -o-linear-gradient(left, #413aa4, #06b590);
    background: linear-gradient(to right, #413aa4, #06b590);
}

.blockquote5 {
    margin-bottom: 0;
}

.blockquote5 .strong {
    font-size: 1.46667rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 2.33333rem;
    line-height: 2.4rem;
    position: relative;
    margin-top: 2.66667rem;
}

.blockquote5 .strong:before {
    position: absolute;
    content: "\f2cd";
    font-family: LineAwesome;
    font-size: 10rem;
    color: rgba(255, 255, 255, 0.1);
    left: 0;
    top: -1rem;
}

.blockquote5 p {
    color: rgba(255, 255, 255, 0.8);
}

.blockquote5 .author-sign {
    font-size: 2.33333rem;
    font-family: Allura, cursive;
    color: #fff;
    display: block;
    margin-top: 2rem;
}

.hr {
    margin: 5.33333rem 0;
}

.accordion_one,
.accordion_two {
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    background: #fff;
    padding: 2.66667rem;
    border: 1px solid #e4e8ed;
}

.accordion_one .accordion-single,
.accordion_two .accordion-single {
    border-bottom: 1px solid #e4e8ed;
}

.accordion_one .accordion-single:first-child,
.accordion_two .accordion-single:first-child {
    padding-top: 0;
}

.accordion_one .accordion-single:first-child h6 a,
.accordion_two .accordion-single:first-child h6 a {
    padding-top: 0;
}

.accordion_one .accordion-single:first-child h6 a[aria-expanded="true"]:before,
.accordion_two .accordion-single:first-child h6 a[aria-expanded="true"]:before {
    top: 10%;
    -webkit-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
}

.accordion_one .accordion-single:first-child h6 a[aria-expanded="false"]:before,
.accordion_two .accordion-single:first-child h6 a[aria-expanded="false"]:before {
    top: 10%;
    -webkit-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
}

.accordion_one .accordion-single h6 a,
.accordion_two .accordion-single h6 a {
    color: #202428;
    display: block;
    position: relative;
    padding: 1.33rem 2rem 1rem 0;
    font-size: 1.06667rem;
    font-weight: 500;
    cursor: pointer;
}

.accordion_one .accordion-single h6 a[aria-expanded="true"]:before,
.accordion_two .accordion-single h6 a[aria-expanded="true"]:before {
    position: absolute;
    content: "\f110";
    font-family: LineAwesome;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #9192a3;
    font-size: 0.93333rem;
}

.accordion_one .accordion-single h6 a[aria-expanded="false"]:before,
.accordion_two .accordion-single h6 a[aria-expanded="false"]:before {
    position: absolute;
    content: "\f113";
    font-family: LineAwesome, sans-serif;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 0.93333rem;
    color: #9192a3;
}

.accordion_two {
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    padding: 3rem;
    -webkit-border-radius: 0.3rem;
    border-radius: 0.3rem;
}

.accordion_two .accordion-single h6 a[aria-expanded="true"]:before {
    content: "\f28e";
    color: #9192a3;
}

.accordion_two .accordion-single h6 a[aria-expanded="false"]:before {
    content: "\f2c2";
}

.accordion_three .accordion-single {
    margin-bottom: 5px;
}

.accordion_three .accordion-heading h6 a {
    display: block;
    padding: 1rem 2.66667rem 1rem 2rem;
    background: #fff;
    color: #202428;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 1.46667rem;
    font-size: 1rem;
    font-weight: 500;
}

.accordion_three .accordion-heading h6 a[aria-expanded="true"] {
    background: #06b590;
    color: #fff;
}

.accordion_three .accordion-heading h6 a[aria-expanded="true"]:before {
    content: "\f28e";
    color: #fff;
}

.accordion_three .accordion-heading h6 a:before {
    position: absolute;
    content: "\f2c2";
    font-family: LineAwesome;
    right: 2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #9192a3;
}

.accordion_three .accordion-contents {
    padding: 1.33rem 2rem 0.66rem;
}

.accordion_three .collapsed.bg-gray-light {
    background: #f5f4f7;
}

.accordion_four .accordion-single {
    margin-bottom: 5px;
}

.accordion_four .accordion-heading h6 a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 2.66667rem 1rem 2rem;
    background: #fff;
    color: #202428;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 1.46667rem;
    font-size: 1rem;
    font-weight: 500;
}

.accordion_four .accordion-heading h6 a[aria-expanded="true"] {
    background: #06b590;
    color: #fff;
}

.accordion_four .accordion-heading h6 a[aria-expanded="true"]:before {
    content: "\f28e";
    color: #fff;
}

.accordion_four .accordion-heading h6 a[aria-expanded="true"] i {
    color: #fff;
}

.accordion_four .accordion-heading h6 a:before {
    position: absolute;
    content: "\f2c2";
    font-family: LineAwesome;
    right: 2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #9192a3;
}

.accordion_four .accordion-heading h6 a i {
    font-size: 1.4rem;
    color: #06b590;
    margin-right: 1rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.accordion_four .accordion-contents {
    padding: 1.33rem 2rem 0.66rem;
}

.accordion_four .collapsed.bg-gray-light {
    background: #f5f4f7;
}

.alert-group .alert:last-child {
    margin-bottom: 0;
}

.alert {
    border: 0 none;
}

.alert strong {
    font-weight: 500;
    font-size: 1.06rem;
}

.alert a {
    color: inherit;
    font-weight: 500;
    font-size: 1.06rem;
}

.alert p {
    margin: 0;
}

.alert i.la {
    padding-right: 0.66rem;
    font-size: 1.2rem;
    position: relative;
    top: 2px;
}

.alert.alert-outline {
    background: 0 0;
    border: 1px solid;
}

.alert.alert-dismissible .close {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 2rem;
    padding: 0;
}

.alert.alert-dismissible .close:focus {
    outline: 0;
}

.card {
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
    overflow: hidden;
}

.card figure {
    margin: 0;
}

.card .card-body .card-title {
    margin-bottom: 25px;
}

.card .card-body h6 {
    margin-bottom: 0;
    line-height: 1.86667rem;
}

.card .card-body h4 a:hover {
    color: #413aa4;
}

.card .card-footer {
    padding: 1.66667rem 2rem;
}

.card .card-footer ul {
    margin: 0;
}

.card.card-shadow {
    -webkit-box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
}

.card.card--dark {
    background: #fff;
}

.card.card--dark .card-header {
    background: inherit;
    padding: 1.53333rem 2rem 2rem;
}

.card.card--dark .card-header h5 {
    margin: 0;
}

.card.card--sidebar .card-header {
    padding: 2rem;
}

.card.card--sidebar .card-header h4 {
    margin: 0;
}

.card.card--sidebar .card-body {
    padding: 2rem 2rem 3.33333rem;
}

.card-one {
    margin-bottom: 2rem;
}

.card-one figure {
    position: relative;
}

.card-one figure figcaption {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(6, 181, 144, 0.8);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
}

.card-one figure figcaption a {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 3.33333rem;
    height: 3.33333rem;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 1.2rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
}

.card-one figure figcaption a:hover {
    background: #413aa4;
    color: #fff;
}

.card-one .card-subtitle {
    margin: 0 0 0.66667rem 0;
    line-height: 1;
}

.card-one:hover figure figcaption {
    opacity: 1;
    visibility: visible;
}

.card-one:hover figure figcaption a {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.card-two .card-footer ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.card-two .card-footer ul li {
    color: #9192a3;
    padding-right: 1rem;
    margin-right: 1rem;
    position: relative;
}

.card-two .card-footer ul li:before {
    position: absolute;
    content: "|";
    right: 0;
    top: 0;
    color: #e4e8ed;
}

.card-two .card-footer ul li:last-child {
    margin-right: 0;
}

.card-two .card-footer ul li:last-child:before {
    content: none;
}

.card-two .card-footer ul li a {
    color: #9192a3;
}

.card-text,
.card-three,
.card-video {
    margin-bottom: 2rem !important;
}

.card-text .card-subtitle,
.card-three .card-subtitle,
.card-video .card-subtitle {
    margin: 0 0 0.66667rem 0;
}

.card-text .card-text,
.card-three .card-text,
.card-video .card-text {
    margin-top: 1rem;
}

.card-video figure {
    position: relative;
}

.card-video figure figcaption {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(32, 36, 40, 0.2);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.card-video figure figcaption a {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 3rem;
    height: 3rem;
    background: #fff;
    color: #06b590;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid transparent;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.card-video figure figcaption a .svg {
    width: 0.86667rem;
    fill: #06b590;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.card-video figure figcaption a:hover {
    background: #06b590;
}

.card-video figure figcaption a:hover .svg {
    fill: #fff;
}

.card-text .card-body h5 {
    margin: 0 0 2rem 0;
}

.card-text:last-child {
    margin-bottom: 0 !important;
}

.card-four {
    margin-bottom: 2rem;
}

.card-four .card-body .card-subtitle {
    margin-bottom: 4px;
}

.card-four .card-body h5 {
    margin-bottom: 1.33rem;
}

.card-four .card-body h5 a {
    color: #202428;
}

.card-four .card-body .card-text {
    margin-bottom: 1.6rem;
}

.card-product {
    margin-bottom: 2rem;
    position: relative;
}

.card-product figure {
    position: relative;
    overflow: hidden;
}

.card-product figure figcaption {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -76px;
    background: #413aa4;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
}

.card-product figure figcaption ul {
    margin: 0;
    padding: 0.8rem 0;
}

.card-product figure figcaption ul li:last-child {
    margin-left: 0.66rem;
}

.card-product figure figcaption ul a.btn--rounded {
    border-color: rgba(255, 255, 255, 0.3);
    color: #fff;
}

.card-product figure figcaption ul .like-btn {
    position: relative;
}

.card-product figure figcaption ul .like-btn i {
    z-index: 2;
}

.card-product figure figcaption ul .like-btn:hover {
    color: #413aa4;
    background: #fff;
}

.card-product .card-body {
    text-align: center;
    padding: 1.66667rem 2rem;
}

.card-product .card-body h6 {
    margin-bottom: 5px;
}

.card-product .card-body h6 a {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.card-product .card-body .prices {
    margin-top: 0.66667rem;
}

.card-product .card-body .prices .product-price {
    font-size: 1.33rem;
    font-weight: 600;
    line-height: 1.2rem;
    display: inline-block;
}

.card-product .card-body .prices .old-price {
    font-weight: 500;
    display: inline-block;
    margin-right: 7px;
    color: #9192a3;
    text-decoration: line-through;
}

.card-product:hover figure figcaption {
    opacity: 1;
    bottom: 0;
}

.card-product .badge {
    position: absolute;
    left: 0.66rem;
    top: 0.66rem;
    padding: 6px 10px;
    font-size: 0.8rem;
    font-weight: 500;
}

.card-six .card-body h5 {
    margin-bottom: 1.33333rem;
}

.card-six .card-body h5 a {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.card-six .card-body h5 a:hover {
    color: #06b590;
}

.card--seven .card-body {
    padding-bottom: 2rem;
}

.card--seven .card-body h6 {
    margin-bottom: 1rem;
}

.card--seven .card-body h6 a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.card--seven .card-body p {
    margin-bottom: 0;
}

.card--seven .card-body .btn {
    margin-top: 1.66667rem;
}

.card--seven:hover .card-body h5 a {
    color: #06b590;
}

.card--eight .card-body {
    padding-bottom: 2rem;
}

.card--eight .card-body h5 {
    margin-bottom: 1rem;
}

.card--eight .card-body h5 a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.card--eight .card-body h5 a:hover {
    color: #06b590;
}

.card--eight .card-body p {
    margin-bottom: 0;
}

.card--eight .card-body .btn {
    margin-top: 1.66667rem;
}

.card--ten {
    margin-bottom: 4.66667rem;
}

.card--ten figure {
    margin-bottom: 0;
}

.card--eleven {
    position: relative;
    border: 0 none;
    overflow: hidden;
}

.card--eleven figure img {
    -webkit-border-radius: 0.33333rem;
    border-radius: 0.33333rem;
}

.card--eleven .card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
    background: rgba(65, 58, 164, 0.7);
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 1.33333rem 2rem;
    transition: all 0.3s ease;
}

.card--eleven .card-body .card-contents .content-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.card--eleven .card-body .card-contents .content-top span {
    font-size: 2.4rem;
    color: #fff;
    margin-right: 1rem;
}

.card--eleven .card-body .card-contents .content-top h6 {
    margin-bottom: 0;
}

.card--eleven .card-body .card-contents .content-bottom {
    max-height: 0;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    overflow: hidden;
}

.card--eleven .card-body .card-contents .content-bottom p {
    margin-top: 1rem;
}

.card--eleven .card-body h6 {
    color: #fff;
    margin-bottom: 0;
}

.card--eleven .card-body p {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2rem;
}

.card--eleven:hover .card-body {
    min-height: 100%;
}

.card--eleven:hover .card-body .card-contents {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 2.66667rem;
}

.card--eleven:hover .card-body .card-contents .content-top {
    display: block;
    text-align: center;
}

.card--eleven:hover .card-body .card-contents .content-top span {
    display: none;
}

.card--eleven:hover .card-body .card-contents .content-top h5 {
    margin-bottom: 2rem;
}

.card--eleven:hover .card-body .card-contents .content-bottom {
    max-height: 300px;
}

.card-twelve {
    border: 0 none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
}

.card-twelve .card-body {
    padding-bottom: 2rem;
}

.card-twelve .card-body .step {
    display: block;
    font-size: 2.4rem;
    font-weight: 500;
    color: #06b590;
    line-height: 1;
}

.card-twelve .card-body h6 {
    margin: 1rem 0;
    color: #202428;
}

.card-twelve .card-body p {
    margin-bottom: 1.6rem;
}

.card--fourteen {
    position: relative;
    border: 0 none;
}

.card--fourteen:hover .card-body {
    visibility: visible;
    opacity: 1;
    bottom: 0;
}

.card--fourteen figure {
    position: relative;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
}

.card--fourteen .card-body {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    background: rgba(55, 125, 255, 0.9);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 1rem 2rem;
    transition: all 0.3s ease;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.card--fourteen .card-body .card-contents .content-top h6 {
    margin-bottom: 0;
}

.card--fourteen .card-body .card-contents .content-top h6 a {
    color: #fff;
}

.card--fourteen .card-body .card-contents .content-top span {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.33333rem;
    display: block;
}

.card--testimonial {
    padding: 2rem;
    position: relative;
    z-index: 1;
}

.card--testimonial:before {
    position: absolute;
    content: "\f2ce";
    right: 2rem;
    top: 2rem;
    color: rgba(65, 58, 164, 0.1);
    font-size: 5.33333rem;
    font-family: LineAwesome;
    line-height: 1;
    z-index: -1;
}

.card--testimonial .author {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.card--testimonial .author .author-spec {
    margin-left: 1rem;
}

.card--testimonial .author .author-spec h6 {
    margin-bottom: 0.53333rem;
}

.card--testimonial .content {
    margin-top: 1.33333rem;
}

.card--testimonial .content p {
    margin: 0;
}

.card--testimonial2 {
    margin-bottom: 2rem;
    border: 1px solid #f3f3f3 !important;
}

.card--testimonial2 .card-body h6 {
    padding-top: 18px;
    color: #202428;
}

.card--testimonial2 .ratings {
    margin: 0.33333rem 0 1rem 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.card--testimonial2 .ratings span {
    margin: 0 1px 0 0;
}

.card--testimonial2 .ratings span:last-child {
    margin--right: 0;
}

.card--testimonial2 .author-spec {
    margin-top: 1.33333rem;
    margin-bottom: 0;
}

.card--testimonial2 .author-spec strong {
    font-weight: 500;
    color: #202428;
}

.team-carousel-one .nav-square {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.team-carousel-one:hover .nav-square {
    visibility: visible;
    opacity: 1;
}

.card--team1 {
    border: 1px solid #f3f3f3 !important;
}

.card--team1 .card-body {
    padding: 2rem;
}

.card--team1 .card-body h6 {
    margin: 1.33333rem 0 0.66667rem 0;
}

.card--team1 .card-body .team-designation {
    padding: 0 1rem;
    line-height: 2rem;
    display: inline-block;
    background: rgb(65 58 164 / 15%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #413aa4;
}

.card--team1 .card-body .team-social {
    margin: 1.66667rem 0 0 0;
}

.card--team1 .card-body .team-social li {
    margin-right: 0.66667rem;
}

.card--team1 .card-body .team-social li:last-child {
    margin-right: 0;
}

.card--team1 .card-body .team-social li a {
    color: rgba(145, 146, 163, 0.7);
    font-size: 0.93333rem;
    width: 2.26667rem;
    height: 2.26667rem;
    -webkit-border-radius: 20rem;
    border-radius: 20rem;
    background: rgba(245, 247, 252, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.card--team1 .card-body .team-social li a:hover {
    color: #fff;
}

.card--team2 {
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
}

.card--team2 figure {
    position: relative;
    overflow: hidden;
    margin-bottom: 0.66667rem;
}

.card--team2 figure figcaption {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #413aa4;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
}

.card--team2 figure figcaption .team-social {
    margin: 0 0 2.66667rem 0;
}

.card--team2 figure figcaption .team-social li {
    margin-right: 1.33333rem;
}

.card--team2 figure figcaption .team-social li:last-child {
    margin-right: 0;
}

.card--team2 figure figcaption .team-social li a {
    color: #fff;
    font-size: 1.06667rem;
}

.card--team2 figure figcaption .btn-outline-light {
    color: #fff;
}

.card--team2 .card-body {
    padding: 1rem;
    text-align: center;
}

.card--team2 .card-body h6 {
    color: #fff;
    margin-bottom: 0.33333rem;
}

.card--team2 .card-body span {
    color: rgba(255, 255, 255, 0.7);
}

.card--team2:hover figure figcaption {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.card--team3 {
    border: 0 none;
    -webkit-box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 2rem;
}

.card--team3 .card__thumbnail img {
    width: 100%;
}

.card--team3 .card-body {
    padding: 1.6rem 2rem;
}

.card--team3 .card-body>p {
    margin-bottom: 25px;
}

.card--team3 .card-body h6 {
    margin-bottom: 0.33333rem;
    color: #202428;
}

.card--team3 .card-body .subtitle {
    display: block;
    margin-bottom: 1rem;
    font-size: 0.93333rem;
    color: #9192a3;
}

.card.card--team4 {
    margin-bottom: 2rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.card.card--team4 .team-image {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}

.card.card--team4 .team-info h6 {
    margin-bottom: 5px;
}

.card.card--team4 .team-info h6 a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.card.card--team4 p {
    margin: 0;
}

.card.card--team4:hover .team-info h5 a {
    color: #413aa4;
}

.post--card {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
}

.post--card .card-body h6 {
    margin: 0 0 0.66667rem 0;
}

.post--card .card-body h6 a {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.post--card .card-body h6 a:hover {
    color: #413aa4;
}

.post--card .card-body .post-meta {
    margin-bottom: 1rem;
}

.post--card .card-body .post-meta li {
    color: #9192a3;
    margin-right: 1rem;
}

.post--card .card-body .post-meta li:last-child {
    margin-right: 0;
}

.post--card .card-body p {
    margin-bottom: 0;
}

.post--card2 figure {
    padding: 1.33333rem 1.33333rem 0;
    position: relative;
}

.post--card2 figure figcaption {
    position: absolute;
    left: 2.66667rem;
    bottom: 1.33333rem;
}

.post--card2 figure figcaption a {
    width: 2.66667rem;
    height: 2.66667rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #e23636;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.post--card2 .card-body h5 {
    margin-bottom: 0.66667rem;
}

.post--card2 .card-body .post-meta {
    padding-bottom: 1.33333rem;
    margin-bottom: 1.33333rem;
    border-bottom: 1px solid #e4e8ed;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.post--card2 .card-body .post-meta li a {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.post--card2 .card-body .post-meta li a:hover {
    color: #413aa4;
}

.post--card2.post--card3 figure {
    padding: 0;
}

.post--card2.post--card3 figure figcaption {
    left: 1.33333rem;
}

.post--card2.post--card3 .card-body h6 {
    margin-bottom: 0.66667rem;
    line-height: 1.86667rem;
}

.post--card2.post--card3 .card-body .post-meta {
    padding-bottom: 1.2rem;
    margin-bottom: 0.8rem;
}

.card--rounded {
    -webkit-border-radius: 0.53333rem;
    border-radius: 0.53333rem;
}

.card--team1,
.card--team2,
.card-style-eight .card--eight,
.card-style-eleven .card--eleven,
.card-style-fourteen .card--fourteen,
.card-style-nine .card--nine,
.card-style-seven .card--seven,
.card-style-twelve .card-twelve,
.card-style-two .card-two,
.post--card1 .post--card {
    margin-bottom: 2rem;
}

.post--card4 {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    border: 0 none;
    margin-bottom: 2rem;
}

.post--card4:hover {
    border: 0 none;
}

.post--card4 .card-body .post-meta {
    margin-bottom: 0;
}

.divider h1,
.divider h2 {
    margin-bottom: 0.33333rem;
}

.divider .divider-split {
    display: inline-block;
    width: 5.66rem;
    height: 0.33333rem;
    position: relative;
}

.divider .divider-split:before {
    position: absolute;
    content: "";
    width: 4rem;
    height: 100%;
    left: 0;
    top: 0;
    background: #413aa4;
    -webkit-border-radius: 3.33333rem;
    border-radius: 3.33333rem;
}

.divider .divider-split:after {
    position: absolute;
    content: "";
    width: 1rem;
    height: 100%;
    right: 0;
    top: 0;
    background: #413aa4;
    -webkit-border-radius: 3.33333rem;
    border-radius: 3.33333rem;
}

.divider .divider-straight {
    display: inline-block;
    width: 4.66rem;
    height: 0.33333rem;
    background: #413aa4;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.divider .divider-line {
    display: block;
    height: 1px;
    background: #e4e8ed;
}

.divider p {
    margin: 1rem 0 0 0;
    font-size: 18px;
}

.divider.text-center p {
    max-width: 40rem;
}

.divider.divider-light {
    margin-bottom: 3.13333rem;
}

.divider.divider-light .divider-split:before {
    background: #fff;
}

.divider.divider-light .divider-split:after {
    background: #fff;
}

.divider.divider-simple h3 {
    margin-bottom: 1rem;
}

.divider.divider-simple h2 {
    margin-bottom: 1.33333rem;
}

.section-title-simple {
    width: 60rem;
    margin: 0 auto;
    text-align: center;
}

.section-title-simple h1 {
    margin-bottom: 10px;
    font-size: 30px;
}

.section-title-simple h6 {
    color: #0ac391;
    font-size: 24px;
}

.subtext {
    font-size: 1.33333rem;
    font-weight: 600;
    display: block;
    margin-bottom: 0.33333rem;
}

.section-title-primary {
    text-align: center;
    margin-bottom: 3rem;
}

.section-title-primary h3 {
    color: #202428;
    margin: 0 0 1rem;
    line-height: 2.93333rem;
}

.section-title-primary p {
    color: #202428;
    margin: 0;
}

.flip-card {
    margin-bottom: 2rem;
}

.flip-card .flip-wrapper {
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    min-height: 22rem;
}

.flip-card .flip-back,
.flip-card .flip-front {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 0.33333rem;
    border-radius: 0.33333rem;
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
}

.flip-card .flip-front {
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -o-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: blur(0);
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}

.flip-card .flip-back {
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -o-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: blur(0);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}

.flip-card .flip-wrapper:hover .flip-front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .flip-wrapper:hover .flip-back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .flip-wrapper:hover .front-contents {
    -webkit-transform: translateY(-50%) translateX(-650px) scale(1);
    -ms-transform: translateY(-50%) translateX(-650px) scale(1);
    transform: translateY(-50%) translateX(-650px) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .flip-wrapper:hover .back-contents {
    -webkit-transform: translateY(-50%) translateX(0) translateZ(0) scale(1);
    transform: translateY(-50%) translateX(0) translateZ(0) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-card .back-contents,
.flip-card .front-contents {
    text-align: center;
    padding: 0 3rem;
    position: relative;
    top: 50%;
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -o-transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1), -webkit-transform 0.6s cubic-bezier(0.5, 0.3, 0.3, 1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}

.flip-card .back-contents h6,
.flip-card .front-contents h6 {
    line-height: 2rem;
    margin-bottom: 1rem;
}

.flip-card .front-contents {
    -webkit-transform: translateY(-50%) translateX(0) scale(1);
    -ms-transform: translateY(-50%) translateX(0) scale(1);
    transform: translateY(-50%) translateX(0) scale(1);
}

.flip-card .front-contents span {
    font-size: 4rem;
}

.flip-card .back-contents {
    -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
}

.flip-card .back-contents p {
    color: rgba(255, 255, 255, 0.6);
}

.flip-card .back-contents .btn {
    margin-top: 1.66rem;
}

.flip-card .flip-overlay,
.flip-card .flip-overlay2 {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.flip-card .flip-overlay {
    background: #fff;
}

.icon-box span {
    font-size: 2.66667rem;
    margin-bottom: 1.33333rem;
    display: inline-block;
    line-height: 1;
}

.icon-box h6 {
    margin-bottom: 1.13333rem;
}

.icon-box p {
    margin: 0;
}

.icon-box-one,
.icon-box-two {
    margin-bottom: 3.33333rem;
}

.icon-box-two h5 {
    font-weight: 500;
}

.icon-box-three {
    margin-bottom: 2rem;
    background: #fff;
    -webkit-border-radius: 0.3rem;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    padding: 3rem 2rem;
    position: relative;
}

.icon-box-three:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background: #413aa4;
    left: 0;
    bottom: 0;
    margin: 0;
    visibility: hidden;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.icon-box-three:hover:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    visibility: visible;
}

.icon-box-three h6 {
    margin-bottom: 1.13333rem;
}

.icon-box-three p {
    margin-bottom: 0;
}

.icon-rounded-lg {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 5.33333rem;
    height: 5.33333rem;
    background: #413aa4;
    font-size: 2.4rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 2rem;
}

.icon-box-four {
    margin-bottom: 3.33333rem;
}

.icon-box-four .box-icon {
    margin-right: 2rem;
}

.icon-box-four .box-content h6 {
    margin: 0 0 1.13333rem 0;
    color: #202428;
}

.icon-box-four .box-content p {
    margin: 0;
}

.icon-box-four:hover .icon-rounded-sm {
    background: #06b590;
}

.icon-rounded-sm,
.icon-square-sm {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 4rem;
    height: 4rem;
    background: #413aa4;
    font-size: 2rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icon-rounded-sm {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 1.33333rem;
}

.icon-square-sm {
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.icon-box-five {
    padding: 2.66rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icon-box-five .icon-rounded-sm {
    background: #06b590;
}

.icon-box-five h6 {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 1.13333rem;
    color: #202428;
}

.icon-box-five p {
    margin-bottom: 2.33rem;
}

.icon-box-five:hover {
    background: #fff;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
}

.icon-box-five:hover .icon-rounded-sm {
    background: #413aa4;
}

.icon-box-five:hover h5 {
    color: #413aa4;
}

.icon-box-five:hover .btn:hover {
    background: #413aa4;
    border-color: #413aa4;
}

.icon-box-four h6 {
    color: #202428;
}

.icon-box-four .icon-square-sm {
    margin-bottom: 2.33rem;
}

.icon-box-four:hover .icon-square-sm {
    background: #06b590;
}

.icon-box-eight {
    margin-bottom: 3.33333rem;
}

.icon-box-eight h6 {
    margin: 0 0 1.13333rem 0;
    color: #202428;
}

.icon-box-eight .icon-square-sm {
    background: 0 0;
    border: 1px solid #06b590;
    color: #06b590;
    margin-bottom: 2.33rem;
}

.icon-box-eight p {
    margin-bottom: 0;
}

.icon-box-eight:hover .icon-square-sm {
    background: #06b590;
    border-color: #06b590;
    color: #fff;
}

.icon-box-nine {
    margin-bottom: 2rem;
    background: #fff;
    border-radius: 0.3rem;
    box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
    padding: 25px;
}

.icon-box-nine .icon-rounded-sm {
    background: 0 0;
    border: 1px solid rgba(6, 181, 144, 0.2);
    color: #06b590;
}

.icon-box-nine h6 {
    margin: 0;
}

.icon-box-nine p {
    margin: 1rem 0 0 0;
    font-size: 14px;
    line-height: 24px;
    margin-top: 5px;
}

.icon-box-nine:hover .icon-rounded-sm {
    background: #06b590;
    border-color: #06b590;
    color: #fff;
}

.icon-box-img {
    border: 1px solid #e4e8ed;
    padding: 3rem 2rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 2rem;
}

.icon-box-img .svg {
    width: 5.33333rem;
    margin-bottom: 1.33rem;
}

.icon-box-img h6 {
    margin-bottom: 1.13333rem;
}

.icon-box-img p {
    margin-bottom: 0;
}

.icon-box-img h6,
.icon-box-img p {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icon-box-img:hover {
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
}

.icon-box-img2 {
    margin-bottom: 5.33rem;
}

.icon-box-img2 .box-icon {
    margin-right: 2rem;
}

.icon-box-img2 .box-icon .svg {
    width: 4.66667rem;
}

.icon-box-img2 .box-content h6 {
    margin: 0 0 1.13333rem 0;
}

.icon-box-img2 .box-content p {
    margin: 0;
}

.icon-box-rtl {
    margin-bottom: 4rem;
}

.icon-box-rtl .box-icon {
    margin-left: 2rem;
}

.icon-box-rtl h5 {
    margin-bottom: 1.13333rem;
}

.icon-box-rtl:hover .icon-square-sm {
    background: #06b590;
}

.icon-box-13 {
    margin-bottom: 2rem;
    padding: 3.33333rem;
    border: 1px solid #e4e8ed;
}

.icon-box-13>span {
    font-size: 3.2rem;
}

.icon-box-fourteen {
    margin-bottom: 2rem;
}

.icon-box-fourteen .icon-rounded {
    font-size: 2.4rem;
    width: 4.66667rem;
    height: 4.66667rem;
    -webkit-border-radius: 1.6rem;
    border-radius: 1.6rem;
    color: #fff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 2rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icon-box-fourteen:hover .icon-rounded {
    background: #413aa4 !important;
}

.icon-box-15 {
    border: 1px solid #e4e8ed;
    padding: 3.33333rem 1.86667rem;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 2rem;
}

.icon-box-15 img {
    margin-bottom: 2.66667rem;
}

.icon-box-15 h6 {
    color: #202428;
    margin-bottom: 1.33333rem;
}

.icon-box-15:hover {
    border-color: transparent;
    -webkit-box-shadow: 0 10px 30px rgba(105, 106, 119, 0.15);
    box-shadow: 0 10px 30px rgba(105, 106, 119, 0.15);
    margin-top: -5px;
    margin-bottom: 5px;
}

ul {
    margin: 0;
}

.list-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.arrow-list1 {
    font-weight: 500;
    margin-bottom: 0.45rem;
    vertical-align: middle;
}

.arrow-list1:last-child {
    margin-bottom: 0;
}

.arrow-list1:before {
    content: "\f121";
    font-family: Lineawesome, sans-serif;
    margin-right: 10px;
}

.arrow-list2,
.arrow-list3,
.arrow-list5 {
    margin-bottom: 0.8rem;
}

.arrow-list2:last-child,
.arrow-list3:last-child,
.arrow-list5:last-child {
    margin-bottom: 0;
}

.arrow-list2 span,
.arrow-list3 span,
.arrow-list5 span {
    width: 1.4rem;
    height: 1.4rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 0.66667rem;
    margin-right: 0.86rem;
    color: #413aa4;
}

.arrow-list2 span,
.arrow-list5 span {
    background: rgba(65, 58, 164, 0.1);
}

.arrow-list3 span {
    background: #413aa4;
    color: #fff;
}

.arrow-list4 {
    margin-bottom: 0.66rem;
    position: relative;
    padding-left: 18px;
}

.arrow-list4:before {
    position: absolute;
    content: "\f359";
    font-family: 'Line Awesome Free';
    left: 0;
    top: 2px;
    font-size: 0.8rem;
}

.arrow-list4:last-child {
    margin-bottom: 0;
}

.arrow-list4 span {
    color: #9192a3;
    padding-right: 0.33333rem;
}

.arrow-list5 {
    font-weight: 500;
}

.content-list {
    margin-bottom: 2.66667rem;
}

.content-list:last-child {
    margin-bottom: 0;
}

.content-list .icon {
    margin-right: 1rem;
    font-size: 2rem;
    line-height: 1;
}

.content-list h6 {
    margin-bottom: 0.46667rem;
}

.content-list p {
    margin-bottom: 0;
}

.content-list .icon-circle {
    margin-right: 2rem;
}

.content-list .icon-circle span {
    width: 4rem;
    height: 4rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f5f4f7;
    font-size: 1.73rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.content-list:hover .icon-circle span {
    background: #06b590;
    color: #fff;
}

.icon-light {
    margin-bottom: 0;
}

.icon-light .content-list .icon-circle span {
    color: #fff;
}

.icon-light .content-list:hover .icon-circle span {
    background: #06b590;
    color: #fff;
}

.text-list {
    margin-bottom: 2rem;
}

.text-list:last-child {
    margin-bottom: 0;
}

.text-list h5,
.text-list h6 {
    color: #202428;
    margin-bottom: 1.2rem;
}

.text-list p {
    margin-bottom: 0;
}

.text-list .sub-text {
    font-size: 1.2rem;
    font-weight: 400;
    display: block;
    margin-bottom: 0.66rem;
}

.image-list {
    margin-bottom: 2.33333rem;
}

.image-list:last-child {
    margin-bottom: 0;
}

.image-list .img {
    margin-right: 1.66rem;
}

.image-list .img img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.image-list h6 {
    margin-bottom: 0.66rem;
    color: #202428;
}

.image-list .sub-text {
    margin-bottom: 1rem;
    display: block;
    color: #9192a3;
}

.image-list p {
    margin: 0;
}

.bullet_list {
    position: relative;
    padding-left: 1.66rem;
    margin-bottom: 0.66rem;
}

.bullet_list:last-child {
    margin-bottom: 0;
}

.bullet_list:before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    left: 0;
    top: 6px;
}

.bullet_list strong {
    font-weight: 500;
}

.bullet_list p {
    margin: 0;
}

.bullet_list.bullet_list--three {
    margin-bottom: 1.66667rem;
}

.bullet_list.bullet_list--three:last-child {
    margin-bottom: 0;
}

.checklist {
    margin-bottom: 0.66667rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.checklist:last-child {
    margin-bottom: 0;
}

.checklist span {
    font-size: 1rem;
    font-weight: 700;
    margin-right: 0.93rem;
}

.checklist.checklist-light {
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.checklist.checklist-light .svg {
    width: 1.2rem;
    fill: #fff;
}

.checklist.checklist-light span {
    font-weight: 400;
    margin-left: 0.8rem;
}

.blog-carousel-one .post-single .post-bottom a.checklist.checklist-light .svg,
.card .card-body h6 a.checklist.checklist-light .svg,
.card--eight .card-body h5 a.checklist.checklist-light .svg,
.card--ten .card-body h6 a.checklist.checklist-light .svg,
.checklist.checklist-light.color-dark .svg,
.counter.counter--5 .counter_single .checklist.checklist-light.title .svg,
.tab.tab--1 .tab_nav .nav .checklist.checklist-light.nav-link .svg,
footer.footer2 .widget.twit--widget a.checklist.checklist-light:hover .svg {
    fill: #202428;
}

.checklist .svg {
    margin-right: 8px;
    width: 0.8rem;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .checklist .svg {
        height: 12px;
    }
}

.checklist.icon-primary .svg .st0 {
    fill: #413aa4;
}

.checklist.icon-secondary .svg .st0 {
    fill: #06b590;
}

.checklist.icon-light .svg .st0 {
    fill: #fff;
}

.checklist.icon-dark .svg .st0 {
    fill: #202428;
}

.checklist-three {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0.66667rem;
    font-weight: 500;
}

.checklist-three:last-child {
    margin-bottom: 0;
}

.checklist-three .svg {
    width: 0.8rem;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .checklist-three .svg {
        height: 12px;
    }
}

.checklist-three .svg .st0 {
    fill: #06b590;
}

.checklist-three span {
    margin-left: 0.66667rem;
}

.checklist-three.checklist-light span {
    color: #fff;
    font-weight: 400;
}

.checklist-three.checklist-light .svg .st0 {
    fill: #fff;
}

.icon-list {
    margin-bottom: 1rem;
}

.icon-list:last-child {
    margin-bottom: 0;
}

.icon-list span {
    font-size: 1.33rem;
    padding-right: 0.66rem;
    line-height: 0;
}

.icon-list strong {
    font-weight: 500;
    color: #202428;
    margin-right: 1rem;
}

.icon-list.list-light {
    color: rgba(255, 255, 255, 0.5);
}

.icon-list.list-light strong {
    color: #fff;
}

.icon-list2 {
    margin-bottom: 2rem;
}

.icon-list2:last-child {
    margin-bottom: 0;
}

.icon-list2 .icon {
    font-size: 1.8rem;
    line-height: 1;
    margin-right: 1rem;
}

.icon-list2 h6 {
    margin-bottom: 0.6rem;
    color: #202428;
    font-weight: 500;
    font-size: 1.13333rem;
}

.icon-list2 .sub-text {
    color: #9192a3;
}

.icon-list2.list-light h6 {
    color: #fff;
}

.icon-list--two .icon-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.number-list {
    list-style: none;
    counter-reset: li;
    padding: 0;
}

.number-list li {
    counter-increment: li;
    margin-bottom: 0.66rem;
    position: relative;
    padding-left: 2rem;
}

.number-list li:last-child {
    margin-bottom: 0;
}

.number-list li:before {
    content: counter(li);
    display: inline-block;
    width: 1em;
    text-align: right;
    direction: rtl;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 500;
}

.number-list li.numbers-decimal-zero:before {
    content: counter(li, decimal-leading-zero);
    color: #202428;
}

.number-list li.numbers-decimal-zero.list-light:before {
    color: #fff;
}

.number-list li.numbers-roman:before {
    content: counter(li, lower-roman);
}

.number-list li.list-light {
    color: rgba(255, 255, 255, 0.7);
}

.process-list {
    margin: 0;
}

.process-list li {
    margin-bottom: 2rem;
}

.process-list li:last-child {
    margin-bottom: 0;
}

.process-list li:last-child .number span:before {
    content: none;
}

.process-list li:hover .number span {
    background: #06b590;
    color: #fff;
}

.process-list .number {
    margin-right: 2rem;
}

.process-list .number.gray-bg span {
    background: rgba(6, 181, 144, 0.1);
}

.process-list .number span {
    width: 4.53333rem;
    height: 4.53333rem;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 600;
    color: #06b590;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.process-list .content h6 {
    margin-bottom: 0.66667rem;
    color: #202428;
    font-weight: 600;
}

.process-list .content p {
    margin: 0;
}

.list-inline-wrapper {
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.list-inline-wrapper .list--inline {
    margin: 0;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.list-inline-wrapper .list--inline li {
    margin-bottom: 2rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.list-inline-wrapper .list--inline li .icon {
    margin-right: 1rem;
}

.list-inline-wrapper .list--inline li .icon span {
    font-size: 3.33333rem;
    display: block;
    line-height: 2rem;
}

.list-inline-wrapper .list--inline li .contents h6 {
    font-weight: 600;
    color: #202428;
    margin: 0 0 5px;
}

.list-count {
    margin-bottom: 1rem;
}

.list-count:last-child {
    margin-bottom: 0;
}

.list-count span {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.list-count .count {
    min-width: 3rem;
    height: 1.66667rem;
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    background: #f5f7fc;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.list-count a:hover span {
    color: #413aa4;
}

.list-count a:hover .count {
    color: #fff;
    background: #413aa4;
}

.progress-wrapper {
    margin-bottom: 2rem;
}

.progress-wrapper:last-child {
    margin-bottom: 0;
}

.progress-wrapper .labels {
    margin-bottom: 0.66rem;
}

.progress-wrapper .labels span {
    color: #202428;
}

.progress-wrapper .labels span:last-child {
    font-weight: 600;
}

.progress-wrapper .progress {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-wrapper .progress .progress-bar {
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
}

.progress-dark {
    padding: 2.33rem 2.66rem;
    background: #202428;
}

.progress-dark .labels span {
    color: #fff;
}

.progress-dark .progress {
    background: #414144;
}


.line-chart_wrap h6 {
    font-weight: 600;
    color: #202428;
    margin-bottom: 2.66667rem;
}

.legend ul {
    margin-top: 1.33333rem;
    margin-bottom: 0;
}

.legend ul li {
    display: inline-block;
    margin-right: 1.33333rem;
}

.legend ul li:last-child {
    margin-right: 0;
}

.legend ul li span {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-right: 0.46667rem;
}

.legend.legend-list {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.legend.legend-list ul li {
    display: block;
}

.pie-chart {
    position: relative;
}

.block-full-width,
.block-full-width2 {
    min-height: 37.66667rem;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f2f2f2;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .block-full-width,
    .block-full-width2 {
        padding-top: 30px;
    }
}

.block-full-width .img,
.block-full-width2 .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
}

.block-full-width2 {
    background: 0 0;
}

.block-full-width2 .img {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    width: 50%;
    height: 100%;
}

.feature-boxes .icon-box-nine {
    margin-bottom: 2rem;
    min-height: 180px;
}

.section-feature-boxes {
    overflow: hidden;
}

.section-feature-boxes .container {
    position: relative;
}

.section-feature-boxes .section-img {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.section-feature-boxes .feature-boxes {
    margin-bottom: -30px;
}

.content--block3 .content_block3_wrapper {
    padding-bottom: 4.66667rem;
}

.content--block3 .content_block3_wrapper:last-child {
    padding-bottom: 0;
}

.content--block3 .content_block3_wrapper+.content_block3_wrapper {
    padding-top: 4.66667rem;
}

.content--block3 .content_block3_wrapper:nth-child(2n + 2) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

@media (min-width: 992px) {
    .content--block3 .content_block3_wrapper:nth-child(2n + 2) .offset-lg-1 {
        margin-left: 0;
        margin-right: 8.33333%;
    }
}

.content--block3 .content_block3_wrapper+.content_block3_wrapper {
    border-top: 1px solid #e4e8ed;
}

.content--block3 h4 {
    color: #202428;
}

.content-block--12 {
    position: relative;
}

.content-block--12 .counter-wrapper {
    position: relative;
}

.content-block--12 .counter-wrapper .counter--alt {
    padding: 6.06667rem 0 11.33333rem;
}

.content-block--12 .clients-logo {
    padding: 13.33333rem 0 6.66667rem;
}

.content-block--12 .highlighted-text {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 1;
}

.content-block--12 .highlighted-text .content {
    background: #fff;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    min-height: 14.66667rem;
    padding: 0 6.66667rem;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .content-block--12 .highlighted-text .content {
        height: 220px;
    }
}

.content-block--12 .highlighted-text .content p {
    font-size: 1.6rem;
    line-height: 2.93333rem;
    color: #202428;
    font-weight: 600;
    margin-bottom: 0;
}

.content-block--15 {
    padding: 6.8rem 0 7.33333rem;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f8f7fd));
    background: -webkit-linear-gradient(top, #fff, #f8f7fd);
    background: -o-linear-gradient(top, #fff, #f8f7fd);
    background: linear-gradient(to bottom, #fff, #f8f7fd);
}

.content-block--15 .section-title {
    text-align: center;
    margin-bottom: 2.33333rem;
}

.content-block--15 .section-title h2 {
    color: #202428;
}

.content-block--15 .section-title p {
    color: #202428;
}

.content-block--15 .content-desc h4 {
    color: #202428;
}

.content-block--6 {
    background: #fff;
}

.content--block8 h4 {
    margin-bottom: 20px;
}

.section_title h1 {
    font-weight: 600;
    margin-bottom: 3.46667rem;
}

.section_title .section_title_underline {
    width: 70px;
    background: #413aa4;
    display: inline-block;
    height: 5px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.contact-split {
    position: relative;
    height: 720px;
}

.contact-split .g-maps {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 50%;
}

.contact-split .g-maps #map2 {
    height: 100%;
}

.contact-split .g-maps .map-top {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #fff;
    padding: 55px 40px;
    -webkit-border-radius: 8px 0 0 8px;
    border-radius: 8px 0 0 8px;
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.contact-split .g-maps .map-top ul {
    margin: 0;
}

.filter-wrapper .filter-sort {
    margin-bottom: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.filter-wrapper .filter-sort ul {
    display: inline-block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    margin: 0;
}

.filter-wrapper .filter-sort ul li {
    float: left;
    color: #202428;
    font-weight: 400;
    line-height: 3.33333rem;
    padding: 0 1.66667rem;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
}

.filter-wrapper .filter-sort ul li.active,
.filter-wrapper .filter-sort ul li:hover {
    color: #fff;
}

.filter-wrapper .filter-sort2 ul {
    padding-bottom: 1.33333rem;
    margin-bottom: 3.33333rem;
    border-bottom: 1px solid #e4e8ed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.filter-wrapper .filter-sort2 ul li {
    margin-right: 2.33333rem;
    color: #202428;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.filter-wrapper .filter-sort2 ul li.active,
.filter-wrapper .filter-sort2 ul li:hover {
    color: #06b590;
}

.filter-wrapper .filter-sort2 ul li:last-child {
    margin-right: 0;
}

.event-single {
    margin-bottom: 2rem;
    height: 13.33333rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
}

.event-single .event-date {
    width: 15.33333rem;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.event-single .event-date h1 {
    color: #fff;
    margin-bottom: 0;
}

.event-single .event-date .month {
    font-size: 1.2rem;
    color: #fff;
}

.event-single .event-date .year {
    display: block;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
}

.event-single .event-info {
    padding: 2.33333rem 2.66667rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 3;
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.event-single .event-info h5 {
    margin-bottom: 1rem;
}

.event-single .event-info h5 a {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.event-single .event-info h5 a:hover {
    color: #413aa4;
}

.event-single .event-info .view-btn {
    margin-left: auto;
}

.social ul li {
    margin-right: 0.66667rem;
}

.social ul li:last-child {
    margin-right: 0;
}

.social ul li a {
    line-height: 2.53333rem;
    width: 2.53333rem;
    display: inline-block;
    text-align: center;
    -webkit-border-radius: 33.33333rem;
    border-radius: 33.33333rem;
    background: #29292d;
    color: #fff;
}

.social ul li a span {
    font-size: 0.86667rem;
}

.social ul li a:hover {
    background-color: #413aa4;
    color: #fff;
}

.social.social--small ul li a {
    line-height: 2.53333rem;
    width: 2.53333rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.social.social--small ul li a span {
    color: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.social.social--small ul li a:hover span {
    color: #fff;
}

.social.square ul li a {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: 0 0;
    border: 1px solid #e4e8ed;
}

.social.social--gray ul li a {
    background: #f5f4f7 !important;
    color: #5a6671;
}

.social.social--gray ul li a span {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #9192a3;
}

.social.social--gray ul li a:hover {
    color: #fff;
    background: #413aa4;
}

.social.social--gray ul li a:hover span {
    color: #fff;
}

.social.social--colored ul li a.facebook {
    background: #3b5998;
}

.social.social--colored ul li a.twitter {
    background: #47adfa;
}

.social.social--colored ul li a.linkedin {
    background: #0677b4;
}

.social.social--colored ul li a.gplus {
    background: #dd4b39;
}

.social.social--colored ul li a.pinterest {
    background: #bc091b;
}

.social-basic ul li {
    margin-right: 1.66667rem;
}

.social-basic ul li:last-child {
    margin-right: 0;
}

.social-basic ul li a {
    color: rgba(32, 36, 40, 0.5);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.social-basic ul li a:hover {
    color: #06b590;
}

.social-basic .rounded-circle li {
    margin-right: 0.66667rem;
}

.social-basic .rounded-circle li a {
    width: 2.13333rem;
    height: 2.13333rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 0.8rem;
    color: rgba(90, 102, 113, 0.6);
}

.social-basic.icon-light li a {
    background: #fff;
    color: rgba(90, 102, 113, 0.6);
}

.social-basic.icon-light li a:hover {
    background: #413aa4;
    color: #fff;
}

.social-basic.icon-grey li a {
    background: rgba(65, 58, 164, 0.06);
}

.social-basic.icon-grey li a:hover {
    background: #413aa4;
    color: #fff;
}

.social-btns {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.social-btns li {
    margin-right: 1.33333rem;
}

.social-btns li:last-child {
    margin-right: 0;
}

.social-btns .btn {
    color: #fff;
}

.social-btns .btn span {
    padding-right: 0.53333rem;
}

.social-btns .btn-facebook {
    background: #3b5998;
}

.social-btns .btn-twitter {
    background: #47adfa;
}

.social-btns .btn-linkedin {
    background: #0677b4;
}

.social-btns .btn-gplus {
    background: #dd4b39;
}

.shadow-lg-2 {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1) !important;
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1) !important;
}

.shadow_lg--bgwhite {
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.shadow_lg2--bgwhite {
    -webkit-box-shadow: 0 10px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 10px 30px rgba(41, 41, 59, 0.1);
}

.top_bar {
    min-height: 3.33333rem;
}

.top_bar .infos .infos--single+.infos--single {
    margin-left: 2rem;
}

.top_bar .infos .infos--single span {
    color: #413aa4;
    font-size: 1.8rem;
    margin-right: 11px;
    margin-top: 3px;
}

.top_bar .infos .infos--single .info {
    line-height: 1.7rem;
}

.top_bar .infos .infos--single .info p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: #202428;
}

.top_bar .infos .infos--single .info small {
    font-size: 15px;
    color: #9192a3;
}

.top_bar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.top_bar .top_bar--lang {
    cursor: pointer;
}

.top_bar .top_bar--lang .dropdown .dropdown-menu {
    min-width: 150px;
}

.top_bar .top_bar--lang .dropdown .lang_flag {
    margin: 0 10px;
}

.top_bar .top_bar--lang .dropdown .dropdown-item img {
    margin-right: 12px;
}

.top_bar .top_bar--lang .dropdown .lang {
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
}

.top_bar .top_bar--lang .dropdown span.la {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.86667rem;
}

.top_bar .top_bar--info li {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
}

.top_bar .top_bar--info li span {
    margin-right: 8px;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.6);
}

.top_bar .top_bar--info li p {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.6);
}

.top_bar .top_bar--info li+li {
    margin-left: 2rem;
}

.top_bar .top_bar--social ul li {
    display: inline-block;
    line-height: 3.6rem;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.top_bar .top_bar--social ul li:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.top_bar .top_bar--social ul li a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    padding: 0 20px;
}

.top_bar .top_bar--social ul li a:hover {
    color: #fff;
}

.top_bar.top--bar2 .top_bar--info li {
    color: rgba(255, 255, 255, 0.3);
}

.top_bar.top--bar2 .top_bar--social ul li {
    border: 0;
}

.top_bar.top--bar2 .top_bar--social ul li a {
    padding: 0 11px;
    color: rgba(255, 255, 255, 0.6);
}

.top_bar.top--bar2 .top_bar--social ul li a:hover {
    color: #fff;
}

.top_bar.top--bar3,
.top_bar.top--bar4 {
    padding: 1.33333rem 0;
}

.top_bar.top--bar4 .infos .infos--single p {
    color: #202428;
}

.menu_area {
    -webkit-box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
    box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
    position: relative;
}

.menu_area.bgimage:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba(12, 13, 32, 0.8);
}

.menu_area .nav_right_content .nav_right_module+.nav_right_module {
    padding-left: 1.6rem;
}

.menu_area .nav_right_content .nav_right_module:last-child {
    padding-right: 0;
}

.menu_area .navbar {
    padding: 1rem;
}

.menu_area .navbar.navbar-primary .navbar-toggler {
    color: #fff;
}

.menu_area .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before {
    color: #928d8d;
}

.menu_area .navbar.navbar-bg .navbar-nav .nav-item.dropdown .nav-link:before {
    color: #ededed;
}

.menu_area .navbar .navbar-nav .nav-item {
    position: relative;
}

.menu_area .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
    font-size: 15px;
}

.menu_area .navbar .navbar-nav .nav-item.has_mega-lg {
    position: static;
}

.menu_area .navbar .navbar-nav .nav-item:hover .mega-menu {
    visibility: visible;
    opacity: 1;
    top: 4.53333rem;
}

.menu_area .navbar .navbar-nav .nav-item:hover .mega-menu-lg {
    top: 5.6rem;
}

/* .menu_area .navbar .navbar-nav .nav-item .nav-link:after {
    position: absolute;
    width: 100%;
    content: "";
    height: 1rem;
    bottom: -1.06667rem;
    left: 0;
} */

@media (min-width: 992px) {
    .menu_area .navbar .navbar-nav .nav-item.dropdown .dropdown-menu {
        display: block;
        top: 6.66667rem !important;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-box-shadow: 0 0 30px rgba(41, 41, 59, 0.1);
        box-shadow: 0 0 30px rgba(41, 41, 59, 0.1);
        opacity: 0;
        visibility: hidden;
        padding: 2rem;
    }

    .menu_area .navbar .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item {
        padding: 0 0 1rem;
    }

    .menu_area .navbar .navbar-nav .nav-item.dropdown .dropdown-menu .dropdown-item:last-child {
        padding: 0;
    }

    .menu_area .navbar .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
        top: 4.66667rem !important;
        opacity: 1;
        visibility: visible;
    }
}

.menu_area .navbar .navbar-nav .nav-item.dropdown .nav-link {
    position: relative;
}

.menu_area .navbar .navbar-nav .nav-item.dropdown .nav-link:before {
    position: absolute;
    content: "\f110";
    font-size: 0.66667rem;
    font-family: 'Line Awesome Free';
    color: rgba(0, 0, 0, 0.5);
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.menu_area .navbar.navbar-primary {
    padding: 0.66667rem 1.8rem;
    background: #413aa4;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.menu_area .navbar.navbar-primary .navbar-nav .nav-link {
    color: #fff;
}

.menu_area .navbar.navbar-primary .nav_right_content .nav_right_module>span.la {
    color: #fff;
}

.menu_area .navbar.navbar-primary .nav_right_content .social_module ul li a {
    color: rgba(255, 255, 255, 0.5);
}

.menu_area .navbar.navbar-primary .nav_right_content .social_module ul li a:hover {
    color: #fff;
}

.menu_area .navbar-nav .nav-link {
    font-weight: 500;
    text-transform: capitalize;
    padding: 1.2rem 1rem;
}

.menu_area.menu1 {
    background: #fff;
}

.menu_area.menu3 {
    background: #202428;
}

.menu_area.menu3 .nav_right_content .cart_module {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.menu_area.menu3 .nav_right_content .cart_module .cart_count {
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
}

.menu_area.menu3 .nav_right_content .nav_right_module {
    padding: 0 1.6rem;
}

.menu_area.menu3 .nav_right_content .nav_right_module:last-child {
    padding-right: 0;
}

.menu_area.menu3 .nav_right_content .nav_right_module .cart__icon span.la,
.menu_area.menu3 .nav_right_content .nav_right_module .la.search_trigger {
    color: rgba(255, 255, 255, 0.7);
}

.menu_area.menu4 {
    -webkit-box-shadow: 0 0.33333rem 1.33333rem rgba(87, 87, 87, 0.1);
    box-shadow: 0 0.33333rem 1.33333rem rgba(87, 87, 87, 0.1);
    border-top: 1px solid #e4e8ed;
}

.menu_area.menu4 .nav_right_content .nav_right_module {
    padding: 0 1.6rem;
}

.menu_area.menu4 .nav_right_content .nav_right_module+.nav_right_module {
    border-left: 1px solid #e4e8ed;
}

.menu_area.menu4 .nav_right_content .nav_right_module:last-child {
    padding-right: 0;
}

.menu_area.menu5 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.menu_area.menu5 .navbar {
    padding: 0;
}

.menu_area.menu5 .nav_right_content .search_module {
    line-height: 6rem;
    padding: 0 0.66667rem;
    color: #fff;
    font-size: 1.66667rem;
}

.menu_area.menu5 .nav_right_content .search_module span {
    font-size: 1.46667rem;
}

.menu_area.menu5 .nav_right_content .language_module {
    padding: 0 1.33333rem;
    cursor: pointer;
}

.menu_area.menu5 .nav_right_content .language_module .dropdown .lang {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
}

.menu_area.menu5 .nav_right_content .language_module .dropdown .lang_flag {
    padding: 0 0.66667rem;
}

.menu_area.menu5 .nav_right_content .language_module .dropdown span.la {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.86667rem;
}

.menu_area.menu5 .nav_right_content .language_module .dropdown .dropdown-menu .dropdown-item img {
    margin-right: 0.66667rem;
}

.menu_area.menu7 {
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}

.menu_area.menu7 .navbar .navbar-nav .nav-item.dropdown .nav-link:before {
    color: #fff;
}

@media (min-width: 992px) {
    .menu_area .mega-menu {
        position: absolute;
        -webkit-box-shadow: 0 0 30px rgba(41, 41, 59, 0.1);
        box-shadow: 0 0 30px rgba(41, 41, 59, 0.1);
        background: #fff;
        width: 46.66667rem;
        left: -2.66667rem;
        top: 6.66667rem;
        z-index: 10;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
    }
}

.menu_area .mega-menu ul {
    padding: 2.66667rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.menu_area .mega-menu ul li {
    margin-bottom: 1rem;
}

.menu_area .mega-menu ul li:last-child {
    margin-bottom: 0;
}

.menu_area .mega-menu ul li h6 {
    color: #202428;
    font-weight: 500;
    margin-bottom: 1.33333rem;
}

.menu_area .mega-menu ul li a {
    color: inherit;
    font-size: 15px;
}

.menu_area .mega-menu ul li a:hover {
    color: #413aa4;
}

.menu_area .mega-menu ul li+li h6 {
    margin-top: 2rem;
}

@media (min-width: 992px) {
    .menu_area .mega-menu.mega-menu-lg {
        width: 100%;
        left: 0;
    }
}

.menu6 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link,
.menu7 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link,
.menu8 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link {
    color: #fff;
}

.menu6 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before,
.menu7 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before,
.menu8 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before {
    color: rgba(255, 255, 255, 0.5);
}

.menu6 .navbar-dark .nav_right_content .cart_module .cart__icon span.la,
.menu6 .navbar-dark .nav_right_content .search_module>span,
.menu6 .navbar-dark .navbar-toggler,
.menu7 .navbar-dark .nav_right_content .cart_module .cart__icon span.la,
.menu7 .navbar-dark .nav_right_content .search_module>span,
.menu7 .navbar-dark .navbar-toggler,
.menu8 .navbar-dark .nav_right_content .cart_module .cart__icon span.la,
.menu8 .navbar-dark .nav_right_content .search_module>span,
.menu8 .navbar-dark .navbar-toggler {
    color: #fff;
}

.navbar-bg .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.navbar-bg .navbar-nav .nav-link:hover {
    color: #fff;
}

.navbar-bg .nav_right_content .nav_right_module .input-group-light span.icon-left .la {
    color: #5a6671;
}

.navbar-bg .nav_right_content .nav_right_module .search_trigger,
.navbar-bg .nav_right_content .nav_right_module span.la-la-angle-down {
    color: rgba(255, 255, 255, 0.7);
}

.navbar-bg .nav_right_content .nav_right_module .search_trigger:hover,
.navbar-bg .nav_right_content .nav_right_module span.la-la-angle-down:hover {
    color: #fff;
}

@media (max-width: 991.98px) {
    .navbar.navbar-dark .navbar-toggler {
        color: #fff;
    }

    .navbar .navbar-toggler {
        padding: 0.33333rem 0.66667rem;
        font-size: 1.66667rem;
        color: #413aa4;
        -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        border: 1px solid #f2f2f2;
    }

    .navbar .navbar-brand {
        width: 25%;
        margin: 0;
    }

    .navbar .navbar-brand img {
        max-width: 100%;
    }

    .navbar .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        width: 100%;
        z-index: 100;
        max-height: 300px;
        overflow: scroll;
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    }
}

.nav_right_content ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.nav_right_content .cart_module {
    position: relative;
}

.nav_right_content .cart_module .cart__icon {
    position: relative;
}

.nav_right_content .cart_module:hover .cart__items {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.nav_right_content .cart_module .cart__items {
    position: absolute;
    right: -20px;
    min-width: 350px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    top: calc(100% + 20px);
}

.nav_right_content .cart_module .cart__items .items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px solid #e4e8ed;
}

.nav_right_content .cart_module .cart__items .items .item_thumb {
    margin-right: 15px;
}

.nav_right_content .cart_module .cart__items .items .item_info {
    margin-right: 10px;
}

.nav_right_content .cart_module .cart__items .items .item_info>a {
    color: #202428;
    line-height: 1.53333rem;
    display: inline-block;
    margin-bottom: 10px;
}

.nav_right_content .cart_module .cart__items .items .item_info span {
    font-weight: 500;
}

.nav_right_content .cart_module .cart__items .items .item_remove {
    display: inline-block;
    -webkit-transition: initial;
    -o-transition: initial;
    transition: initial;
}

.nav_right_content .cart_module .cart__items .items .item_remove span {
    font-size: 14px;
    line-height: 30px;
    width: 2rem;
    background: #f4f4f4;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.nav_right_content .cart_module .cart__items .cart_info {
    padding: 20px 25px;
}

.nav_right_content .cart_module .cart__items .cart_info p {
    margin-bottom: 15px;
}

.nav_right_content .cart_module .cart__items .cart_info p span {
    font-weight: 500;
    font-size: 18px;
}

.nav_right_content .cart_module .cart__items .cart_info a+a {
    margin-left: 10px;
}

.nav_right_content .cart_module span.la {
    font-size: 1.66667rem;
    color: #202428;
}

.nav_right_content .cart_module .cart_count {
    position: absolute;
    top: -0.93333rem;
    left: 3px;
    display: inline-block;
    line-height: 1.33333rem;
    width: 1.2rem;
    text-align: center;
    font-size: 0.8rem;
    color: #fff;
    -webkit-border-radius: 13.33333rem;
    border-radius: 13.33333rem;
}

.nav_right_content .search_module {
    position: relative;
    z-index: 3;
}

.nav_right_content .search_module .search_area {
    position: absolute;
    width: 300px;
    right: -10px;
    opacity: 0;
    visibility: hidden;
    padding: 10px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: calc(100% + 40px);
    background: #fff;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.2);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.2);
}

.nav_right_content .search_module .search_area .input-group-light span.icon-left .la {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    color: #969696;
}

.nav_right_content .search_module .search_area.active {
    top: calc(100% + 19px);
    opacity: 1;
    visibility: visible;
}

.nav_right_content .search_module .search_area .search_field {
    border: 0;
}

.nav_right_content .search_module>span {
    font-size: 1.33333rem;
    color: #202428;
    cursor: pointer;
    line-height: 2rem;
}

.nav_right_content .social_module ul li {
    padding: 0 0.66667rem;
}

.nav_right_content .social_module ul li a {
    color: #9192a3;
}

.nav_right_content .social_module ul li a:hover {
    color: #413aa4;
}

@media (max-width: 1199.98px) {
    .nav_right_content .search_module {
        padding: 0 0.6rem 0 0.33333rem;
    }
}

.modal.search_modal {
    background: rgba(255, 255, 255, 0.9);
}

.modal.search_modal .searc__field input {
    background: 0 0;
    border: 2px solid rgba(255, 255, 255, 0.36);
    color: #fff;
    padding: 1.2rem 1.55rem;
}

.modal.search_modal .searc__field input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.modal.search_modal .searc__field input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.modal.search_modal .searc__field input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.modal.search_modal .input_with_embed button {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.54);
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.modal.search_modal .input_with_embed button:hover {
    color: #fff;
}

.modal.search_modal .modal-body {
    padding: 2.68rem 3.34rem;
}

.modal .modal-dialog.modal--fullwidth {
    max-width: 1140px;
}

.modal .modal-dialog .modal-content.transparent {
    background: #413aa4;
    -webkit-box-shadow: 0 0.5rem 1.33rem rgba(65, 58, 164, 0.5);
    box-shadow: 0 0.5rem 1.33rem rgba(65, 58, 164, 0.5);
    border: 0;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    padding: 0 8px;
    font-size: 0.8rem;
}

.breadcrumb li {
    font-size: 14px;
}

.breadcrumb li a {
    color: #202428;
    font-weight: 500;
}

.breadcrumb li a span {
    margin-right: 5px;
}

.breadcrumb li a:hover {
    color: #413aa4;
}

.breadcrumb li::before {
    font-family: LineAwesome, Poppins, sans-serif;
}

.breadcrumb_area .page_title {
    line-height: 4.8rem;
    font-weight: 600;
}

.breadcrumb_area.breadcrumb1 {
    padding: 3rem 0;
    background: #f5f7fc;
}

.breadcrumb_area.breadcrumb1 .page_title {
    margin: 0;
    text-transform: capitalize;
    color: #202428;
}

.breadcrumb_area.breadcrumb2 {
    padding-top: 7.1rem;
}

.breadcrumb_area.breadcrumb2 .page_title {
    color: #fff;
    margin-bottom: 5.6rem;
    text-align: center;
    width: 50rem;
    line-height: 3.6rem;
}

.breadcrumb_area.breadcrumb2 .breadcrumb li {
    color: rgba(255, 255, 255, 0.5);
}

.breadcrumb_area.breadcrumb2 .breadcrumb li a {
    color: #fff;
}

.breadcrumb_area.breadcrumb2 .breadcrumb li a:hover {
    color: #06b590;
}

.breadcrumb_area.breadcrumb-colored .page_title,
.breadcrumb_area.breadcrumb-gradient .page_title {
    color: #fff;
}

.breadcrumb_area.breadcrumb-colored .breadcrumb li a,
.breadcrumb_area.breadcrumb-gradient .breadcrumb li a {
    color: #fff;
}

.breadcrumb_area.breadcrumb-colored .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb_area.breadcrumb-colored .breadcrumb-item.active,
.breadcrumb_area.breadcrumb-gradient .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb_area.breadcrumb-gradient .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.5);
}

.breadcrumb3 {
    padding: 6.26667rem 0 10.46667rem;
}

.breadcrumb3 .breadcrumb_wrapper .subtext {
    font-size: 2rem;
}

.breadcrumb3 .breadcrumb_wrapper .page_title {
    width: 33.33333rem;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    line-height: 3.6rem;
}

.breadcrumb3 .breadcrumb_wrapper .breadcrumb {
    margin-top: 5.33333rem;
}

.breadcrumb3 .breadcrumb_wrapper .breadcrumb li a {
    color: #fff;
}

footer {
    background: #f4f4f4;
}

footer .footer__big {
    padding: 7.33333rem 0 3.33333rem;
}

footer .footer__big .text_widget p {
    line-height: 2rem;
    margin-bottom: 1.33333rem;
}

footer .footer__big .text_widget p .address,
footer .footer__big .text_widget p .mail,
footer .footer__big .text_widget p .tel {
    display: block;
    color: #5a6671;
}

footer .footer__big .text_widget p .mail:hover,
footer .footer__big .text_widget p .tel:hover {
    color: #06b590;
}

footer .footer__big .text_widget .footer_logo {
    margin-bottom: 2rem;
}

footer .footer__big .widget {
    margin-bottom: 4rem;
}

footer .footer__big .widget .widget__title {
    font-size: 1.06667rem;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
    position: relative;
}

footer .footer__big .widget .widget__title2 {
    font-size: 1.13333rem;
    color: #202428;
}

footer .footer__big .widget .widget__social {
    margin-top: 30px;
}

footer .footer__big .widget.subcribe--widget .subscribe_form .embed_icon {
    font-size: 1.33333rem;
    color: #5a6671;
}

footer .footer__big .widget.subcribe--widget .subscribe_form input {
    width: 100%;
    font-size: 1rem;
    color: #9192a3;
    outline: 0;
    padding-left: 1.4rem;
    padding-right: 1rem;
}

footer .footer__big .widget ul.links li {
    margin-bottom: 0.66667rem;
}

footer .footer__big .widget ul.links li:last-child {
    margin-bottom: 0;
}

footer .footer__big .widget ul.links li a {
    color: #5a6671;
}

footer .footer__big .widget ul.links li a:hover {
    color: #06b590;
}

footer .footer__big .widget--contact_info ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 31px;
}

footer .footer__big .widget--contact_info ul li span.la {
    font-size: 28px;
    margin-right: 12px;
}

footer .footer__big .widget--contact_info ul li .info p {
    margin: 0;
    color: #fff;
    font-size: 1.06667rem;
    line-height: 26px;
}

footer .footer__big .widget--contact_info ul li .info small {
    font-size: 14px;
}

footer .footer__big .widget--latest-news ul li {
    margin-bottom: 20px;
}

footer .footer__big .widget--latest-news ul li small {
    font-size: 1rem;
    display: block;
    line-height: 30px;
}

footer .footer__big .widget--latest-news ul li a {
    color: #fff;
    font-weight: 500;
    line-height: 28px;
}

footer .footer__small {
    background: #000;
}

footer .footer__small p {
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    padding: 30px 0;
}

footer .footer__small a {
    color: #06b590;
}

footer .footer__bottom .footer__bottom-content {
    border-top: 1px solid #eaeaea;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 2.33333rem 0;
}

footer .footer__bottom .footer__bottom-content p {
    margin: 0;
}

footer .footer__bottom .footer__bottom-content p>a {
    color: #06b590;
}

footer .footer__bottom .footer__bottom-content .social-basic li a {
    color: #9192a3;
}

footer .footer__bottom .footer__bottom-content .social-basic li a:hover {
    color: #06b590;
}

footer.footer--dark .footer__big .text_widget p a,
footer.footer--dark .footer__big .text_widget p span {
    color: rgba(255, 255, 255, 0.5);
}

footer.footer--dark .footer__big .widget.subcribe--widget p {
    color: rgba(255, 255, 255, 0.5);
}

footer.footer--dark .footer__big .widget .widget__title {
    color: #fff;
}

footer.footer--dark .footer__big .widget ul.links li a:hover {
    color: #06b590;
}

footer.footer--light .widget__social .social ul li a {
    background: #fff;
}

footer.footer--light .widget__social .social ul li a span {
    color: #9192a3;
}

footer.footer--light .widget__social .social ul li a:hover {
    background: #413aa4;
}

footer.footer--light .widget__social .social ul li a:hover span {
    color: #fff;
}

footer.footer--light .widget--contact_info ul li .info p {
    color: #202428;
    font-weight: 500;
}

footer.footer--light .widget--latest-news ul li a {
    color: #202428;
}

footer.footer2.footer--light {
    background: #fff;
}

footer.footer2.footer--light .widget.twit--widget .user .name {
    color: #06b590;
}

footer.footer2.footer--light .widget.twit--widget .user .name+span {
    color: #9192a3;
}

footer.footer2.footer--light .footer__small {
    background: #fff;
    border-top: 1px solid #e4e8ed;
}

footer.footer2.footer--light .footer__small p {
    color: #5a6671;
}

footer.footer2 .widget.twit--widget .user {
    margin-top: 14px;
}

footer.footer2 .widget.twit--widget .user .name {
    color: #fff;
}

footer.footer2 .widget.twit--widget .user .name+span:before {
    content: "-";
    margin: 0 3px;
}

footer.footer2 .widget.twit--widget a {
    display: inline-block;
}

footer.footer2 .widget.twit--widget span.fab {
    margin-right: 12px;
    font-size: 18px;
}

footer.footer2 .widget.twit--widget .twit_wrapper>span {
    position: relative;
    top: 0.46667rem;
}

footer.footer2 .footer__small p {
    color: rgba(255, 255, 255, 0.7);
}

footer.footer2 .footer__small p a:hover {
    color: #fff;
}

footer.footer3 .footer__small {
    background: #e4e8ed;
}

footer.footer3 .footer__small p {
    color: #5a6671;
}

footer.footer4 .footer__big {
    padding: 7.33333rem 0;
}

footer.footer4 .footer_menu_items ul li {
    padding: 0.94rem;
}

footer.footer4 .footer_menu_items ul li a {
    line-height: 2.14rem;
    color: rgba(255, 255, 255, 0.5);
}

footer.footer4 .footer_menu_items ul li a:hover {
    color: #fff;
}

footer.footer4 .footer__small .info_links li+li {
    margin-left: 17px;
}

footer.footer4 .footer__small .info_links:before {
    content: "|";
    margin: 0 30px;
    color: rgba(255, 255, 255, 0.2);
}

footer.footer4 .footer_menu_items {
    background: rgba(255, 255, 255, 0.1);
}

footer.footer4 .footer__small {
    padding: 1.66667rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

footer.footer4 .footer__small p,
footer.footer4 .footer__small ul {
    padding: 0;
    margin: 0;
}

footer.footer4 .social.square ul li a {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

footer.footer4 .social.square ul li a:hover {
    border-color: transparent;
}

footer.footer4.footer--light .footer4_text {
    color: #5a6671;
}

footer.footer4.footer--light .footer_menu_items {
    background: #fff;
}

footer.footer4.footer--light .footer__small {
    background: #fff;
}

footer.footer4.footer--light .footer__small p {
    color: #5a6671;
}

footer.footer4.footer--light .footer__small .info_links li a {
    color: #5a6671;
}

footer.footer4.footer--light .footer__small .social.square li a {
    color: #9192a3;
    border-color: #e4e8ed;
}

footer.footer4.footer--light .footer__small .social.square li a:hover {
    color: #fff;
    border-color: transparent;
}

footer.footer4 .footer4_text {
    margin: 3.33rem 0 4.66rem;
    line-height: 2rem;
}

footer.footer5.footer--bw .footer__big {
    background: #111115;
}

footer.footer5.footer--bw .footer__big .text_widget a {
    color: #fff;
}

footer.footer5.footer--bw .footer__big .text_widget a:hover {
    color: #413aa4;
}

footer.footer5.footer--bw .footer__big .widget ul.links a:hover {
    color: #fff;
}

footer.footer5.footer--bw .footer__big .widget .widget__title {
    color: #fff;
}

footer.footer5.footer--bw .footer_small {
    background: #000;
}

footer.footer--light-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfd), to(#f2f1fa));
    background: -webkit-linear-gradient(top, #fbfbfd, #f2f1fa);
    background: -o-linear-gradient(top, #fbfbfd, #f2f1fa);
    background: linear-gradient(to bottom, #fbfbfd, #f2f1fa);
}

footer.footer6 {
    position: relative;
    overflow: hidden;
    padding-top: 4.33333rem;
}

footer.footer6:before {
    position: absolute;
    content: "";
    width: 180%;
    height: 150%;
    background: #fff;
    left: -40%;
    top: -137%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

footer.footer6.footer--dark .widget .widget__title2 {
    color: #fff;
}

footer.footer6.footer--dark .footer__bottom .footer__bottom-content {
    border-color: rgba(255, 255, 255, 0.1);
}

footer.footer7 {
    position: relative;
    overflow: hidden;
    /* background: url(img/shape-wave3.png) center/100% 100% no-repeat; */
    padding-top: 4.66667rem;
}

footer.footer7.footer--dark .widget .widget__title2 {
    color: #fff;
}

footer.footer7.footer--dark .footer__bottom .footer__bottom-content {
    border-color: rgba(255, 255, 255, 0.1);
}

.pricing {
    text-align: center;
    padding: 30px;
    margin-bottom: 2rem;
}

.pricing .pricing__title h4 {
    line-height: 2rem;
    margin-bottom: 0;
    font-weight: 600;
}

.pricing .pricing__title span {
    color: #9192a3;
    line-height: 1.8rem;
    display: inline-block;
}

.pricing .pricing__price p {
    font-size: 3.33rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 4.93333rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.pricing .pricing__price p sup {
    font-size: 1.63rem;
}

.pricing .pricing__price p small {
    font-size: 1rem;
    position: relative;
    top: 0.53333rem;
}

.pricing.pricing--1 {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    padding: 36px 30px 40px;
    background: #fff;
}

.pricing.pricing--1 .pricing__title {
    margin-bottom: 2rem;
}

.pricing.pricing--1 .pricing__title h4 {
    font-size: 1.6rem;
}

.pricing.pricing--1 .pricing__price {
    max-width: 231px;
    margin: 0 auto;
}

.pricing.pricing--1 .pricing__price p {
    margin: 0;
}

.pricing.pricing--1 .pricing__features {
    padding: 2rem 0;
}

.pricing.pricing--1 .pricing__features ul {
    margin: 0;
}

.pricing.pricing--1 .pricing__features ul li {
    margin-bottom: 1rem;
}

.pricing.pricing--1 .pricing__features ul li:last-child {
    margin-bottom: 0;
}

.pricing.pricing--2 {
    background: #fff;
    padding: 0;
}

.pricing.pricing--2 .pricing__features_list {
    text-align: left;
    padding: 5.86rem 3.33rem 3.33rem;
}

.pricing.pricing--2 .pricing__features_list P {
    margin: 0;
}

.pricing.pricing--2 .pricing__features_list ul.list {
    margin-top: 63px;
}

.pricing.pricing--2 .pricing__features_list ul.list li {
    line-height: 3.2rem;
    font-weight: 500;
    color: #202428;
    border-bottom: 1px solid #e4e8ed;
}

.pricing.pricing--2 .pricing__features_list ul.list li:first-child {
    border-top: 1px solid #e4e8ed;
}

.pricing.pricing--2 .pricing__wrapper {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option {
    padding: 3.2rem 2rem 3.33rem;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option .pricing__title {
    margin-bottom: 22px;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option ul.favail {
    margin-bottom: 2.66rem;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option ul.favail li {
    line-height: 3.2rem;
    border-bottom: 1px solid #e4e8ed;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option ul.favail li:first-child {
    border-top: 1px solid #e4e8ed;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option ul.favail li i {
    font-size: 18px;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option ul.favail li i.la-close {
    color: #e23636;
}

.pricing.pricing--2 .pricing__wrapper .pricing_option:hover {
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.pricing.pricing--2 .pricing__wrapper .pricing__price {
    margin-bottom: 2.66rem;
}

.timeline1 .happening:hover .happening__period .wrapper:before {
    border-color: #06b590;
}

.timeline1 .happening:hover .happening__period .wrapper:after {
    background: rgba(6, 181, 144, 0.1);
}

.timeline1 .happening .happening__period {
    text-align: center;
    position: relative;
}

.timeline1 .happening .happening__period:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #e2e1ea;
    right: -41px;
    top: 0;
}

.timeline1 .happening .happening__period .wrapper {
    background: #fff;
    padding: 0;
    margin-top: 0;
    position: relative;
    min-height: 8.33333rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.timeline1 .happening .happening__period .wrapper:before {
    content: "";
    position: absolute;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    height: 10px;
    width: 10px;
    background: #fff;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    right: -60.5px;
    z-index: 1;
    border: 2px solid #afb0c3;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.timeline1 .happening .happening__period .wrapper:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    right: -65.5px;
    z-index: 1;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.timeline1 .happening .happening__period .wrapper .date {
    margin: 10px 0 0 0;
    line-height: normal;
}

.timeline1 .happening .happening__period .wrapper .year {
    font-size: 2.42rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.timeline1 .happening .happening__desc {
    padding: 0 0 3.33333rem;
    position: relative;
}

.timeline1 .happening .happening__desc .happening_title {
    color: #202428;
    line-height: 3.2rem;
    margin-bottom: 0.66rem;
    margin-top: -10px;
}

.timeline1 .happening p {
    margin-bottom: 0;
}

.timeline1 .happening:last-child .happening__desc {
    padding-bottom: 0;
}

.timeline2 {
    position: relative;
}

.timeline2:before {
    content: "";
    height: 100%;
    width: 1px;
    background: #e4e8ed;
    position: absolute;
    left: 0;
}

.timeline2 .happening2 {
    padding-bottom: 2.66667rem;
    padding-left: 3.2rem;
    position: relative;
}

.timeline2 .happening2:last-child {
    padding-bottom: 0;
}

.timeline2 .happening2:before {
    content: "";
    position: absolute;
    width: 12px;
    top: 43px;
    height: 12px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    border: 2px solid #afb0c3;
    background: #fff;
    left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.timeline2 .happening2:hover:before {
    border-color: #06b590;
}

.timeline2 .happening2 .happening2__period {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    line-height: normal;
}

.timeline2 .happening2 .happening2__title {
    color: #202428;
    font-weight: 500;
    line-height: 3.2rem;
    margin-bottom: 0;
}

.timeline2 .happening2 p {
    margin-bottom: 0;
}

.arabic {
    font-size: 1.46667rem;
}

.counter.counter--1,
.counter.counter--2 {
    padding: 6.33333rem 0;
}

.counter.counter--3 {
    padding: 7.33333rem 0;
}

.counter.counter--3 .counter_single .title {
    color: rgba(255, 255, 255, 0.7);
}

.counter.counter--4 .counter_single .title {
    color: #5a6671;
}

.counter.counter--5 {
    padding: 8rem 0 6rem;
}

.counter.counter--5 .counter_single {
    padding: 3rem 0;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #fff;
    margin-bottom: 2rem;
}

.counter.counter--5 .counter_single .value {
    margin-bottom: 0.33rem;
}

.counter.counter--5 .counter_single .title {
    font-weight: 500;
}

.counter.counter--6 {
    padding: 7.66667rem 0;
}

.counter.counter--6 .icon span {
    color: #fff;
}

.counter.counter--6 .title {
    color: rgba(255, 255, 255, 0.7);
}

.counter.counter--7 {
    padding: 6.66667rem 0 4.66667rem;
}

.counter.counter--7 .counter_left_content h4 {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 600;
}

.counter.counter--7 .counter_left_content p {
    color: #fff;
}

.counter.counter--7 .counter_single {
    background: #fff;
    padding: 1.93333rem 0;
    margin-bottom: 30px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

.counter.counter--7 .counter_single .title {
    color: #202428;
    font-weight: 500;
}

.counter_single {
    text-align: center;
}

.counter_single p {
    margin: 0;
}

.counter_single .icon {
    padding-bottom: 2.33rem;
}

.counter_single .icon span {
    color: #fff;
    font-size: 3.47rem;
}

.counter_single .value {
    font-size: 3.2rem;
    line-height: 3.66rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
}

.counter_single .title {
    color: #fff;
}

.shadow-lg-2 {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1) !important;
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1) !important;
}

.shadow_lg--bgwhite {
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.shadow_lg2--bgwhite {
    -webkit-box-shadow: 0 10px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 10px 30px rgba(41, 41, 59, 0.1);
}

.dot-light,
.owl-dots {
    text-align: center;
    margin-top: 2rem;
}

.dot-light .owl-dot,
.owl-dots .owl-dot {
    display: inline-block;
    margin-right: 0.66667rem;
}

.dot-light .owl-dot:last-child,
.owl-dots .owl-dot:last-child {
    margin-right: 0;
}

.dot-light .owl-dot:focus,
.owl-dots .owl-dot:focus {
    outline: 0;
}

.dot-light .owl-dot span,
.owl-dots .owl-dot span {
    width: 0.8rem;
    height: 0.8rem;
    -webkit-border-radius: 20rem;
    border-radius: 20rem;
    background: rgba(65, 58, 164, 0.3);
    display: inline-block;
}

.dot-secondary.dot-light .owl-dot span,
.owl-dots.dot-secondary .owl-dot span {
    border: 0 none;
    background: rgba(6, 181, 144, 0.2);
}

.dot-light .owl-dot span {
    border: 1px solid #fff;
}

.dot-light .owl-dot.active span {
    background: #fff;
}

.nav-circle button {
    width: 3.06667rem;
    height: 3.06667rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #fff;
    border: 0 none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.nav-circle button:focus {
    outline: 0;
}

.nav-circle .owl-prev {
    margin-left: -4rem;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.nav-circle .owl-next {
    margin-right: -4rem;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.nav-circle.nav-circle--light button {
    width: 2.66667rem;
    height: 2.66667rem;
    background: #fff;
    color: #9192a3;
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.nav-circle.nav-circle--light button:hover {
    color: #fff;
}

.nav-circle.nav-circle--lighten button {
    width: 2.66667rem;
    height: 2.66667rem;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    font-size: 0.86667rem;
}

.nav-circle.nav-circle--lighten button:hover {
    color: #fff;
}

.nav-square button {
    width: 2.66667rem;
    height: 2.66667rem;
    color: #fff;
    font-size: 1.06667rem;
    background: rgba(255, 255, 255, 0.4);
    border: 0 none;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.nav-square button:focus {
    outline: 0;
}

.nav-square .owl-prev {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.nav-square .owl-next {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.nav-square.nav-square-dark button {
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.nav-square.nav-square-dark .owl-prev {
    margin-left: -5px;
}

.nav-square.nav-square-dark .owl-next {
    margin-right: -5px;
}

.carousel-one .carousel-one-single {
    text-align: center;
}

.carousel-one .carousel-one-single figure {
    margin-bottom: 2rem;
}

.carousel-one .carousel-one-single figure img {
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.carousel-one .carousel-one-single h6 {
    margin-bottom: 1rem;
}

.carousel-one .carousel-one-single h6 a {
    color: #202428;
}

.carousel-one .carousel-one-single h6 a:hover {
    color: #06b590;
}

.carousel-one .carousel-one-single p {
    margin-bottom: 0;
}

.carousel-three {
    position: relative;
}

.carousel-three .carousel-single {
    padding: 1rem;
}

.carousel-four .carousel-single {
    padding: 1rem;
}

.portfolio-carousel .dot-circle,
.portfolio-carousel .dot-light {
    margin-top: 2rem;
}

.portfolio-carousel-single {
    margin-bottom: 2rem;
    overflow: hidden;
}

.portfolio-carousel-single figure {
    position: relative;
    margin: 0;
}

.portfolio-carousel-single figure figcaption {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(28, 28, 38, 0.85);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.portfolio-carousel-single figure figcaption h5 {
    margin-bottom: 1rem;
    color: #fff;
}

.portfolio-carousel-single figure figcaption h5,
.portfolio-carousel-single figure figcaption span {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.portfolio-carousel-single figure figcaption .link {
    position: absolute;
    right: -3.33333rem;
    top: -3.33333rem;
    width: 3.33333rem;
    height: 3.33333rem;
    color: #fff;
    font-size: 1.6rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 0 0 0 3px;
    border-radius: 0 0 0 3px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.portfolio-carousel-single figure:hover figcaption {
    opacity: 1;
    visibility: visible;
}

.portfolio-carousel-single figure:hover figcaption h5,
.portfolio-carousel-single figure:hover figcaption span {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.portfolio-carousel-single figure:hover figcaption .link {
    top: 0;
    right: 0;
}

.image-carousel-one figure {
    margin: 0;
}

.image-carousel-one .nav-circle .owl-prev {
    margin-left: 2rem;
}

.image-carousel-one .nav-circle .owl-next {
    margin-right: 2rem;
}

.image-carousel-two .carousel-single figure img {
    width: 100%;
}

.image-carousel-two .dot-light,
.image-carousel-two .owl-dots {
    margin-top: 1.06667rem;
}

.testimonial-carousel-one .carousel-single {
    text-align: center;
}

.testimonial-carousel-one .carousel-single img {
    width: auto;
    margin: 0 auto;
}

.testimonial-carousel-one .carousel-single h6 {
    margin: 1.33333rem 0 0.66667rem 0;
    color: #202428;
}

.testimonial-carousel-one .carousel-single .sub-text {
    color: #06b590;
    display: block;
    margin-bottom: 1.33333rem;
}

.testimonial-carousel-one .carousel-single p {
    font-size: 1.2rem;
    line-height: 2.26667rem;
    margin: 0;
}

.carousel-bg .bg_image_holder:before {
    position: absolute;
    content: "";
    background: rgba(27, 28, 57, 0.8);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.testimonial-carousel-three .nav-circle {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.testimonial-carousel-three:hover .nav-circle {
    visibility: visible;
    opacity: 1;
}

.testimonial-carousel-three .carousel-single {
    padding: 1rem;
}

.testimonial-carousel-three .carousel-single .card img {
    width: auto;
    margin: 0 auto;
}

.tc-four-wrapper.bg-grey {
    background: #f4f4f4;
}

.tc-four-wrapper.bg-grey .testimonial-carousel-four .carousel-single .author-spec strong {
    color: #202428;
}

.tc-four-wrapper.bg-grey .testimonial-carousel-four .carousel-single .author-text:before {
    color: #ededef;
}

.tc-four-wrapper.bg-grey .testimonial-carousel-four .carousel-single .author-text p {
    color: #5a6671;
}

.tc-four-wrapper.bg-grey .testimonial-carousel-four .nav-square button {
    background: #e1e1e7;
    color: #202428;
}

.testimonial-carousel-four {
    padding: 7.33333rem 0 6.93333rem;
}

.testimonial-carousel-four .carousel-single img {
    width: auto;
    margin: 0 auto;
}

.testimonial-carousel-four .carousel-single .author-spec {
    margin: 1.33333rem 0 0 0;
}

.testimonial-carousel-four .carousel-single .author-spec strong {
    font-weight: 500;
    color: #fff;
    font-size: 1.2rem;
}

.testimonial-carousel-four .carousel-single .author-spec span {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.6);
}

.testimonial-carousel-four .carousel-single .author-spec p {
    margin: 0;
}

.testimonial-carousel-four .carousel-single .author-text {
    width: 50rem;
    margin: 0 auto;
    padding-top: 3.33333rem;
    position: relative;
}

.testimonial-carousel-four .carousel-single .author-text:before {
    position: absolute;
    content: "\f2ce";
    font-family: LineAwesome;
    font-size: 10rem;
    color: rgba(255, 255, 255, 0.05);
    left: 50%;
    top: 0;
    line-height: 0.8;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
}

.testimonial-carousel-four .carousel-single .author-text p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    line-height: 2.26667rem;
    margin: 0;
}

.testimonial-carousel-four .nav-circle .owl-prev {
    margin-left: 0;
}

.testimonial-carousel-four .nav-circle .owl-next {
    margin-right: 0;
}

.testimonial-carousel-four .nav-circle .owl-next,
.testimonial-carousel-four .nav-circle .owl-prev {
    background: rgba(255, 255, 255, 0.05);
    font-size: 0.86667rem;
    color: #fff;
}

.testimonial-carousel-four .nav-circle .owl-next:hover,
.testimonial-carousel-four .nav-circle .owl-prev:hover {
    background: #fff;
    color: #202428;
}

.testimonial-carousel-five .carousel-single {
    padding: 1rem;
}

.testimonial-carousel-five .carousel-single .author-text {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    padding: 2.66667rem 2.66667rem;
    position: relative;
}

.testimonial-carousel-five .carousel-single .author-text:before {
    position: absolute;
    content: "";
    border-right: 1.33333rem solid transparent;
    border-left: 1.33333rem solid transparent;
    border-top: 1.33333rem solid #fff;
    bottom: -1.33333rem;
    left: 4.66667rem;
}

.testimonial-carousel-five .carousel-single .author-text p {
    margin-bottom: 0;
}

.testimonial-carousel-five .carousel-single .author-spec {
    margin-top: 2.66667rem;
    margin-left: 3.33333rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonial-carousel-five .carousel-single .author-spec .author-img {
    margin-right: 1.33333rem;
}

.testimonial-carousel-five .carousel-single .author-spec .author-desc h6 {
    margin: 0 0 5px 0;
    color: #202428;
}

.testimonial-carousel-five .nav-square-dark {
    top: 40%;
}

.testimonial-carousel-five .nav-square-dark .owl-prev {
    margin-left: 1rem;
}

.testimonial-carousel-five .nav-square-dark .owl-next {
    margin-right: 1rem;
}

.testimonial-carousel-six-wrapper {
    /* background: url(img/shape-wave.png); */
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3.2rem 0 6.93333rem 0;
}

.testimonial-carousel-six {
    position: relative;
}

.testimonial-carousel-six:before {
    position: absolute;
    content: "\f2ce";
    font-family: LineAwesome;
    font-size: 13.33333rem;
    color: rgba(6, 181, 144, 0.1);
    left: 100px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.testimonial-carousel-six .carousel-single {
    text-align: center;
}

.testimonial-carousel-six .carousel-single img {
    display: inline-block;
    width: auto;
}

.testimonial-carousel-six .carousel-single h5 {
    color: #202428;
    margin: 1.33333rem 0 0.66667rem;
}

.testimonial-carousel-six .carousel-single p {
    margin: 2rem 0 0 0;
}

.team-carousel-one .carousel-single {
    padding: 1rem;
}

.team-carousel-one .carousel-single img {
    width: auto;
    margin: 0 auto;
}

.team-carousel-two .dot-light,
.team-carousel-two .owl-dots {
    margin-top: 1.33333rem;
}

.team-carousel-three {
    background: #fff;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
}

.team-carousel-three .carousel-single {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.team-carousel-three .carousel-single .team-img {
    width: 45%;
}

.team-carousel-three .carousel-single .team-img img {
    -webkit-border-radius: 0.26667rem 0 0 0.26667rem;
    border-radius: 0.26667rem 0 0 0.26667rem;
}

.team-carousel-three .carousel-single .team-desc {
    width: 55%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 3.33333rem;
}

.team-carousel-three .carousel-single .team-desc h5 {
    color: #202428;
    margin: 0 0 0.53333rem;
}

.team-carousel-three .carousel-single .team-desc span {
    color: #9192a3;
}

.team-carousel-three .carousel-single .team-desc p {
    margin: 1rem 0 2rem 0;
}

.team-carousel-three .nav-circle .owl-prev {
    margin-left: -1.33333rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.team-carousel-three .nav-circle .owl-next {
    margin-right: -1.33333rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.team-carousel-three:hover .nav-circle .owl-next,
.team-carousel-three:hover .nav-circle .owl-prev {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.logo-carousel-one .carousel-single img,
.logo-carousel-two .carousel-single img {
    width: auto;
    margin: 0 auto;
}

.logo-carousel-one .owl-stage,
.logo-carousel-two .owl-stage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.logo-carousel {
    padding: 7.33333rem 0 7rem;
}

.logo-carousel .content-left h1 {
    line-height: 3.73333rem;
}

.logo-carousel-two .logo-box {
    background: #fff;
    margin-bottom: 2rem;
    min-height: 8rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .logo-carousel-two .logo-box {
        height: 8rem;
    }
}

.logo-carousel-two .logo-box:last-child {
    margin-bottom: 0;
}

.logo-carousel-three .carousel-single {
    min-height: 8rem;
}

.logo-carousel-three .carousel-single .logo-box {
    border: 1px solid #e4e8ed;
    height: 8rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.logo-carousel-three .carousel-single .logo-box img {
    width: auto;
}

.logo-carousel-three .nav-square-dark {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.logo-carousel-three .nav-square-dark .owl-prev {
    margin-left: -1.33333rem;
}

.logo-carousel-three .nav-square-dark .owl-next {
    margin-right: -1.33333rem;
}

.logo-carousel-three:hover .nav-square-dark {
    visibility: visible;
    opacity: 1;
}

.blog-carousel-one .post-single .post-meta ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-carousel-one .post-single .post-meta ul li {
    margin-right: 1rem;
}

.blog-carousel-one .post-single .post-meta ul li:last-child {
    margin-right: 0;
}

.blog-carousel-one .post-single .post-meta ul li span {
    padding: 0 1rem;
    line-height: 2rem;
    display: inline-block;
    color: #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.blog-carousel-one .post-single h6 {
    margin: 1.33333rem 0 1.06667rem 0;
}

.blog-carousel-one .post-single h6 a:hover {
    color: #06b590;
}

.blog-carousel-one .post-single .post-bottom {
    margin: 1.66667rem 0 0 0;
    padding-top: 2rem;
    border-top: 1px solid #e4e8ed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-carousel-one .post-single .post-bottom img {
    width: auto;
    margin-right: 0.66667rem;
}

.logo-grid-one {
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    overflow: hidden;
}

.logo-grid-one ul {
    margin-right: -1px;
    margin-bottom: -1px;
}

.logo-grid-one li {
    width: 25%;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    background: #fff;
    min-height: 13.33333rem;
}

.logo-grid-one li figure {
    margin: 0;
}

.logo-carousel-dark .single-slide img {
    width: auto;
}

.logo-grid-two .grid-single {
    background: #fff;
    margin-bottom: 2rem;
    min-height: 10rem;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .logo-grid-two .grid-single {
        height: 10rem;
    }
}

.logo-grid-three ul {
    margin: 0;
}

.logo-grid-three li {
    width: 25%;
    border: 1px solid #dee2e6;
    background: #fff;
    min-height: 13.33333rem;
    margin-right: -1px;
    margin-bottom: -1px;
}

.logo-grid-three li figure {
    margin: 0;
}

.logo-details-wrapper {
    padding: 6rem 0 0;
}

.logo-details {
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    background: #fff;
    padding: 7rem 3rem 2rem;
    margin-bottom: 7.33333rem;
    margin-top: 0.66667rem;
    position: relative;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
}

.logo-details .logo {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    height: 10.66667rem;
    width: 18.66667rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
}

.logo-details h5 {
    color: #202428;
    margin-bottom: 0.33333rem;
}

.logo-details .sub-title {
    display: block;
    margin-bottom: 0.8rem;
}

.logo-details p {
    margin: 0;
}

.logo-carousel-four .owl-stage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.logo-carousel-four .carousel-single {
    text-align: center;
}

.logo-carousel-four .carousel-single img {
    display: inline-block;
    width: auto;
}

.dropdown .dropdown-toggle:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.dropdown .dropdown-caret {
    display: inline-block;
    padding-left: 1rem;
}

.dropdown .dropdown-menu {
    top: 0.66667rem !important;
    min-width: 16.66667rem;
    margin: 0;
    padding: 0;
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    border: 0 none;
}

.dropdown .dropdown-menu .dropdown-item:hover {
    background: 0 0;
    color: #413aa4;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.dropdown .dropdown--btn .dropdown-item:hover {
    background: #413aa4;
    color: #fff;
    -webkit-border-radius: 0.2rem;
    border-radius: 0.2rem;
}

.dropdown .dropdown--text .dropdown-text-item {
    padding: 30px;
    margin: 0;
}

.gallery-image-view .portfolio-carousel-single {
    margin-bottom: 0.66667rem;
}

.gallery-image-view .portfolio-carousel-single img {
    width: auto;
}

.gallery-image-view .carousel-thumbs ul {
    margin: 0;
}

.gallery-image-view .carousel-thumbs ul li {
    margin: 0 0.33333rem;
}

.gallery-image-view .image-view-carousel .carousel-single figure img {
    width: 100%;
}

.gallery-fullwidth .portfolio-carousel-single {
    width: 25%;
}

.price-filter {
    margin-top: 10px;
}

.range-slider .slider-range {
    background: #e4e8ed;
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    height: 0.4rem;
    max-width: 13.33333rem;
}

.range-slider .slider-range .ui-slider-range {
    background: #413aa4;
}

.range-slider .slider-range .ui-slider-handle {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.26667rem solid #413aa4;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    top: -0.4rem;
    cursor: pointer;
}

.range-slider .slider-range .ui-slider-handle:focus {
    outline: 0;
}

.range-slider p {
    margin: 1.66667rem 0 0 0;
}

.range-slider .amounts {
    border: 0 none;
    margin-left: 1.33333rem;
    color: #5a6671;
    font-size: 1.06667rem;
    width: 6rem;
    background: 0 0;
}

.range-slider-dark .slider-range {
    background: rgba(65, 58, 164, 0.2);
}

.video-single figure {
    position: relative;
    margin: 0;
}

.video-single figure .v_img {
    position: relative;
}

.video-single figure .v_img:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(15, 15, 30, 0.2);
}

.video-single figure figcaption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.video-overlay figcaption {
    background: rgba(32, 36, 40, 0.3);
}

.video-banner {
    padding: 5rem 2rem;
    overflow: hidden;
}

.video-banner div a {
    color: #fff;
    font-weight: 500;
    font-size: 1.33333rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.video-banner div a .play-btn {
    margin-right: 2rem;
}

.video-bg {
    padding: 7.33333rem 0;
}

.video-bg.overlay:before {
    background: rgba(27, 28, 57, 0.8);
}

.video-bg .content_above a {
    color: #fff;
    font-weight: 500;
    font-size: 1.6rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.cta--one .sub-heading {
    font-size: 1.6rem;
    font-weight: 500;
}

.cta--one h2 {
    color: #fff;
    margin: 1.33333rem 0 3.33333rem;
    line-height: 3.2rem;
    font-weight: 500;
}

.splitted-banner {
    padding: 10rem 0;
    position: relative;
    overflow: hidden;
}

.splitted-banner .split-bg {
    position: absolute;
    width: 53%;
    height: 46.66667rem;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #06b590;
    z-index: 1;
    height: 100%;
}

.splitted-banner .split-bg:before {
    position: absolute;
    content: "";
    height: 150%;
    width: 26.66667rem;
    right: -28.66667rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-box-shadow: -6.66667rem 0 0 #06b590;
    box-shadow: -6.66667rem 0 0 #06b590;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.splitted-banner h1 {
    color: #fff;
}

.splitted-banner .btns a {
    margin-right: 2rem;
}

.splitted-banner .btns a:last-child {
    margin-right: 0;
}

.subscribe-wrapper {
    padding: 6.33333rem 0;
}

.subscribe-wrapper h2 {
    color: #fff;
    margin: 0 0 1rem 0;
}

.subscribe-wrapper p {
    font-size: 1.13333rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2.33333rem;
}

.subscribe-wrapper .subscribe-form button {
    margin-left: 2rem;
}

.subscribe--two {
    position: relative;
    background: -webkit-linear-gradient(310deg, #413aa4 0, #413aa4 25%, #06b590 100%);
    background: -o-linear-gradient(310deg, #413aa4 0, #413aa4 25%, #06b590 100%);
    background: linear-gradient(140deg, #413aa4 0, #413aa4 25%, #06b590 100%);
    padding-bottom: 10rem;
    overflow: hidden;
}

.subscribe--two:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 5.06667rem;
    left: 0;
    bottom: 0;
    /* background: url(img/sb2.png); */
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

.subscribe-six,
.subscribe-three {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    padding: 4rem 0;
    position: relative;
    z-index: 1;
}

.subscribe-six h4,
.subscribe-three h4 {
    margin: 0;
}

.subscribe-six .subscribe-form--inline input,
.subscribe-three .subscribe-form--inline input {
    background: #f4f4f4;
}

.subscribe-six .subscribe-form--inline button,
.subscribe-three .subscribe-form--inline button {
    margin-left: 2rem;
}

.subscribe-four {
    padding: 8.66667rem 0;
}

.subscribe-four h3 {
    margin: 0 0 0.53333rem 0;
    color: #fff;
}

.subscribe-four p {
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.2rem;
}

.subscribe-four form .input-group-subscribe button {
    background: #413aa4;
}

.subscribe--five h2 {
    color: #fff;
    margin-bottom: 1rem;
}

.subscribe--five p {
    color: #fff;
    font-size: 1.13333rem;
    margin-bottom: 2.4rem;
}

.subscribe--five .subscribe-form-two .form-control {
    padding-top: 0.33333rem;
    padding-bottom: 0.33333rem;
    height: 4rem;
}

.subscribe--five .subscribe-form-two .btn {
    line-height: 2.66667rem;
    right: 0.33333rem;
}

.subscribe-seven {
    padding: 7.66667rem 0;
    /* background: url(img/map-bg.png); */
    background-repeat: no-repeat;
    background-position: center;
}

.subscribe-form-two>div {
    position: relative;
}

.subscribe-form-two .form-control {
    height: 4.66667rem;
    -webkit-box-shadow: 0 10px 30px rgba(105, 106, 119, 0.15);
    box-shadow: 0 10px 30px rgba(105, 106, 119, 0.15);
    border: 0 none;
    -webkit-border-radius: 0.2rem;
    border-radius: 0.2rem;
    padding-right: 12.33333rem;
    padding-left: 2rem;
}

.subscribe-form-two .btn {
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.subscribe-contents h2 {
    line-height: 3.2rem;
    color: #202428;
    font-size: 1.6rem;
}

.subscribe-contents h2 span {
    color: #06b590;
    font-size: 2.4rem;
}

.subscribe-contents img {
    margin-top: 4.66667rem;
}

.subscribe-eight {
    padding: 6.33333rem 0 7.33333rem;
}

.twitter-feeds {
    padding: 7rem 0 7.2rem;
}

.twitter-feeds .feed-single .feed-header {
    margin-bottom: 2.66667rem;
}

.twitter-feeds .feed-single .feed-header span i {
    font-size: 2rem;
    color: #fff;
}

.twitter-feeds .feed-single .feed-header .user {
    color: #fff;
}

.twitter-feeds .feed-single .feed-header .time {
    color: rgba(255, 255, 255, 0.5);
}

.twitter-feeds .feed-single .feed-header p {
    margin: 0;
}

.twitter-feeds .feed-single .feed-content {
    width: 48.66667rem;
    margin: 0 auto;
}

.twitter-feeds .feed-single .feed-content p {
    font-size: 1.2rem;
    line-height: 2.13333rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1.33333rem;
}

.twitter-feeds .feed-single .feed-content .twit-link {
    color: #fff;
}

.twitter-feeds .feed-single .feed-content .twit-link:hover {
    color: #202428;
}

.twitter-feeds--light .feed-single .feed-header .user {
    color: #413aa4;
}

.twitter-feeds--light .feed-single .feed-header .time {
    color: rgba(32, 36, 40, 0.5);
}

.twitter-feeds--light .feed-single .feed-header span i {
    color: #06b590;
}

.twitter-feeds--light .feed-single .feed-content p {
    color: inherit;
}

.twitter-feeds--light .feed-single .feed-content .twit-link {
    color: #06b590;
}

.follow-btn .btn {
    text-transform: capitalize;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.follow-btn .btn i {
    font-size: 1.2rem;
    margin-right: 0.66667rem;
}

.cta-wrapper {
    padding: 6.86667rem 0 7.33333rem;
}

.cta-wrapper-sm {
    padding: 4rem 0;
}

.cta--two {
    position: relative;
    overflow: hidden;
    padding: 6.86667rem 0 10rem;
}

.cta--two:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 5.06667rem;
    left: 0;
    bottom: 0;
    /* background: url(img/sb2.png); */
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: bottom;
    -webkit-transform: rotateY(-180deg) translateY(0.8%);
    transform: rotateY(-180deg) translateY(0.8%);
    z-index: 1;
}

.cta--two .cta-content p {
    color: #fff;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 600;
    margin: 0;
}

.cta--two .action-btn {
    text-align: right;
}

.cta--eight .action-btn,
.cta--five .action-btn,
.cta--four .action-btn,
.cta--seven .action-btn,
.cta--six .action-btn {
    text-align: right;
}

.cta--three .cta-content p {
    color: #fff;
    font-size: 2.4rem;
    line-height: 3.66667rem;
    font-weight: 600;
    margin: 0 0 3rem;
}

.cta--four .cta-content h1 {
    color: #fff;
    font-weight: 600;
}

.cta--four .cta-content p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2rem;
    line-height: 2.26667rem;
}

.cta--four .cta-content .phone {
    font-size: 2.4rem;
    display: block;
    color: #fff;
    margin-bottom: 3rem;
    font-weight: 600;
}

.cta--five {
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.cta--five p {
    font-size: 2rem;
    color: #202428;
    font-weight: 600;
}

.cta--six p {
    font-weight: 600;
    font-size: 2rem;
}

.cta--seven {
    font-weight: 600;
    font-size: 2.4rem;
}

.cta--seven p {
    font-weight: 600;
    font-size: 2rem;
}

.cta--eight h1 {
    line-height: 3.66667rem;
}

.cta--nine {
    position: relative;
    overflow: hidden;
}

.cta--nine .cta--none-contents {
    position: relative;
}

.cta--nine .cta--none-contents:before {
    position: absolute;
    content: "";
    width: 25.13333rem;
    height: 55.53333rem;
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.03);
    -webkit-border-radius: 5.33333rem;
    border-radius: 5.33333rem;
    -webkit-transform: rotate(-45deg) translateX(82%);
    -ms-transform: rotate(-45deg) translateX(82%);
    transform: rotate(-45deg) translateX(82%);
}

.cta--nine .cta--none-contents:after {
    position: absolute;
    content: "";
    width: 33.33333rem;
    height: 55.53333rem;
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.03);
    -webkit-border-radius: 5.33333rem;
    border-radius: 5.33333rem;
    -webkit-transform: rotate(-45deg) translate(59%, 20%);
    -ms-transform: rotate(-45deg) translate(59%, 20%);
    transform: rotate(-45deg) translate(59%, 20%);
}

.cta--nine.cta-wrapper {
    padding-top: 4.66667rem;
}

.cta--nine h3 {
    color: #fff;
    font-weight: 300;
    line-height: 3.06667rem;
    font-size: 29px;
}

.cta--nine h3 span {
    font-weight: 600;
}

.cta--nine:before {
    position: absolute;
    content: "";
    width: 180%;
    height: 150%;
    background: #fff;
    left: -40%;
    top: 87%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.process-one {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
}

.process-one .process-single {
    width: 33.333%;
    margin-bottom: 4.66667rem;
    padding: 0 1rem;
}

.process-one .process-single:nth-child(2n + 1) .icon:before {
    content: url(../img/arrow-up.png);
}

.process-one .process-single .icon {
    width: 9.33333rem;
    height: 9.33333rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto 2rem;
}

.process-one .process-single .icon.gray-bg {
    background: rgba(6, 181, 144, 0.1);
}

.process-one .process-single .icon.light-bg {
    background: #fff;
}

.process-one .process-single .icon .icon-la {
    font-size: 24px;
    color: #413aa4;
}

.process-one .process-single .icon .step {
    position: absolute;
    width: 2.33333rem;
    height: 2.33333rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #202428;
    font-weight: 500;
    left: 0;
    top: 0;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
}

.process-one .process-single .icon:before {
    position: absolute;
    content: url(../img/arrow-down.png);
    right: -90%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.process-one .process-single h6 {
    margin-bottom: 1rem;
}

.process-one .process-single p {
    margin: 0;
}

.process-one .process-single:last-child,
.process-one .process-single:nth-child(4n) {
    margin-right: 0;
}

.process-one .process-single:last-child .icon:before,
.process-one .process-single:nth-child(4n) .icon:before {
    content: none;
}

.process-three {
    background: #fff;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    padding: 1.73333rem 2rem 1.6rem;
}

.process-three span {
    display: block;
    font-size: 2.33333rem;
    font-weight: 400;
    color: #06b590;
    margin-bottom: 1.2rem;
    line-height: 1;
}

.process-three h6 {
    margin-bottom: 1.2rem;
}

.process-three p {
    margin: 0;
}

.team-four {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
}

.team-four .subtitle {
    margin-bottom: 1.33333rem;
    display: inline-block;
    font-size: 0.93333rem;
}

.team-four img {
    -webkit-border-radius: 0.26667rem 0.26667rem 0 0;
    border-radius: 0.26667rem 0.26667rem 0 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.team-four .content {
    padding: 1.8rem 2rem 2rem;
    -webkit-border-radius: 0 0 0.26667rem 0.26667rem;
    border-radius: 0 0 0.26667rem 0.26667rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #fff;
}

.team-four .content h6 {
    margin-bottom: 0.66667rem;
    font-size: 1.06667rem;
}

.team-five .content {
    margin-top: 1.66667rem;
}

.team-five .content h6 {
    margin-bottom: 0.66667rem;
}

.team-five .content span {
    display: block;
}

.counter.counter--4 .counter_single .team-five .content span.value,
.nav_right_content .cart_module .cart__items .cart_info p .team-five .content span,
.nav_right_content .cart_module .cart__items .items .item_remove .team-five .content span,
.tab-content.tab--content2 .tab-pane .tab_text_module .team-five .content span.tab_text_module__subtitle,
.tab.tab--8 .tab_nav7 .nav .nav-item .team-five .content span.nav-link.active,
.team-five .content .counter.counter--4 .counter_single span.value,
.team-five .content .nav_right_content .cart_module .cart__items .cart_info p span,
.team-five .content .nav_right_content .cart_module .cart__items .items .item_remove span,
.team-five .content .tab-content.tab--content2 .tab-pane .tab_text_module span.tab_text_module__subtitle,
.team-five .content .tab.tab--8 .tab_nav7 .nav .nav-item span.nav-link.active,
.team-five .content .timeline1 .happening .happening__period .wrapper span.year,
.team-five .content span.color-primary,
.timeline1 .happening .happening__period .wrapper .team-five .content span.year {
    font-weight: 500;
}

.faq-single {
    margin-bottom: 40px;
}

.faq-single h6 {
    margin-bottom: 0.66667rem;
}

.faq-single p {
    margin: 0;
}

.event-sidebar {
    border: 1px solid #e4e8ed;
}

.widget-wrapper .widget-default {
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
    border: 1px solid #e4e8ed;
    margin-bottom: 2rem;
}

.widget-wrapper .widget-header .widget-title {
    margin: 0;
    border-bottom: 1px solid #e4e8ed;
    padding: 1.33333rem 2rem 1.33333rem 2rem;
}

.widget-wrapper .widget-content {
    padding: 2rem 2rem 2rem;
}

.widget-wrapper .widget-shadow {
    background: #fff;
    -webkit-box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 10px rgba(105, 106, 119, 0.05);
    -webkit-border-radius: 0.4rem;
    border-radius: 0.4rem;
}

.signup-widget .form-group {
    margin-bottom: 1.33333rem;
}

.signup-widget .form-group:last-child {
    margin-bottom: 0;
}

.search-widget {
    margin-bottom: 2rem;
}

.search-widget .input-group {
    position: relative;
}

.search-widget .input-group input {
    width: 100%;
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    background: #f5f4f7;
    border: 0 none;
    height: 3.33333rem;
    padding: 0 4rem 0 1.66667rem;
}

.search-widget .input-group input:focus {
    outline: 0;
}

.search-widget .input-group button {
    padding: 0 1.33333rem;
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    position: absolute;
    right: 0;
    top: 0;
    height: 3.33333rem;
    background: 0 0;
    border: 0;
    cursor: pointer;
}

.category-widget ul {
    margin-top: -6px;
}

.category-widget ul li a {
    color: #202428;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.category-widget ul li a:hover {
    color: #413aa4;
}

.sidebar-post .post-single {
    margin-bottom: 1.66667rem;
}

.sidebar-post .post-single:last-child {
    margin-bottom: 0;
}

.sidebar-post .post-single img {
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.sidebar-post .post-single P {
    margin: 0 0 0 1rem;
}

.sidebar-post .post-single P span {
    display: block;
    line-height: 1.46667rem;
}

.sidebar-post .post-single .post-title {
    margin-top: 0.66667rem;
    display: block;
    font-weight: 500;
    color: #202428;
}

.tags-widget ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -10px;
}

.tags-widget ul li {
    margin-right: 0.66667rem;
    margin-bottom: 0.66667rem;
}

.tags-widget ul li:last-child {
    margin-right: 0;
}

.tags-widget ul li a {
    line-height: 1.73333rem;
    display: block;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 2px 0.66667rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #5a6671;
    font-size: 0.86667rem;
}

.tags-widget ul li a:hover {
    color: #fff;
    background: #413aa4;
    border-color: #413aa4;
}

.addresses_carousel .adress img {
    max-width: 100%;
    width: auto;
    display: inline-block;
}

.addresses_carousel .dot-light,
.addresses_carousel .owl-dots {
    margin-top: 0;
}

.adress {
    padding: 0 2rem;
    margin-bottom: 3.33333rem;
}

.adress .nam {
    margin-top: 1.66667rem;
    font-size: 1.2rem;
    color: #202428;
    margin-bottom: 0.66667rem;
    font-weight: 600;
}

.adress img {
    max-width: 100%;
    width: auto;
    display: inline-block;
}

.adress p {
    margin: 0;
}

.adress {
    text-align: center;
}

.map#map1,
.map#map3,
.map#map4 {
    height: 400px;
}

.map#map2 {
    height: 500px;
}

footer {
    background: #f4f4f4;
}

footer .footer__big {
    padding: 7.33333rem 0 3.33333rem;
}

footer .footer__big .text_widget p {
    line-height: 2rem;
    margin-bottom: 1.33333rem;
}

footer .footer__big .text_widget p .address,
footer .footer__big .text_widget p .mail,
footer .footer__big .text_widget p .tel {
    display: block;
    color: #5a6671;
}

footer .footer__big .text_widget p .mail:hover,
footer .footer__big .text_widget p .tel:hover {
    color: #06b590;
}

footer .footer__big .text_widget .footer_logo {
    margin-bottom: 0rem;
    width: 130px;
}

footer .footer__big .widget {
    margin-bottom: 4rem;
}

footer .footer__big .widget .widget__title {
    font-size: 1.06667rem;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
    position: relative;
}

footer .footer__big .widget .widget__title2 {
    font-size: 1.13333rem;
    color: #202428;
}

footer .footer__big .widget .widget__social {
    margin-top: 30px;
}

footer .footer__big .widget.subcribe--widget .subscribe_form .embed_icon {
    font-size: 1.33333rem;
    color: #5a6671;
}

footer .footer__big .widget.subcribe--widget .subscribe_form input {
    width: 100%;
    font-size: 1rem;
    color: #9192a3;
    outline: 0;
    padding-left: 1.4rem;
    padding-right: 1rem;
}

footer .footer__big .widget ul.links li {
    margin-bottom: 0.66667rem;
}

footer .footer__big .widget ul.links li:last-child {
    margin-bottom: 0;
}

footer .footer__big .widget ul.links li a {
    color: #5a6671;
}

footer .footer__big .widget ul.links li a:hover {
    color: #06b590;
}

footer .footer__big .widget--contact_info ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 31px;
}

footer .footer__big .widget--contact_info ul li span.la {
    font-size: 28px;
    margin-right: 12px;
}

footer .footer__big .widget--contact_info ul li .info p {
    margin: 0;
    color: #fff;
    font-size: 1.06667rem;
    line-height: 26px;
}

footer .footer__big .widget--contact_info ul li .info small {
    font-size: 14px;
}

footer .footer__big .widget--latest-news ul li {
    margin-bottom: 20px;
}

footer .footer__big .widget--latest-news ul li small {
    font-size: 1rem;
    display: block;
    line-height: 30px;
}

footer .footer__big .widget--latest-news ul li a {
    color: #fff;
    font-weight: 500;
    line-height: 28px;
}

footer .footer__small {
    background: #000;
}

footer .footer__small p {
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    padding: 30px 0;
}

footer .footer__small a {
    color: #06b590;
}

footer .footer__bottom .footer__bottom-content {
    border-top: 1px solid #eaeaea;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 2.33333rem 0;
}

footer .footer__bottom .footer__bottom-content p {
    margin: 0;
}

footer .footer__bottom .footer__bottom-content p>a {
    color: #06b590;
}

footer .footer__bottom .footer__bottom-content .social-basic li a {
    color: #9192a3;
}

footer .footer__bottom .footer__bottom-content .social-basic li a:hover {
    color: #06b590;
}

footer.footer--dark .footer__big .text_widget p a,
footer.footer--dark .footer__big .text_widget p span {
    color: rgba(255, 255, 255, 0.5);
}

footer.footer--dark .footer__big .widget.subcribe--widget p {
    color: rgba(255, 255, 255, 0.5);
}

footer.footer--dark .footer__big .widget .widget__title {
    color: #fff;
}

footer.footer--dark .footer__big .widget ul.links li a:hover {
    color: #06b590;
}

footer.footer--light .widget__social .social ul li a {
    background: #fff;
}

footer.footer--light .widget__social .social ul li a span {
    color: #9192a3;
}

footer.footer--light .widget__social .social ul li a:hover {
    background: #413aa4;
}

footer.footer--light .widget__social .social ul li a:hover span {
    color: #fff;
}

footer.footer--light .widget--contact_info ul li .info p {
    color: #202428;
    font-weight: 500;
}

footer.footer--light .widget--latest-news ul li a {
    color: #202428;
}

footer.footer2.footer--light {
    background: #fff;
}

footer.footer2.footer--light .widget.twit--widget .user .name {
    color: #06b590;
}

footer.footer2.footer--light .widget.twit--widget .user .name+span {
    color: #9192a3;
}

footer.footer2.footer--light .footer__small {
    background: #fff;
    border-top: 1px solid #e4e8ed;
}

footer.footer2.footer--light .footer__small p {
    color: #5a6671;
}

footer.footer2 .widget.twit--widget .user {
    margin-top: 14px;
}

footer.footer2 .widget.twit--widget .user .name {
    color: #fff;
}

footer.footer2 .widget.twit--widget .user .name+span:before {
    content: "-";
    margin: 0 3px;
}

footer.footer2 .widget.twit--widget a {
    display: inline-block;
}

footer.footer2 .widget.twit--widget span.fab {
    margin-right: 12px;
    font-size: 18px;
}

footer.footer2 .widget.twit--widget .twit_wrapper>span {
    position: relative;
    top: 0.46667rem;
}

footer.footer2 .footer__small p {
    color: rgba(255, 255, 255, 0.7);
}

footer.footer2 .footer__small p a:hover {
    color: #fff;
}

footer.footer3 .footer__small {
    background: #e4e8ed;
}

footer.footer3 .footer__small p {
    color: #5a6671;
}

footer.footer4 .footer__big {
    padding: 7.33333rem 0;
}

footer.footer4 .footer_menu_items ul li {
    padding: 0.94rem;
}

footer.footer4 .footer_menu_items ul li a {
    line-height: 2.14rem;
    color: rgba(255, 255, 255, 0.5);
}

footer.footer4 .footer_menu_items ul li a:hover {
    color: #fff;
}

footer.footer4 .footer__small .info_links li+li {
    margin-left: 17px;
}

footer.footer4 .footer__small .info_links:before {
    content: "|";
    margin: 0 30px;
    color: rgba(255, 255, 255, 0.2);
}

footer.footer4 .footer_menu_items {
    background: rgba(255, 255, 255, 0.1);
}

footer.footer4 .footer__small {
    padding: 1.66667rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

footer.footer4 .footer__small p,
footer.footer4 .footer__small ul {
    padding: 0;
    margin: 0;
}

footer.footer4 .social.square ul li a {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

footer.footer4 .social.square ul li a:hover {
    border-color: transparent;
}

footer.footer4.footer--light .footer4_text {
    color: #5a6671;
}

footer.footer4.footer--light .footer_menu_items {
    background: #fff;
}

footer.footer4.footer--light .footer__small {
    background: #fff;
}

footer.footer4.footer--light .footer__small p {
    color: #5a6671;
}

footer.footer4.footer--light .footer__small .info_links li a {
    color: #5a6671;
}

footer.footer4.footer--light .footer__small .social.square li a {
    color: #9192a3;
    border-color: #e4e8ed;
}

footer.footer4.footer--light .footer__small .social.square li a:hover {
    color: #fff;
    border-color: transparent;
}

footer.footer4 .footer4_text {
    margin: 3.33rem 0 4.66rem;
    line-height: 2rem;
}

footer.footer5.footer--bw .footer__big {
    background: #111115;
}

footer.footer5.footer--bw .footer__big .text_widget a {
    color: #fff;
}

footer.footer5.footer--bw .footer__big .text_widget a:hover {
    color: #413aa4;
}

footer.footer5.footer--bw .footer__big .widget ul.links a:hover {
    color: #0d00c4;
    font-weight: 600;
}

footer.footer5.footer--bw .footer__big .widget .widget__title {
    color: #fff;
}

footer.footer5.footer--bw .footer_small {
    background: #000;
}

footer.footer--light-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfd), to(#f2f1fa));
    background: -webkit-linear-gradient(top, #fbfbfd, #f2f1fa);
    background: -o-linear-gradient(top, #fbfbfd, #f2f1fa);
    background: linear-gradient(to bottom, #fbfbfd, #f2f1fa);
}

footer.footer6 {
    position: relative;
    overflow: hidden;
    padding-top: 4.33333rem;
}

footer.footer6:before {
    position: absolute;
    content: "";
    width: 180%;
    height: 150%;
    background: #fff;
    left: -40%;
    top: -137%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

footer.footer6.footer--dark .widget .widget__title2 {
    color: #fff;
}

footer.footer6.footer--dark .footer__bottom .footer__bottom-content {
    border-color: rgba(255, 255, 255, 0.1);
}

footer.footer7 {
    position: relative;
    overflow: hidden;
    /* background: url(img/shape-wave3.png) center/100% 100% no-repeat; */
    padding-top: 4.66667rem;
}

footer.footer7.footer--dark .widget .widget__title2 {
    color: #fff;
}

footer.footer7.footer--dark .footer__bottom .footer__bottom-content {
    border-color: rgba(255, 255, 255, 0.1);
}

.nav-tabs {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0 none;
}

.nav-tabs .nav-item .nav-link {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.nav-tabs .nav-item .nav-link.active {
    background: #4239a4;
    color: #fff;
}

.nav-tabs .nav-item {
    position: relative;
    top: 1px;
    z-index: 1;
    margin: 0;
}

.nav-tabs .nav-item:first-child .nav-link {
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.nav-tabs .nav-item:last-child .nav-link {
    -webkit-border-radius: 0 4px 0 0;
    border-radius: 0 4px 0 0;
}

.nav-pills .nav-item {
    margin-right: 10px;
}

.nav-pills .nav-item:last-child {
    margin-right: 0;
}

.nav-pills .nav-item .nav-link {
    background: rgba(65, 58, 164, 0.1);
    font-weight: 400;
    font-size: 0.93333rem;
    line-height: 3.06667rem;
    padding: 0 30px;
}

.nav-pills .nav-item .nav-link:hover {
    color: #fff;
}

.tab.tab--1 .tab_nav {
    padding: 1.66rem 2.66rem;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
}

.tab.tab--1 .tab_nav .nav ul {
    margin-bottom: 0;
}

.tab.tab--1 .tab_nav .nav ul li {
    line-height: 2rem;
}

.tab.tab--1 .tab_nav .nav .nav-link {
    font-weight: 500;
    text-transform: capitalize;
    padding: 15px 0;
    border-bottom: 1px solid #e4e8ed;
}

.tab.tab--1 .tab_nav .nav .nav-link:last-child {
    border-bottom: 0;
}

.tab.tab--1 .tab_nav .nav .nav-link.active {
    background: 0 0;
}

.tab.tab--1 .tab-content {
    padding-left: 30px;
}

.tab.tab--1 .tab-content .tab-pane {
    background: #fff;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
    overflow: hidden;
    -webkit-box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 20px rgba(105, 106, 119, 0.05);
}

.tab.tab--1 .tab-content .tab-pane figure {
    margin: 0;
}

.tab.tab--1 .tab-content .tab-pane figure figcaption {
    padding: 2.13333rem 2.33333rem 2.33333rem;
}

.tab.tab--1 .tab-content .tab-pane figure figcaption h5 {
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.tab.tab--1 .tab-content .tab-pane figure figcaption a.btn {
    margin-top: 8px;
}

.tab.tab--2 .tab_nav2 .nav,
.tab.tab--6 .tab_nav2 .nav {
    border-bottom: 1px solid #e4e8ed;
}

.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 4rem;
    position: relative;
    color: rgba(32, 36, 40, 0.5);
    padding-left: 2rem;
    padding-right: 2rem;
}

.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link:before,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link:before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    background: #9d9ebc;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.tab.tab--2 .tab_nav2 .nav .nav-item .nav-link.active:after,
.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link.active:after {
    position: absolute;
    content: "";
    width: 1.33333rem;
    height: 1.33333rem;
    background: rgba(6, 181, 144, 0.1);
    -webkit-border-radius: 200px;
    border-radius: 200px;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.tab.tab--3 .tab_nav3 {
    border-bottom: 1px solid #e4e8ed;
}

.tab.tab--3 .tab_nav3 .nav li {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.tab.tab--3 .tab_nav3 .nav li a {
    font-size: 1.06667rem;
    color: #5a6671;
    font-weight: 500;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    padding: 1.4rem 0;
}

.tab.tab--4 .tab--content4 {
    padding: 50px 50px 50px 0;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
}

.tab.tab--4 .tab--content9 {
    padding: 2.66667rem;
}

.tab.tab--4 .tab-content .tab-pane .graph_area {
    margin-right: 35px;
}

.tab.tab--4 .tab-content .tab-pane .text_content p {
    margin-bottom: 0;
}

.tab.tab--6 .tab_nav2 .nav .nav-item .nav-link {
    font-size: 1.2rem;
}

.tab.tab--7 .tab_nav7,
.tab.tab--8 .tab_nav7 {
    border-bottom: 1px solid #e4e8ed;
}

.tab.tab--7 .tab_nav7 .nav .nav-item .nav-link,
.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link {
    font-size: 1rem;
    line-height: 3.33333rem;
    padding: 0rem 2rem;
    position: relative;
    color: #9192a3;
}

.tab.tab--7 .tab_nav7 .nav .nav-item .nav-link span,
.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link span {
    margin-right: 10px;
    font-size: 1.2rem;
}

.tab.tab--7 .tab_nav7 .nav .nav-item .nav-link.active:before,
.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link.active:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.tab.tab--7 .tab--content7,
.tab.tab--8 .tab--content7 {
    margin-top: 35px;
}

.tab.tab--7 .tab--content7 h5,
.tab.tab--8 .tab--content7 h5 {
    margin-bottom: 20px;
}

.tab.tab--8 .tab_nav7 {
    border-bottom: 0;
    border-left: 1px solid #e4e8ed;
}

.tab.tab--8 .tab_nav7 .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link {
    padding: 0rem 1.66667rem;
}

.tab.tab--8 .tab_nav7 .nav .nav-item .nav-link.active:before {
    height: 100%;
    width: 1px;
    left: -1px;
}

.tab.tab--8 .tab-content h5 {
    margin-bottom: 1.33333rem;
}

.tab-content.tab--content2 {
    margin-top: 3.33333rem;
}

.tab-content.tab--content2 .tab-pane .tab_text_module .tab_text_module__subtitle {
    font-size: 1.06667rem;
    font-weight: 400;
    margin-bottom: 5px;
    color: #06b590;
}

.tab-content.tab--content2 .tab-pane .tab_text_module h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
}

.tab-content.tab--content2 .tab-pane .tab_text_module .btn {
    margin-top: 1.26667rem;
}

.tab-content.tab--content2 .tab-pane .tab_text_module ul li {
    color: #202428;
    font-weight: 500;
    line-height: 2.13333rem;
    margin: 0;
}

.tab-content.tab--content2 .tab-pane .tab_image_module--left,
.tab-content.tab--content2 .tab-pane .tab_image_module--right {
    margin-bottom: 0;
}

.tab-content.tab--content2 .tab-pane .tab_image_module--left img,
.tab-content.tab--content2 .tab-pane .tab_image_module--right img {
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.tab_nav4 .nav-tabs li a {
    background: #00008b;
}

.tab_nav5 {
    margin-bottom: 2rem;
}

.tab_event>p {
    color: #413aa4;
    font-weight: 500;
    line-height: 2.53333rem;
    margin-top: 16px;
    margin-bottom: 15px;
}

.tab_event .tab_event__module {
    padding: 32px 40px 2.46667rem;
    -webkit-border-radius: 0.266rem;
    border-radius: 0.266rem;
}

.tab_event .tab_event__module .event_meta {
    margin-bottom: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tab_event .tab_event__module .event_meta p {
    margin-right: 1.46667rem;
    font-size: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.tab_event .tab_event__module .event_meta p span.la {
    font-size: 1.2rem;
    vertical-align: middle;
    margin-right: 8px;
}

.tab_event .tab_event__module h5 {
    color: #202428;
    line-height: 2.53333rem;
    margin-bottom: 0.66667rem;
}

.tab_event .tab_event__module .speakers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    padding-top: 11px;
}

.tab_event .tab_event__module .speakers li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.tab_event .tab_event__module .speakers li>div {
    margin-left: 20px;
}

.tab_event .tab_event__module .speakers li+li {
    margin-left: 30px;
}

.tab_event .tab_event__module .speakers li img {
    -webkit-border-radius: 200px;
    border-radius: 200px;
    height: 50px;
    width: 50px;
}

.tab_event .tab_event__module .speakers li h6,
.tab_event .tab_event__module .speakers li p {
    margin: 0;
    line-height: 1.86667rem;
}

.tab_event .tab_event__module .speakers li p {
    font-size: 14px;
}

.tab_event .tab_event__module .speakers li h5 {
    font-size: 1.2rem;
}

.service--tabs .tab_nav2 .nav {
    padding: 0;
}

.service--tabs .tab_nav2 .nav .nav-link {
    padding: 0.86667rem 1.33333rem;
    color: #202428;
    background: rgba(65, 58, 164, 0.05);
    margin-bottom: 5px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.06667rem;
    font-weight: 500;
}

.service--tabs .tab_nav2 .nav .nav-link:last-child {
    margin-bottom: 0;
}

.service--tabs .tab_nav2 .nav .nav-link.active {
    background: #06b590;
    color: #fff;
}

.testimonial2 .carousel-single .author-spec .author-desc h5 {
    color: #202428;
}

.testimonial6 {
    padding: 5.33333rem 0;
}

.single_testimonial .big--quote {
    font-size: 7.33333rem;
    color: rgba(255, 255, 255, 0.1);
    position: relative;
    left: -13px;
}

.single_testimonial p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.33333rem;
    line-height: 2.4rem;
    margin: 0;
}

.single_testimonial .single_testimonial_meta {
    padding-top: 35px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;
}

.single_testimonial .single_testimonial_meta span {
    font-size: 1.2rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 0.66667rem;
}

.intro-area-11 {
    /* background-image: url(img/intro-bg.png); */
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 3.33333rem;
    position: relative;
}

.intro-area-11:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    background: -webkit-linear-gradient(to right, #fff, #f3f2fa);
    background: -o-linear-gradient(to right, #fff, #f3f2fa);
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(#f3f2fa));
    background: -webkit-linear-gradient(left, #fff, #f3f2fa);
    background: -o-linear-gradient(left, #fff, #f3f2fa);
    background: linear-gradient(to right, #fff, #f3f2fa);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#ffffff, endColorstr=#f3f2fa);
    z-index: -1;
}

.intro-area-11 .menu_area.menu8 {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0 0;
}

.intro-area-two {
    background-image: -webkit-gradient(linear, left top, right top, from(#413aa4), to(#06b590));
    background-image: -webkit-linear-gradient(left, #413aa4, #06b590);
    background-image: -o-linear-gradient(left, #413aa4, #06b590);
    background-image: linear-gradient(to right, #413aa4, #06b590);
    position: relative;
    padding-bottom: 4rem;
}

.intro-area-two:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* background-image: url(img/svg/shape2.svg), -webkit-gradient(linear, left top, right top, from(#413aa4), to(#06b590));
    background-image: url(img/svg/shape2.svg), -webkit-linear-gradient(left, #413aa4, #06b590);
    background-image: url(img/svg/shape2.svg), -o-linear-gradient(left, #413aa4, #06b590);
    background-image: url(img/svg/shape2.svg), linear-gradient(to right, #413aa4, #06b590); */
    background-repeat: no-repeat;
    background-position: right;
    opacity: 0.15;
}

.intro-area-two .menu_area.menu8 {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0 0;
}

.intro-one {
    position: relative;
    min-height: 47.46667rem;
}

.intro-one .intro-one--contents {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.intro-one h1 {
    color: #202428;
    font-weight: 600;
}

.intro-one p {
    font-size: 17px;
    margin: 0;
    line-height: 2.13333rem;
}

.intro-one .btn {
    margin-top: 2.66667rem;
}

.intro-one .intro-one--img {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    background: -webkit-linear-gradient(135deg, #06b590, #413aa4);
    background: -o-linear-gradient(135deg, #06b590, #413aa4);
    background: linear-gradient(-45deg, #06b590, #413aa4);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.intro-two {
    min-height: 53.33333rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .intro-two {
        height: 53.33333rem;
    }
}

.intro-two:before {
    position: absolute;
    content: "";
    width: 89%;
    height: 100%;
    border-top: 800px solid #06b590;
    border-right: 836px solid transparent;
    border-left: 0 solid transparent;
    left: 0;
    top: 0;
}

.intro-two h1 {
    line-height: 4rem;
    color: #fff;
    font-weight: 600;
    margin-bottom: 1.66667rem;
}

.intro-two p {
    color: #fff;
    margin: 0;
    font-size: 1.13333rem;
    line-height: 2.13333rem;
}

.intro-two ul {
    margin: 2.66667rem 0 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.intro-two ul li {
    margin-right: 2rem;
}

.intro-two ul li:last-child {
    margin-right: 0;
}

.intro-three {
    min-height: 45.33333rem;
    position: relative;
    background: -webkit-linear-gradient(135deg, #f7f6fc, #fff);
    background: -o-linear-gradient(135deg, #f7f6fc, #fff);
    background: linear-gradient(-45deg, #f7f6fc, #fff);
    overflow: hidden;
}

.intro-three:before {
    position: absolute;
    content: "";
    width: 80rem;
    height: 93.33333rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    left: -520px;
    bottom: -900px;
}

.intro-three:before {
    background: #0bc49229;
}

.intro-three:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url(../img/shape2.png) top right/auto no-repeat;
}

.intro-three .intro-three--img {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 16.66667rem;
}

.intro-three .intro-three--contents {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
}

.intro-three .intro-three--contents h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    padding-right: 60px;
}

.intro-three .intro-three--contents p {
    font-size: 13px;
    margin: 0;
    line-height: 24px;
    margin-top: 10px;
}

.form-group-mtb {
    margin-bottom: 15px;
}

.formbox {
    box-shadow: none;
    padding: 25px 10px;
    border-radius: 10px;
    background: #fff;
}

.formbox h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

.intro-four {
    position: relative;
    min-height: 40.33333rem;
}

.intro-four:before {
    position: absolute;
    content: "";
    width: 80%;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f1f2f7), to(rgba(241, 242, 247, 0)));
    background: -webkit-linear-gradient(left, #f1f2f7 50%, rgba(241, 242, 247, 0));
    background: -o-linear-gradient(left, #f1f2f7 50%, rgba(241, 242, 247, 0));
    background: linear-gradient(to right, #f1f2f7 50%, rgba(241, 242, 247, 0));
    z-index: 1;
}

.intro-four .intro-four--contents {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.intro-four .intro-four--contents h1 {
    font-weight: 600;
    margin-bottom: 1.66667rem;
}

.intro-four .intro-four--contents p {
    margin: 0;
    font-size: 1.13333rem;
    color: #202428;
    line-height: 2.13333rem;
}

.intro-four .intro-four--contents ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2.66667rem;
}

.intro-four .intro-four--contents ul li {
    margin-right: 2rem;
}

.intro-four .intro-four--contents ul li:last-child {
    margin-right: 0;
}

.intro-five h1 {
    font-weight: 600;
}

.intro-five p {
    font-size: 1.13333rem;
    margin: 0;
    line-height: 2.13333rem;
}

.intro-five .btn {
    margin-top: 2.33333rem;
}

.shape-wrapper {
    position: relative;
}

.shape-wrapper:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.shape-wrapper img {
    width: 100%;
}

.shape-wrapper.img-shape-one:before {
    /* background: url(img/img_shape1.png); */
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.shape-wrapper.img-shape-two:before {
    /* background: url(img/img_shape2.png); */
    -webkit-background-size: 100% 101%;
    background-size: 100% 101%;
    background-repeat: no-repeat;
}

.shape-wrapper.img-shape-three:before {
    /* background: url(img/img_shape3.png); */
    -webkit-background-size: 100% 101%;
    background-size: 100% 101%;
    background-repeat: no-repeat;
    background-position: center;
}

.intro_area9 {
    min-height: 56.66667rem;
    position: relative;
}

.intro_area9 .intro_area9--contents {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.intro_area9 .intro_area9--contents:before {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    background: -webkit-linear-gradient(310deg, #413aa4 0, #413aa4 25%, #06b590 100%);
    background: -o-linear-gradient(310deg, #413aa4 0, #413aa4 25%, #06b590 100%);
    background: linear-gradient(140deg, #413aa4 0, #413aa4 25%, #06b590 100%);
}

.intro_area9 .intro_area9--contents h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    color: #fff;
    font-weight: 600;
    margin: 0 0 2.66667rem;
}

.intro_area9 .intro_area9--contents p {
    font-size: 1.13333rem;
    line-height: 2.13333rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 2.66667rem;
}

.intro_area9 .intro_area9--contents .btn {
    text-transform: uppercase;
}

.intro_area9 .intro_area9--slides {
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.intro_area9 .intro_area9--slides .intro_area9-carousel {
    text-align: center;
}

.intro_area9 .intro_area9--slides .intro_area9-carousel img {
    display: inline-block;
    width: auto;
}

.intro_area9 .intro_area9--slides .intro_area9-carousel .dot-light,
.intro_area9 .intro_area9--slides .intro_area9-carousel .owl-dots {
    height: 2.13333rem;
    background: rgba(6, 181, 144, 0.1);
    -webkit-border-radius: 20rem;
    border-radius: 20rem;
    padding: 0 1.33333rem;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.intro_area9 .intro_area9--slides .intro_area9-carousel .dot-light .owl-dot span,
.intro_area9 .intro_area9--slides .intro_area9-carousel .owl-dots .owl-dot span {
    border: 0 none;
    background: #fff;
}

.intro_area9 .intro_area9--slides .intro_area9-carousel .dot-light .owl-dot.active span,
.intro_area9 .intro_area9--slides .intro_area9-carousel .owl-dots .owl-dot.active span {
    background: #06b590;
}

.intro-area-four {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-bottom: 1px solid #e4e8ed;
    min-height: 56.66667rem;
    position: relative;
}

.intro-area-four .img-left,
.intro-area-four .img-right {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.intro-area-four .img-left {
    left: 0;
}

.intro-area-four .img-right {
    right: 0;
}

.intro-area-four .intro-area-four--contents {
    text-align: center;
}

.intro-area-four .intro-area-four--contents h1 {
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 4.13333rem;
    margin-bottom: 1.33333rem;
}

.intro-area-four .intro-area-four--contents h1 span {
    color: #06b590;
    font-weight: 600;
}

.intro-area-four .intro-area-four--contents p {
    font-size: 1.13333rem;
    line-height: 2.13333rem;
    margin: 0;
}

.intro-form {
    margin: 2.53333rem 0 3.33333rem 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.intro-form .input-group {
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.intro-form .input-group span.icon-left {
    left: 1.33333rem;
}

.intro-form .input-group input {
    -webkit-border-radius: 3px 0 0 3px !important;
    border-radius: 3px 0 0 3px !important;
    -webkit-box-shadow: 0 0.33333rem 0.66667rem rgba(145, 142, 187, 0.1);
    box-shadow: 0 0.33333rem 0.66667rem rgba(145, 142, 187, 0.1);
    padding-left: 3.33333rem;
}

.intro-form button {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    -webkit-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    -webkit-box-shadow: 0 0.33333rem 0.66667rem rgba(145, 142, 187, 0.1);
    box-shadow: 0 0.33333rem 0.66667rem rgba(145, 142, 187, 0.1);
}

.rev_slider {
    direction: lTr;
}

.rev_slider .slotholder:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(65, 58, 164, 0.7);
    z-index: 99;
}

#rev_slider_15_1_wrapper .slotholder:before,
#rev_slider_15_2_wrapper .slotholder:before,
#rev_slider_17_1_wrapper .slotholder:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(32, 36, 40, 0.6);
    z-index: 99;
    left: 0;
    top: 0;
}

.overlay_blue.rev_slider .slotholder:before {
    background: rgba(59, 62, 121, 0.7);
}

.rev_slider_17_1_wrapper .form-wrapper .btn {
    text-align: center;
}

.rev_slider.without_overlay .slotholder:before {
    display: none;
}

#rev_slider_7_1_wrapper .slotholder:before,
#rev_slider_9_1_wrapper .slotholder:before {
    content: none;
}

.custom.tparrows:before,
.hephaistos.tparrows:before,
.hesperiden.tparrows:before,
.tparrows:before {
    font-family: LineAwesome, sans-serif;
    font-size: 20px;
}

.custom.tparrows.tp-leftarrow:before {
    content: "\f120";
}

.custom.tparrows.tp-rightarrow:before {
    content: "\f121";
}

.hephaistos.tparrows.tp-leftarrow:before,
.hesperiden.tparrows.tp-leftarrow:before {
    content: "\f111";
}

.hephaistos.tparrows.tp-rightarrow:before,
.hesperiden.tparrows.tp-rightarrow:before {
    content: "\f112";
}

#rev_slider_27_1_wrapper .hero_btn {
    border-color: #fff;
    color: #fff;
}

#rev_slider_27_1_wrapper .hero_btn:hover {
    border-color: #413aa4;
}

.rev_slider .btn-icon i,
.rev_slider .btn-icon span {
    font-size: 20px;
}

.tp-caption .form-wrapper {
    min-width: 21.33333rem;
}

.erinyen .tp-bullet {
    background: #fff;
    border: 0 none;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 12px;
    height: 12px;
}

.erinyen .tp-bullet.selected,
.erinyen .tp-bullet:hover {
    background: rgba(255, 255, 255, 0.4);
    border: 0 none;
}

.erinyen.tp-bullets:before {
    content: "";
    background: rgba(255, 255, 255, 0.15);
    -webkit-border-radius: 300px;
    border-radius: 300px;
    -webkit-box-shadow: none;
    box-shadow: none;
    left: 0;
}

.zeus.tparrows:before {
    font-family: 'Line Awesome Free';
}

.zeus.tparrows.tp-rightarrow:before {
    content: "\f35a";
}

.zeus.tparrows.tp-leftarrow:before {
    content: "\f359";
}

.hades .tp-thumb-img-wrap {
    background: rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.hades .tp-thumb-image {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.hades .tp-thumb.selected .tp-thumb-img-wrap,
.hades .tp-thumb:hover .tp-thumb-img-wrap {
    background: #06b590;
}

.trumbowyg-box .trumbowyg-editor {
    min-height: 13.33333rem;
}

.separator {
    margin-top: 5.33333rem;
    height: 1px;
    background: #e4e8ed;
    margin-bottom: 5.33333rem;
}

.case_studies {
    position: relative;
}

.bt1 {
    border-top: 1px solid #e4e8ed;
}

.services_area .section_title {
    margin-bottom: 0;
}

.services_area .section_title h1 {
    margin-bottom: 10px;
}

.features-area .section-title {
    text-align: center;
    margin-bottom: 2.66667rem;
}

.features-area .section-title h3 {
    color: #202428;
    margin-bottom: 1.66667rem;
}

.features-area .section-title p {
    color: #202428;
    margin: 0;
}

.service-two {
    padding: 7.33333rem 0 5.33333rem;
    /* background: url(img/shape-wave2.png) center/100% 100% no-repeat; */
}

.p-box {
    padding: 2rem 2.66667rem 2.33333rem;
    background: #377dff;
    -webkit-border-radius: 0.4rem;
    border-radius: 0.4rem;
}

.p-box p {
    margin: 0;
    font-size: 1.13333rem;
    line-height: 2.26667rem;
}

.p-box h4 {
    font-weight: 500;
    line-height: 2.66667rem;
    margin: 0;
    color: #fff;
}

.about-links,
.event-types {
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    position: relative;
    z-index: 1;
}

.about-links ul li,
.event-types ul li {
    margin: 0 15px;
}

.about-links ul li a,
.event-types ul li a {
    font-size: 1.06667rem;
    color: #5a6671;
    padding: 2rem 0;
    display: block;
    font-weight: 500;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.about-links ul li a.active,
.event-types ul li a.active {
    color: #413aa4;
    border-bottom: 2px solid #413aa4;
}

.about-links ul li a:hover,
.event-types ul li a:hover {
    color: #413aa4;
}

.event-types {
    background: #fff;
}

.event-types ul li a {
    padding-left: 50px;
    padding-right: 50px;
}

.search-wrapper form {
    padding: 2.66667rem;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.1);
    background: #fff;
    -webkit-border-radius: 0.4rem;
    border-radius: 0.4rem;
}

.search-wrapper form h4 {
    font-weight: 600;
    color: #413aa4;
    margin-bottom: 1rem;
}

.search-wrapper .form-group .form-control {
    width: auto;
    border: 1px solid #ecf1f7 !important;
    height: 3.66667rem;
    background: #fff;
    margin-right: -1px;
}

.search-wrapper .form-group .form-control:first-child {
    -webkit-box-flex: 3;
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
}

.search-wrapper .form-group .form-control:nth-child(2) {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.search-wrapper .form-group .select-basic {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.search-wrapper .form-group .select-basic .form-control {
    width: 100%;
    height: 3.66667rem;
}

.career-search {
    position: relative;
}

.career-search .search-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.job-categories .card {
    margin-bottom: 2rem;
}

.information-box {
    position: relative;
}

.information-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: -80px;
    z-index: 1;
}

.information-wrapper .card-header {
    padding: 1.8rem 2.13333rem 1.66667rem;
}

.information-wrapper ul li {
    margin-bottom: 2rem;
}

.information-wrapper ul li:last-child {
    margin-bottom: 0;
}

.information-wrapper ul li span {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.information-wrapper ul li span i {
    color: #413aa4;
    padding-right: 0.53333rem;
    font-size: 1.06667rem;
}

.information-wrapper ul li span:first-child {
    color: #202428;
    font-weight: 500;
}

.apply-form .apply-form--header {
    margin-bottom: 3.33333rem;
    text-align: center;
}

.apply-form .apply-form--header h3 {
    margin-bottom: 1rem;
}

.apply-form .apply-form--header p {
    font-size: 1.2rem;
}

.apply-form .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.33333rem;
}

.apply-form .form-group label {
    min-width: 13.33333rem;
    color: #202428;
}

.apply-form .form-group label .sup {
    color: #413aa4;
}

.apply-form .form-group label .optional {
    color: #8c96a0;
}

.apply-form .form-group .select-basic {
    width: 100%;
}

.apply-form form h4 .optional {
    color: #9192a3;
    font-weight: 400;
}

.team-profile .team-details h4 {
    margin-bottom: 0.66667rem;
}

.team-profile .team-details span {
    display: block;
    line-height: 1.7;
}

.team-profile .team-details span strong {
    font-weight: 500;
}

.service-tab-wrapper .download-widget {
    overflow: hidden;
}

.service-tab-wrapper .download-widget .header {
    padding: 1rem;
    text-align: center;
    border-width: 1px 1px 0 1px;
    border-color: #e4e8ed;
    border-style: solid;
}

.service-tab-wrapper .download-widget .header h6 {
    font-weight: 500;
    margin: 0;
}

.service-tab-wrapper .download-widget .content {
    padding: 2rem 2rem 1.13333rem;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 0 0 0.4rem 0.4rem;
    border-radius: 0 0 0.4rem 0.4rem;
}

.service-tab-wrapper .download-widget .content p {
    margin: 1.66667rem 0 0 0;
    text-align: center;
}

.service-tab-wrapper .download-widget .content p span {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    color: #9192a3;
}

.service-tab-wrapper .cta-widget {
    border: 1px solid #e4e8ed;
}

.service-tab-wrapper .cta-widget h6 {
    padding: 1rem;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid #e4e8ed;
    margin: 0;
}

.service-tab-wrapper .cta-widget .content {
    text-align: center;
    padding: 1.33333rem 2rem 2rem;
}

.service-tab-wrapper .cta-widget .content p {
    margin: 0;
}

.service-tab-wrapper .cta-widget .content p>span {
    display: block;
}

.service-tab-wrapper .cta-widget .content p.call-us span:last-child {
    font-weight: 500;
    color: #202428;
}

.service-tab-wrapper .cta-widget .content p.call-us span:last-child i {
    color: #413aa4;
}

.event-details-intro .subtext {
    margin-bottom: 1rem;
}

.event-details-intro h1 {
    line-height: 4rem;
}

.event-details-intro .countdown {
    margin-top: 3.33333rem;
}

.event-overview h4 {
    margin-bottom: 13px;
}

.countdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.countdown li {
    width: 8rem;
    height: 8rem;
    background: rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 1.33333rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.countdown li:last-child {
    margin-right: 0;
}

.countdown li span:first-child {
    font-size: 2.66667rem;
    font-weight: 600;
    color: #fff;
    line-height: 3.33333rem;
}

.countdown li span:last-child {
    color: rgba(255, 255, 255, 0.5);
}

.error-wrapper {
    padding: 7.33333rem 0;
}

.error-wrapper .error-contents figure {
    margin-bottom: 2.26667rem;
}

.error-wrapper .error-contents figure img {
    width: 26.66667rem;
}

.error-wrapper .error-contents h3 {
    color: #202428;
}

.error-wrapper .error-contents p {
    margin: 0 auto;
    width: 33.33333rem;
}

.login-register {
    height: 100%;
    position: relative;
}

.login-register .login-form .form-wrapper,
.login-register .signup-form .form-wrapper {
    padding: 0 2rem;
}

.login-register .login-form .form-wrapper .card .card-header h4,
.login-register .signup-form .form-wrapper .card .card-header h4 {
    margin: 1.33333rem 0;
}

.login-register .login-form .form-wrapper .card .card-body,
.login-register .signup-form .form-wrapper .card .card-body {
    padding-bottom: 4rem;
}

.login-register .login-form .form-wrapper .card .card-body .form-group,
.login-register .signup-form .form-wrapper .card .card-body .form-group {
    margin-bottom: 1.66667rem;
}

.login-register .login-form .form-wrapper .card .card-body .custom-control,
.login-register .signup-form .form-wrapper .card .card-body .custom-control {
    padding-left: 0;
}

.login-register .login-form .form-wrapper .other-logins a,
.login-register .signup-form .form-wrapper .other-logins a {
    line-height: 2.66667rem;
    padding: 0 1.33333rem;
    font-size: 0.93333rem;
    margin-right: 1.33333rem;
    -webkit-border-radius: 0.2rem;
    border-radius: 0.2rem;
}

.login-register .login-form .form-wrapper .other-logins a:first-child,
.login-register .signup-form .form-wrapper .other-logins a:first-child {
    color: #06b590;
    border: 1px solid #06b590;
}

.login-register .login-form .form-wrapper .other-logins a:last-child,
.login-register .signup-form .form-wrapper .other-logins a:last-child {
    margin-right: 0;
    color: #413aa4;
    border: 1px solid #413aa4;
}

.login-register .login-form {
    padding-top: 11.66667rem;
    padding-bottom: 11.66667rem;
}

.login-register .signup-form {
    padding-top: 5.66667rem;
    padding-bottom: 7.33333rem;
}

.coming-soon {
    min-height: 65.33333rem;
}

.coming-soon .cs-contents {
    padding: 10rem 0;
}

.coming-soon .cs-contents .cs-text {
    display: block;
    font-size: 3.33333rem;
    font-weight: 600;
    color: #fff;
    margin: 3.33333rem 0 2.26667rem;
}

.coming-soon .cs-contents p {
    width: 33.33333rem;
    margin: 0 auto 3rem auto;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2rem;
}

.project-list {
    margin-bottom: 2rem;
}

.project-list .card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.project-list .card figure {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.project-list .card .card-body {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    padding: 2.93333rem 3.33333rem;
}

.project-list .card .card-body h5 {
    margin-bottom: 1.66667rem;
}

.project-list .card .card-body h5 a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.project-list .card .card-body h5 a:hover {
    color: #413aa4;
}

.project-masonry .filtr-container-masonry .filtr-item {
    margin-bottom: 2rem;
}

.project-single .project-info .info-box {
    padding: 2.33333rem;
    border: 1px solid #e4e8ed;
    -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
}

.project-single .project-info .info-box h6 {
    margin-bottom: 25px;
}

.project-single .project-info .info-box ul li {
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.project-single .project-info .info-box ul li:last-child {
    margin-bottom: 0;
}

.project-single .project-info .info-box ul li span {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.project-single .project-info .info-box ul li span:last-child {
    font-weight: 500;
    color: #202428;
}

.project-desc h4 {
    margin-bottom: 20px;
}

.project-gallery figure {
    margin-bottom: 2rem;
}

.project-gallery figure img {
    width: 100%;
}

.products-wrapper .product-sort {
    margin-bottom: 2.33333rem;
}

.products-wrapper .product-sort .sort,
.products-wrapper .product-sort .total-pages {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.products-wrapper .product-sort .sort span {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.products-wrapper .product-sort .sort .form-group {
    -webkit-box-flex: 4;
    -webkit-flex: 4;
    -ms-flex: 4;
    flex: 4;
}

.products-wrapper .product-sort .total-pages p {
    margin-bottom: 0;
}

.product-details .product-info h4 {
    margin-bottom: 1rem;
}

.product-details .product-info .product-price {
    font-size: 1.6rem;
    font-weight: 600;
    color: #413aa4;
    display: block;
    margin-bottom: 0.33333rem;
}

.product-details .product-info .product-ratings {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
}

.product-details .product-info .product-ratings p {
    margin: 0;
}

.product-details .product-info .product-ratings p span {
    color: #ffaf00;
}

.product-details .product-info .product-ratings .total-reviews {
    margin-left: 1rem;
}

.product-details .product-info .product-actions {
    margin: 1.33333rem 0;
    padding: 2rem 0;
    border-top: 1px solid #e4e8ed;
    border-bottom: 1px solid #e4e8ed;
}

.product-details .product-info .product-actions form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details .product-info .product-actions form .total-item {
    margin-right: 2rem;
}

.product-details .product-info .product-actions form .total-item .pcount {
    font-size: 16px;
    cursor: pointer;
}

.product-details .product-info .product-actions form .total-item input {
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: 1px solid #9192a3;
}

.product-details .product-info .product-actions form ul li {
    margin-right: 1.33333rem;
}

.product-details .product-info .product-actions form ul li:last-child {
    margin-right: 0;
}

.product-details .product-info .product-category {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details .product-info .product-category span {
    margin-right: 1.33333rem;
}

.product-details .product-info .product-category p {
    margin: 0;
}

.product-info-tab .review-wrapper .reviews {
    margin-bottom: 1.66667rem;
    margin-top: 3.33333rem;
    padding: 0 2.66667rem;
}

.product-info-tab .review-wrapper .reviews .review-single {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 2.33333rem;
    margin-bottom: 2.33333rem;
    border-bottom: 1px solid #e4e8ed;
}

.product-info-tab .review-wrapper .reviews .review-single .review-details {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.product-info-tab .review-wrapper .reviews .review-single .review-details ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0.86667rem;
}

.product-info-tab .review-wrapper .reviews .review-single .review-details ul li:last-child {
    margin-bottom: 0;
}

.product-info-tab .review-wrapper .reviews .review-single .review-details ul li span {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.product-info-tab .review-wrapper .reviews .review-single .review-details ul li .strong {
    -webkit-box-flex: 3;
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
    color: #202428;
    font-weight: 500;
}

.product-info-tab .review-wrapper .reviews .review-single .review-details ul li .strong span {
    color: #ffaf00;
    font-size: 12px;
}

.product-info-tab .review-wrapper .reviews .review-single .review-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.product-info-tab .review-wrapper .review-form {
    padding: 4rem;
}

.product-info-tab .review-wrapper .review-form h4 {
    color: #202428;
    margin-bottom: 0.66667rem;
}

.product-info-tab .review-wrapper .review-form p {
    margin: 0;
}

.product-info-tab .review-wrapper .review-form p a {
    color: #5a6671;
    font-size: 1.2rem;
}

.product-info-tab .review-wrapper .review-form form {
    margin-top: 3rem;
}

.product-info-tab .review-wrapper .review-form form input,
.product-info-tab .review-wrapper .review-form form textarea {
    margin-bottom: 2rem;
}

.product-info-tab .review-wrapper .review-form form .rating span {
    padding-bottom: 0.6rem;
    margin-right: 1rem;
    color: #202428;
    font-weight: 500;
}

.br-theme-fontawesome-stars .br-widget {
    height: 28px;
    white-space: nowrap;
}

.br-theme-fontawesome-stars .br-widget a {
    font-family: LineAwesome, sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    margin-right: 2px;
}

.br-theme-fontawesome-stars .br-widget a:after {
    content: "\f318";
    color: #d2d2d2;
}

.br-theme-fontawesome-stars .br-widget a.br-active:after {
    color: #f2b01e;
}

.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: #f2b01e;
}

.br-theme-fontawesome-stars .br-widget .br-current-rating {
    display: none;
}

.br-theme-fontawesome-stars .br-readonly a {
    cursor: default;
}

@media print {
    .br-theme-fontawesome-stars .br-widget a:after {
        content: "\f318";
        color: #000;
    }

    .br-theme-fontawesome-stars .br-widget a.br-active:after,
    .br-theme-fontawesome-stars .br-widget a.br-selected:after {
        content: "\f318";
        color: #000;
    }
}

.cart-table thead th {
    border-top: 1px solid #e4e8ed;
    vertical-align: middle;
    padding: 1rem 0;
}

.cart-table thead th+th {
    padding-left: 4.66667rem;
}

.cart-table thead th span {
    font-size: 1.06667rem;
    font-weight: 500;
    color: #202428;
}

.cart-table thead th span:last-child {
    text-align: right;
}

.cart-table .cart-single td,
.cart-table .cart-single th {
    vertical-align: middle;
    padding: 1.33333rem 0;
}

.cart-table .cart-single td+td {
    padding-left: 4.66667rem;
}

.cart-table .cart-single .name {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cart-table .cart-single .name a {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.73333rem;
    color: #202428;
    margin-left: 2rem;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.cart-table .cart-single .total-item input {
    width: 3rem;
    height: 3rem;
    border: 1px solid #9192a3;
    text-align: center;
}

.cart-table .cart-single .total-item input[type="number"]::-webkit-inner-spin-button,
.cart-table .cart-single .total-item input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cart-table .cart-single .remove a {
    font-size: 1.33333rem;
}

.cart-table .cart-bottom {
    padding-bottom: 2rem;
}

.cart-table .cart-bottom td {
    padding: 2rem 0;
    border-bottom: 1px solid #e4e8ed;
    vertical-align: middle;
}

.cart-table .cart-bottom form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 24.66667rem;
}

.cart-table .cart-bottom form button {
    margin-left: 2rem;
}

.cart-table .cart-bottom .total-amount span {
    color: #202428;
    font-weight: 500;
}

.cart-table .cart-actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cart-table .cart-actions .ml-auto a:last-child {
    margin-left: 0.46667rem;
}

.cart-table.cart-table--2 thead th {
    padding: 1.66667rem 0;
}

.cart-table.cart-table--2 thead th:last-child {
    padding-left: 4rem;
}

.cart-table.cart-table--2 .cart-bottom td {
    border-bottom: 0;
}

.ukm .filter-item {
    padding-bottom: 30px;
}

.checkout-wrapper .nav-item {
    position: relative;
}

.checkout-wrapper .nav-item:before {
    position: absolute;
    content: "\f186";
    font-family: LineAwesome, sans-serif;
    font-size: 20px;
    right: -75px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(59, 62, 121, 0.5);
}

.checkout-wrapper .nav-item:last-child {
    margin-right: 0;
}

.checkout-wrapper .nav-item:last-child:before {
    content: none;
}

.checkout-wrapper .nav-item .nav-link {
    padding: 0;
}

.checkout-wrapper .tab-content h4 {
    padding-bottom: 1.53333rem;
    margin-bottom: 3.33333rem;
    border-bottom: 1px solid #e4e8ed;
}

.checkout-wrapper .tab-content form .form-group {
    margin-bottom: 1.66667rem;
}

.checkout-wrapper .tab-content form .shipping-method .custom-control {
    padding-left: 0;
}

.checkout-wrapper .tab-content .method-1 {
    border: 1px solid #e4e8ed;
    margin-bottom: 2rem;
}

.checkout-wrapper .tab-content .method-1 .header {
    background: #f5f5f7;
    padding: 1.66667rem 2rem;
}

.checkout-wrapper .tab-content .method-1 .body {
    padding: 2.66667rem;
}

.checkout-wrapper .tab-content .method-1 .body p {
    margin-bottom: 3.33333rem;
}

.checkout-wrapper .tab-content .method-1 .body p img {
    margin-left: 1.33333rem;
}

.checkout-wrapper .tab-content .method-1 .body input {
    margin-bottom: 2rem;
}

.checkout-wrapper .tab-content .method-2 {
    margin-bottom: 2rem;
}

.checkout-wrapper .tab-content .method-2 .header {
    background: #f5f5f7;
    padding: 1.66667rem 2rem;
}

.checkout-wrapper .tab-content .order-info {
    border: 1px solid #e4e8ed;
}

.checkout-wrapper .tab-content .order-info h6 {
    margin: 0 0 2rem 0;
    padding-bottom: 1.33333rem;
    border-bottom: 1px solid #e4e8ed;
}

.checkout-wrapper .tab-content .order-info ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
}

.checkout-wrapper .tab-content .order-info ul li:last-child {
    margin-bottom: 0;
}

.checkout-wrapper .tab-content .order-info ul li span {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.checkout-wrapper .tab-content .order-info ul li span:last-child {
    color: #202428;
}

.checkout-confirm h2 {
    margin-bottom: 1.66667rem;
}

.checkout-confirm p {
    margin-bottom: 2.66667rem;
}

.checkout-confirm form {
    margin-bottom: 3.33333rem;
}

.blog-grid-wrapper .post--card {
    margin-bottom: 2rem;
}

.blog-wrapper .blog-single {
    margin-bottom: 3.33333rem;
}

.blog-grid-masonry .filtr-item {
    margin-bottom: 2rem;
}

.blog-single-wrapper .post-gallery {
    margin-bottom: -2rem;
}

.blog-single-wrapper .post-gallery figure {
    margin-bottom: 2rem;
}

.blog-single-wrapper .post-details img {
    width: 100%;
}

.blog-single-wrapper .post-details .post-head {
    margin-bottom: 2.46667rem;
}

.blog-single-wrapper .post-details .post-content .post-header {
    margin-bottom: 2rem;
}

.blog-single-wrapper .post-details .post-content .post-header h3 {
    color: #202428;
    margin-bottom: 1.33333rem;
}

.blog-single-wrapper .post-details .post-content .post-header ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e4e8ed;
}

.blog-single-wrapper .post-details .post-content .post-header ul li {
    margin-right: 1.33333rem;
}

.blog-single-wrapper .post-details .post-content .post-header ul li:last-child {
    margin-right: 0;
}

.blog-single-wrapper .post-details .post-content .post-header ul li a {
    color: #202428;
}

.blog-single-wrapper .post-bottom {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem 0;
    border-top: 1px solid #e4e8ed;
    border-bottom: 1px solid #e4e8ed;
    margin: 3rem 0 4.66667rem 0;
}

.blog-single-wrapper .post-bottom .tags {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.blog-single-wrapper .post-bottom .tags ul li {
    margin-right: 0.66667rem;
}

.blog-single-wrapper .post-bottom .tags ul li:last-child {
    margin-right: 0;
}

.blog-single-wrapper .post-bottom .tags ul li a {
    line-height: 1.86667rem;
    display: block;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 0.2rem;
    border-radius: 0.2rem;
    padding: 0 0.8rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #5a6671;
    font-size: 0.93333rem;
}

.blog-single-wrapper .post-bottom .tags ul li a:hover {
    color: #fff;
    background: #413aa4;
    border-color: #413aa4;
}

.blog-single-wrapper .post-bottom .social-share {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.blog-single-wrapper .post-author {
    padding: 2.66667rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 4rem;
}

.blog-single-wrapper .post-author .author-thumb {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.blog-single-wrapper .post-author .author-info {
    -webkit-box-flex: 4;
    -webkit-flex: 4;
    -ms-flex: 4;
    flex: 4;
}

.blog-single-wrapper .post-author .author-info h5 {
    color: #202428;
    margin-bottom: 1rem;
}

.blog-single-wrapper .post-author .author-info h5 span {
    color: #413aa4;
}

.blog-single-wrapper .post-pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e4e8ed;
    padding-bottom: 4rem;
}

.blog-single-wrapper .post-pagination .next-post,
.blog-single-wrapper .post-pagination .prev-post {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.blog-single-wrapper .post-pagination .next-post>span,
.blog-single-wrapper .post-pagination .prev-post>span {
    display: block;
}

.blog-single-wrapper .post-pagination .next-post .title,
.blog-single-wrapper .post-pagination .prev-post .title {
    font-size: 16px;
    color: #202428;
    font-weight: 500;
    margin: 0.66667rem 0;
    display: block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.blog-single-wrapper .post-pagination .next-post .title:hover,
.blog-single-wrapper .post-pagination .prev-post .title:hover {
    color: #413aa4;
}

.blog-single-wrapper .post-pagination .next-post p,
.blog-single-wrapper .post-pagination .prev-post p {
    margin: 0;
}

.blog-single-wrapper .post-pagination .next-post {
    text-align: right;
}

.blog-single-wrapper .related-post {
    border-bottom: 1px solid #e4e8ed;
}

.blog-single-wrapper .related-post .related-post--title {
    margin-bottom: 1.8rem;
    text-align: center;
}

.blog-single-wrapper .related-post .related-post--title h4 {
    margin: 0;
}

.blog-single-wrapper .related-post .single-post {
    margin-bottom: 3.33333rem;
}

.blog-single-wrapper .related-post .single-post h6 {
    margin: 1.2rem 0 0.33333rem;
    font-size: 1rem;
}

.blog-single-wrapper .related-post .single-post h6 a {
    color: #202428;
}

.blog-single-wrapper .related-post .single-post p {
    font-size: 0.93333rem;
}

.comments-area .comment-title {
    margin-bottom: 1.66667rem;
    text-align: center;
}

.comments-area .comment-title h3 {
    position: relative;
    margin-bottom: 0;
}

.comments-area .comment-lists ul .depth-1 {
    padding: 2rem 2rem 1.6rem;
    border: 1px solid #e4e8ed;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
    margin-bottom: 2rem;
}

.comments-area .comment-lists ul .depth-1:last-child {
    margin-bottom: 0;
}

.comments-area .comment-lists ul .depth-1 .media {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.comments-area .comment-lists ul .depth-1 .media .media-body {
    margin-left: 1.66667rem;
}

.comments-area .comment-lists ul .depth-1 .media .media-body .media_top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
}

.comments-area .comment-lists ul .depth-1 .media .media-body .media_top h6 {
    margin-bottom: 0.46667rem;
}

.comments-area .comment-lists ul .depth-1 .media .media-body .media_top .reply {
    border: 1px solid #e4e8ed;
    color: #5a6671;
    line-height: 2.26667rem;
    -webkit-border-radius: 1.66667rem;
    border-radius: 1.66667rem;
    display: inline-block;
    font-weight: 500;
    font-size: 0.86667rem;
    padding: 0 1rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.comments-area .comment-lists ul .depth-1 .media .media-body .media_top .reply:hover {
    color: #fff;
    background: #413aa4;
    border-color: #413aa4;
}

.comments-area .comment-lists ul .depth-1 .media p {
    margin: 0;
}

.comments-area .comment-lists ul .depth-1 .depth-2 {
    padding-left: 2.66667rem;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #e4e8ed;
}

.comment-form {
    padding: 3rem 2.66667rem;
}

.comment-form .comment-title h5 {
    color: #202428;
    margin-bottom: 0.66667rem;
}

.comment-form form textarea {
    height: 8.33333rem;
}

.video-background {
    height: 500px !important;
    padding-bottom: 0 !important;
}

.video-background .video-content {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.video-background .video-content h2 {
    color: #fff;
    margin-bottom: 0;
}

.news-area .section-title {
    text-align: center;
    margin-bottom: 2.06667rem;
}

.news-area .section-title h2 {
    color: #202428;
    margin-bottom: 1.06667rem;
}

.news-area .section-title p {
    color: #202428;
}

.about-wrapper .about-contents h3 {
    color: #202428;
    line-height: 2.8rem;
    margin-bottom: 1rem;
}

.about-wrapper .about-contents p {
    margin: 1.33333rem 0 0 0;
}

.about-wrapper .about-contents .sub-title {
    font-size: 1.33333rem;
    font-weight: 500;
}

.team-wrapper {
    /* background: url(img/shape1.png) center/100% 100% no-repeat; */
    padding: 7.33333rem 0;
    position: relative;
}

.team-wrapper:before {
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
    left: 0;
    top: 0;
    background: #f7f6fc;
    z-index: -1;
}

.team-wrapper:after {
    content: "";
    position: absolute;
    width: 70%;
    height: 100%;
    right: 0;
    top: 0;
    background: -webkit-linear-gradient(135deg, #06b590, #413aa4);
    background: -o-linear-gradient(135deg, #06b590, #413aa4);
    background: linear-gradient(-45deg, #06b590, #413aa4);
    z-index: -1;
}

.team-wrapper .title-box {
    background: #fff;
    padding: 4rem;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 0.26667rem;
    border-radius: 0.26667rem;
    -webkit-box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
    box-shadow: 0 5px 30px rgba(41, 41, 59, 0.1);
}

.team-wrapper .title-box h2 {
    color: #202428;
    line-height: 3.2rem;
    margin: 0;
}

.testimonials .owl-carousel {
    margin-top: -1rem;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .menu_area.menu1 .nav_right_content .search_module {
        display: none;
    }

    .top_bar .infos .infos--single {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .top_bar .infos .infos--single+.infos--single {
        margin-left: 25px;
    }

    .footer__small .content-left,
    .footer__small .content-right {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .footer__small .content-right {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    footer.footer4 .footer__small .info_links:before {
        content: none;
    }

    .process-one .process-single:nth-child(2n + 1) .icon:before {
        content: url(../img/arrow-up.png);
    }

    .process-one .process-single .icon:before {
        /* content: url(../img/arrow-down-sm.png); */
    }

    .process-one .process-single .icon:before {
        right: -58%;
    }

    .pricing.pricing--2 {
        display: block !important;
    }

    .event-single {
        height: auto;
    }

    .event-single .event-info .view-btn {
        margin-left: 0;
        margin-top: 1.33333rem;
    }

    .testimonial-carousel-three .card--testimonial2 {
        margin-bottom: 0;
    }

    .testimonials {
        padding-bottom: 3rem;
    }

    .search-wrapper form .form-group button {
        margin: 1rem 0 0 0;
    }

    .service-tab-wrapper .cta-widget {
        padding: 2rem 1rem;
    }

    .other-logins {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .project-list .card figure {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .cart-table .cart-single .name a {
        margin-left: 1.33333rem;
    }

    .card-body {
        padding: 2.3rem;
    }

    .card--testimonial {
        padding: 2.33333rem;
    }

    .card .card-footer {
        padding: 2rem;
    }

    .address-blocks .adress {
        padding: 0;
    }

    .team-wrapper .title-box {
        padding: 2rem;
    }

    .service-tab-wrapper .cta-widget .content {
        padding: 0;
    }

    .pricing.pricing--2 .pricing__features_list {
        padding: 30px;
    }

    .pricing.pricing--2 .pricing__features_list ul.list {
        margin-top: 30px;
    }

    section.intro-area {
        overflow-x: hidden;
    }
}

@media (max-width: 991px) {
    .margin-md-60 {
        margin-bottom: 4rem;
    }

    .margin-md-60 p {
        margin-bottom: 0;
    }

    .menu_area .navbar .navbar-nav .nav-item.dropdown .nav-link:before {
        right: 20px;
        font-size: 13px;
    }

    .menu_area .navbar .navbar-nav .nav-item .nav-link {
        padding: 10px 20px;
        color: #202428;
    }

    .menu_area .navbar.navbar-bg .navbar-nav .nav-item.dropdown .nav-link:before {
        color: inherit;
    }

    .menu_area .navbar-nav .nav-item>.nav-link {
        color: #202428;
    }

    .menu_area .navbar-nav .nav-item>.nav-link.active {
        color: #413aa4;
    }

    .menu_area .dropdown-menu a.dropdown-item {
        padding: 4px 15px;
    }

    .menu_area .dropdown-menu,
    .menu_area .mega-menu {
        width: 100%;
        -webkit-box-shadow: 0 0 0;
        box-shadow: 0 0 0;
        background: #f9f9f9;
        left: auto;
        position: initial;
        padding: 10px 20px;
    }

    .menu_area .dropdown-menu ul,
    .menu_area .mega-menu ul {
        -webkit-box-flex: initial;
        -webkit-flex: initial;
        -ms-flex: initial;
        flex: initial;
        padding: 0 15px;
    }

    .menu_area .dropdown-menu ul+ul,
    .menu_area .mega-menu ul+ul {
        margin-top: 7px;
    }

    .menu_area .dropdown-menu ul+ul>li>h6,
    .menu_area .mega-menu ul+ul>li>h6 {
        margin-top: 24px;
    }

    .menu_area .dropdown-menu ul li,
    .menu_area .mega-menu ul li {
        margin-bottom: 8px;
    }

    .top_bar .infos {
        display: none !important;
    }

    .top_bar .infos .infos--single span {
        display: none;
    }

    .carousel-four .owl-item img,
    .team-carousel-two .owl-item img {
        width: 100%;
    }

    .testimonial-carousel-four .carousel-single .author-text {
        width: 100%;
    }

    .logo-grid-one li,
    .logo-grid-three li {
        width: 33.33%;
    }

    .dropdowns-wrapper .dropdown {
        margin-bottom: 2rem;
    }

    .tab-content.tab--content2 .tab-pane .tab_image_module--left {
        margin: 0 0 2rem 0;
    }

    .tab-content.tab--content2 .tab-pane .tab_image_module--right {
        margin: 2rem 0 0 0;
    }

    .video-single figure img {
        width: 100%;
    }

    .video-wrapper.video--three .video-banner figure figcaption {
        padding: 2rem;
    }

    .accordion-styles.accordion--two .form-box {
        margin-top: 3.33333rem;
    }

    .card--team1 figure img,
    .card--team2 figure img {
        width: 100%;
    }

    .divider.text-center p {
        width: 100%;
    }

    .splitted-banner:after {
        position: absolute;
        content: "";
        width: 50%;
        right: 0;
        top: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }

    .subscribe-four form,
    .subscribe-six form,
    .subscribe-three form {
        margin-top: 2rem;
    }

    .twitter-feeds .feed-single .feed-content {
        width: 100%;
    }

    .quotes-with-image {
        padding-top: 5.33333rem;
    }

    .blockquote5 .author-sign {
        margin-bottom: 1rem;
    }

    .cta--eight p,
    .cta--five p,
    .cta--four p,
    .cta--seven p,
    .cta--six p,
    .cta--two p {
        text-align: center;
    }

    .cta--eight .action-btn,
    .cta--five .action-btn,
    .cta--four .action-btn,
    .cta--seven .action-btn,
    .cta--six .action-btn,
    .cta--two .action-btn {
        text-align: center;
        margin-top: 2rem;
    }

    .cta--five p,
    .cta--seven p,
    .cta--six p {
        line-height: 3rem;
    }

    .cta--seven p {
        line-height: 3.66667rem;
    }

    .contact-split {
        display: block !important;
        height: auto;
    }

    .contact-split .g-maps {
        position: relative;
        width: 100%;
    }

    .contact-split .g-maps #map2 {
        height: 33.33333rem;
    }

    .contact-split .form-wrapper {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .contact--four .form-wrapper {
        margin-bottom: 4rem;
    }

    .block-full-width2:before,
    .block-full-width:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.9);
        z-index: 1;
    }

    .block-full-width .img,
    .block-full-width2 .img {
        width: 100%;
    }

    .content-block--12 .highlighted-text .content {
        padding: 0 3rem;
    }

    .section-split:before {
        width: 100%;
    }

    .section-split .skills {
        margin-top: 4rem;
    }

    .contact--from8 .form-wrapper .select-basic,
    .contact--from8 .form-wrapper input {
        -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
        box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
    }

    .footer__small .content-left,
    .footer__small .content-right {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
    }

    .footer__small .content-left {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        text-align: center;
    }

    .footer__small .content-left p,
    .footer__small .content-left ul {
        width: 100%;
    }

    .footer__small .content-left ul {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .footer__small .content-right {
        margin-top: 1rem;
    }

    footer.footer4 .footer__small .info_links:before {
        content: none;
    }

    .process-one .process-single {
        width: 50%;
    }

    .process-one .process-single .icon:before,
    .process-one .process-single:nth-child(2n + 1) .icon:before {
        content: none;
    }

    .process-three {
        margin-bottom: 2rem;
    }

    .team-single.team-five,
    .team-single.team-four {
        margin-bottom: 2rem;
    }

    .team-single.team-five img,
    .team-single.team-four img {
        width: 100%;
    }

    .pricing.pricing--2 {
        display: block !important;
    }

    .event-single {
        height: auto;
    }

    .event-single .event-info .view-btn {
        margin-left: 0;
        margin-top: 1.33333rem;
    }

    #accordion_one,
    #accordion_two {
        padding: 2rem 2.66667rem;
    }

    .section-news {
        padding-bottom: 6rem;
    }

    .services {
        padding-bottom: 4rem;
    }

    .testimonials {
        padding-bottom: 5rem;
    }

    .section-split2:before {
        content: none;
    }

    .about-links ul,
    .event-types ul {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
        padding: 1rem 0;
    }

    .about-links ul li,
    .event-types ul li {
        margin: 0 2rem;
    }

    .about-links ul li a,
    .event-types ul li a {
        padding: 1rem 0;
    }

    .timeline1 .happening .happening__period:before {
        right: -20px;
    }

    .timeline1 .happening .happening__period .wrapper:before {
        right: -39px;
    }

    .values-info .value-desc p {
        padding-left: 0;
        margin-bottom: 0;
    }

    .search-wrapper form .form-group button {
        margin: 1rem 0 0 0;
    }

    .search-wrapper .form-group .form-control:first-child {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-right: 1px solid #e4e8ed !important;
    }

    .search-wrapper .form-group .select-basic {
        min-width: 100%;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-top: 1rem;
    }

    .search-wrapper .form-group .form-control,
    .search-wrapper .form-group .select-basic .form-control {
        border-right: 0 !important;
    }

    .career-search .search-wrapper {
        position: relative;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .information-box {
        padding: 8rem 0 4rem !important;
    }

    .information-wrapper {
        position: relative;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        top: 0;
    }

    .information-wrapper ul {
        margin-bottom: 1rem;
    }

    .information-wrapper ul li {
        margin-bottom: 1rem;
    }

    .information-wrapper .card {
        border: 1px solid #e4e8ed !important;
    }

    .information-wrapper .card .card-body {
        padding-bottom: 1.66667rem;
    }

    .action-btns ul {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .card.card--team4 {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .card.card--team4 .team-info {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .card.card--team4 .team-info .m-bottom-25 {
        margin-bottom: 15px;
    }

    .card.card--team4 .team-info .social-basic {
        margin-bottom: 25px;
    }

    .card.card--team4 .team-image {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
    }

    .card.card--team4 .team-image img {
        width: 100%;
    }

    .team-profile .team-details {
        margin-left: 0;
    }

    .service-tab-wrapper .service--tabs .sidebar {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .service-tab-wrapper .service--tabs .sidebar .tab_nav2 {
        width: 100%;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .service-tab-wrapper .service--tabs .sidebar .cta-widget,
    .service-tab-wrapper .service--tabs .sidebar .download-widget {
        width: 45%;
        margin-right: 20px;
    }

    .event-share {
        margin-bottom: 2rem;
    }

    .other-logins {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .project-list .card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .project-list .card figure img {
        width: 100%;
    }

    .filter-wrapper .filter-sort2 ul li {
        margin-bottom: 1rem;
    }

    .checkout-wrapper .nav-item {
        margin: 0 3.33333rem !important;
    }

    .checkout-wrapper .nav-item:before {
        content: none;
    }

    .widget-wrapper:last-child .widget-default {
        margin-bottom: 0;
    }

    .cart-table .cart-single .name a {
        margin-left: 0;
        margin-top: 0.66667rem;
    }

    .cart-table--2 .cart-single .name a {
        margin-left: 1.33333rem;
    }

    .hr-divider-gray {
        margin-top: 3.66667rem;
    }

    .products-wrapper .product-sort .sort .form-group {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .total-pages {
        display: none;
    }

    .products-wrapper .product-grid {
        margin-bottom: 50px;
    }

    .content_block3_wrapper {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .team-wrapper .title-box {
        padding: 2rem;
    }

    .team-carousel-three {
        margin-top: 2rem;
    }

    .intro-one .intro-one--contents {
        margin-bottom: 3.33333rem;
    }

    .intro-one .intro-one--contents,
    .intro-one .intro-one--img,
    .intro-three .intro-three--contents,
    .intro-three .intro-three--img {
        position: static;
        width: 100%;
    }

    .intro-three .intro-three--img {
        z-index: 1;
        position: relative;
        margin-top: 3.33333rem;
    }

    .intro_area9 .intro_area9--contents,
    .intro_area9 .intro_area9--slides {
        width: 100%;
        position: relative;
        right: auto;
        top: auto;
        padding: 6.66667rem 0;
    }

    .intro_area9 .intro_area9--contents:before {
        width: 100%;
    }

    .shape-wrapper.img-shape-one:before {
        -webkit-background-size: 100% 100.2%;
        background-size: 100% 100.2%;
    }

    .intro-area-four {
        min-height: 46.66667rem;
    }

    .intro_area9 .intro_area9--contents h1 br {
        display: none;
    }

    .tp-caption .form-wrapper {
        display: none;
    }

    .intro-four {
        min-height: 33.33333rem;
    }

    .intro-area-four .img-left,
    .intro-area-four .img-right {
        max-width: 10rem;
    }

    .intro-two {
        min-height: 550px;
    }

    .navbar.navbar-bg .navbar-toggler {
        color: #fff;
    }

    .breadcrumb3 {
        padding: 80px 0;
    }

    .search-wrapper form {
        border: 1px solid #e4e8ed;
    }

    .search-wrapper .form-group .form-control,
    .search-wrapper .form-group .select-basic .form-control {
        border: 1px solid #e4e8ed !important;
    }

    .subscribe--five h2 {
        margin-top: 2rem;
    }

    .pricing.pricing--2 .pricing__features_list {
        padding: 30px;
    }

    .pricing.pricing--2 .pricing__features_list ul.list {
        margin-top: 30px;
    }

    .subscribe-six h4,
    .subscribe-three h4 {
        margin-bottom: 30px;
    }

    .subscribe-six h4 {
        margin-bottom: 0;
    }

    .tab.tab--1 .tab_nav {
        padding: 10px 20px;
    }

    .menu6 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before,
    .menu7 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before,
    .menu8 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link,
    .menu8 .navbar.navbar-dark .navbar-nav .nav-item.dropdown .nav-link:before {
        color: #202428;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    html {
        font-size: 14.5px;
    }

    .gallery-fullwidth .portfolio-carousel-single {
        width: 33.33%;
    }

    .content-block .content-list--one,
    .content-block .content-list--two {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .content-block .content-list--one .content-list,
    .content-block .content-list--two .content-list {
        width: 50%;
    }

    .content-block .content-list--one .content-list:nth-child(2n + 2),
    .content-block .content-list--two .content-list:nth-child(2n + 2) {
        padding-left: 15px;
    }

    .content-block .content-list--one .content-list:nth-child(2n + 1),
    .content-block .content-list--two .content-list:nth-child(2n + 1) {
        padding-right: 15px;
    }

    .content-block--12 .highlighted-text .content {
        min-height: 20rem;
    }

    .content-block--12 .highlighted-text .content p {
        font-size: 1.66667rem;
        line-height: 2.66667rem;
    }
}

@media screen and (max-width: 767px) {
    .margin-sm-60 {
        margin-bottom: 4rem;
    }

    .margin-sm-60 p {
        margin-bottom: 0;
    }

    .card-body {
        padding: 2rem;
    }

    .blog-carousel-two .owl-item img,
    .carousel-one .owl-item img,
    .carousel-two .owl-item img {
        width: 100%;
    }

    .carousel-three .nav-circle .owl-prev {
        margin-left: 0;
    }

    .carousel-three .nav-circle .owl-next {
        margin-right: 0;
    }

    .logo-carousel-three .nav-square-dark .owl-prev {
        margin-left: 0;
    }

    .logo-carousel-three .nav-square-dark .owl-next {
        margin-right: 0;
    }

    .logo-grid-one li,
    .logo-grid-three li {
        width: 50%;
    }

    .gallery-fullwidth .portfolio-carousel-single {
        width: 50%;
    }

    .gallery-grid figure img {
        width: 100%;
    }

    .tab.tab--1 .tab-content {
        padding-left: 0;
        margin-top: 2rem;
    }

    .tab-content.tab--content2 .tab-pane .tab_image_module {
        margin-top: 2rem;
    }

    .tab.tab--4 .tab--content4 {
        padding: 2.66667rem;
    }

    .tab.tab--4 .tab--content4 .text_content {
        margin-top: 2rem;
        padding-left: 0;
    }

    .tab.tab--4 .nav-tabs li {
        width: 100%;
        margin-bottom: 1px;
    }

    .tab.tab--8 .tab_nav7 {
        margin-bottom: 2rem;
    }

    .card figure img,
    .card--ten figure img {
        width: 100%;
    }

    .section-title-simple {
        width: 100%;
    }

    .social-btns li {
        margin-bottom: 1.33333rem;
    }

    .counter--1,
    .counter--2,
    .counter--3,
    .counter--4,
    .counter--6 {
        padding-bottom: 4.66667rem !important;
    }

    .counter--1 .counter_single,
    .counter--2 .counter_single,
    .counter--3 .counter_single,
    .counter--4 .counter_single,
    .counter--6 .counter_single {
        width: 50%;
        margin-bottom: 3.33333rem;
    }

    .breadcrumb_area.breadcrumb1 .page_title {
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    .breadcrumb3 .breadcrumb_wrapper .page_title,
    .breadcrumb_area.breadcrumb2 .page_title {
        width: 100%;
    }

    .breadcrumb_area.breadcrumb1 .breadcrumb_wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .content-block--12 .highlighted-text {
        position: relative;
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        padding: 5.33333rem 0;
    }

    .content-block--12 .clients-logo {
        padding-top: 0;
    }

    .event-single {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .event-single .event-date {
        padding: 2rem 0;
    }

    .event-single .event-date,
    .event-single .event-info {
        width: 100%;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .header--6 .nav_right_content .cart_module,
    .header--6 .nav_right_content .search_module {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .header--6 .nav_right_content .ctabtn {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        padding-left: 0 !important;
        padding-right: 20px !important;
    }

    .nav_right_content .cart_module .cart__items {
        width: 250px;
        min-width: auto;
        right: 0;
    }

    .nav_right_content .cart_module .cart__items .cart_info a+a {
        margin-left: 0;
        margin-top: 10px;
    }

    .error-wrapper .error-contents p {
        width: 100%;
    }

    .home8-counter {
        padding-bottom: 0;
    }

    .timeline1 .happening .happening__period:before {
        content: none;
    }

    .timeline1 .happening .happening__period .wrapper:before {
        content: none;
    }

    .timeline1 .happening .happening__desc {
        margin-top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .timeline1 .happening:last-child .happening__desc {
        padding-bottom: 0;
    }

    .search-wrapper .form-group .form-control:first-child {
        border-right: 0 !important;
        margin-bottom: 1rem;
    }

    .action-btns ul li {
        margin-bottom: 0.66667rem;
    }

    .career-search .search-wrapper .form-group .form-control:first-child {
        border-right: 1px solid #e4e8ed !important;
    }

    .career-search .search-wrapper form {
        padding: 30px;
    }

    .service-tab-wrapper .service--tabs .sidebar .cta-widget,
    .service-tab-wrapper .service--tabs .sidebar .download-widget {
        width: 100%;
    }

    .service-tab-wrapper .service--tabs .sidebar .download-widget {
        text-align: center;
    }

    .service-tab-wrapper .service--tabs .sidebar .download-widget .content p {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .login-register .login-form .form-wrapper .other-logins,
    .login-register .signup-form .form-wrapper .other-logins {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .login-register .login-form .form-wrapper .other-logins a,
    .login-register .signup-form .form-wrapper .other-logins a {
        margin: 10px 0;
    }

    .project-single .project-info .info-box {
        margin-top: 3rem;
    }

    .blog-single-wrapper .post-bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
        -webkit-box-align: start;
        -webkit-align-items: start;
        -ms-flex-align: start;
        align-items: start;
    }

    .blog-single-wrapper .post-bottom .social-share {
        margin-top: 1.33333rem;
    }

    .cart-table .cart-actions .ml-auto {
        margin-left: 0 !important;
        margin-top: 1rem;
    }

    .checkout-wrapper .nav-item {
        width: 100%;
        margin: 0 !important;
    }

    .tab.tab--6 .tab_nav2 .nav .nav-item .nav-link:before {
        bottom: auto;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        left: auto;
        right: 0;
    }

    .gallery-image-view .portfolio-carousel-single img {
        width: 100%;
    }

    .product-info-tab .review-wrapper .reviews {
        padding: 0;
    }

    .nav-circle button {
        width: 3rem;
        height: 3rem;
    }

    textarea.form-control {
        height: 200px;
    }

    html {
        font-size: 14px;
    }

    .mb-sm-50 {
        margin-bottom: 50px;
    }

    .services_area .icon-box-five {
        padding: 0;
        -webkit-box-shadow: 0 0 0;
        box-shadow: 0 0 0;
    }

    .event-single .event-info {
        padding: 2rem 2.33333rem;
    }

    .nav_right_content .search_module {
        position: initial;
    }

    .nav_right_content .search_module .search_area {
        width: 100%;
        right: 0;
    }

    .nav_right_content .search_module .search_area.active {
        top: 100%;
    }

    .cta--nine .cta--none-contents {
        text-align: center;
    }

    .cta--nine .cta--none-contents h3 {
        margin-top: 2rem;
    }

    .about-wrapper img {
        margin-bottom: 2rem;
    }

    .about-wrapper .line-chart_wrap {
        margin-top: 2rem;
    }

    .team-carousel-three .carousel-single .team-desc {
        padding: 2rem;
    }

    .team-carousel-three .carousel-single .team-img {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .intro-three,
    .intro-two {
        padding: 5.33333rem 0;
    }

    .intro-two .form-wrapper {
        margin-top: 3.33333rem;
    }

    .image-carousel-one .nav-circle button {
        width: 2.33333rem;
        height: 2.33333rem;
        font-size: 0.86667rem;
    }

    .text-box {
        margin-top: 0;
    }

    .tab.tab--2 .tab_nav2 .nav .nav-item .nav-link,
    .tab.tab--6 .tab_nav2 .nav .nav-item .nav-link {
        line-height: 2rem;
    }

    .tab.tab--2 .tab_nav2 .nav .nav-item .nav-link.active:after,
    .tab.tab--6 .tab_nav2 .nav .nav-item .nav-link.active:after {
        content: none;
    }

    .checkout-wrapper .tab-content .method-1 .body p img {
        margin-left: 0;
        margin-top: 10px;
    }

    .quotes-with-image .blockquote5 {
        margin-top: 80px;
    }

    .quotes-with-image .blockquote5 .author-sign {
        margin-bottom: 0;
    }

    .nav-circle .owl-next,
    .nav-circle .owl-prev {
        margin: 0;
    }

    .timeline1 .happening .happening__period .wrapper:after {
        content: none;
    }
}

@media (min-width: 320px) and (max-width: 575.98px) {

    .logo-grid-one li,
    .logo-grid-three li {
        width: 100%;
    }

    .gallery-fullwidth .portfolio-carousel-single {
        width: 100%;
    }

    .tab.tab--4 .nav-tabs li {
        width: 100%;
    }

    .tab.tab--4 .nav-pills li {
        margin-top: 1rem;
    }

    .tab.tab--7 .tab_nav7 .nav .nav-item,
    .tab.tab--8 .tab_nav7 .nav .nav-item {
        width: 100%;
    }

    .tab.tab--3 ul.nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .tab.tab--3 .tab_nav3 {
        padding-bottom: 1rem;
    }

    .tab.tab--3 .tab_nav3 .nav li a {
        padding: 0 0 1rem 0;
    }

    .breadcrumb {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .breadcrumb li {
        margin-bottom: 0.66667rem;
    }

    .breadcrumb li:last-child {
        margin-bottom: 0;
    }

    .counter--1 .counter_single,
    .counter--2 .counter_single,
    .counter--3 .counter_single,
    .counter--4 .counter_single,
    .counter--6 .counter_single {
        width: 100%;
    }

    .subscribe--two .subscribe-form,
    .subscribe-six .subscribe-form,
    .subscribe-three .subscribe-form {
        display: block !important;
    }

    .subscribe--two .subscribe-form button,
    .subscribe-six .subscribe-form button,
    .subscribe-three .subscribe-form button {
        margin: 1.33333rem 0 0 0;
    }

    .process-one .process-single {
        width: 100%;
    }

    .portfolio-carousel .owl-item img {
        width: 100%;
    }

    .pagination-area .pagination {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .filter-wrapper .filter-sort ul {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .error-wrapper {
        padding: 8rem 0;
    }

    .error-wrapper .error-contents .error-404 {
        font-size: 8rem;
    }

    .error-wrapper .error-contents h2 {
        font-size: 1.66667rem;
        line-height: 2.33333rem;
    }

    .apply-form form .form-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .apply-form form .form-group label {
        min-width: 100%;
    }

    .countdown {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .countdown li {
        margin: 0 0.66667rem 1.33333rem !important;
    }

    .tab_event .tab_event__module .speakers {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .tab_event .tab_event__module .speakers li {
        margin-bottom: 1.33333rem;
    }

    .tab_event .tab_event__module .speakers li:last-child {
        margin-bottom: 0;
    }

    .tab_event .tab_event__module .speakers li+li {
        margin-left: 0;
    }

    .login-register .login-form .form-wrapper .form-action {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .login-register .login-form .form-wrapper .form-action .custom-control {
        padding-left: 0;
        margin-bottom: 1rem;
    }

    .coming-soon .cs-contents p {
        width: 100%;
    }

    .coming-soon .cs-contents form .subscribe-form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .coming-soon .cs-contents form .subscribe-form button {
        margin-left: 0;
        margin-top: 1rem;
    }

    .related-post .single-post img {
        width: 100%;
    }

    .blog-single-wrapper .post-pagination {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .blog-single-wrapper .post-pagination .next-post {
        margin-top: 1rem;
    }

    .product-info-tab .review-wrapper .reviews .review-single {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .product-info-tab .review-wrapper .reviews .review-single .review-text {
        margin-top: 2rem;
    }

    .product-details .product-info .product-share {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start !important;
        -webkit-align-items: start !important;
        -ms-flex-align: start !important;
        align-items: start !important;
    }

    .product-details .product-info .product-share>span {
        margin-bottom: 1rem;
    }

    .rev_column {
        padding: 0 15px !important;
    }

    .go_top {
        line-height: 30px;
        width: 30px;
        font-size: 13px;
    }

    .services_area .icon-box-five {
        text-align: center;
        margin-bottom: 50px;
    }

    .intro-one .intro-one--contents {
        padding-top: 40px;
    }

    .display-3 {
        font-size: 2.5rem;
    }

    .login-register .login-form .form-wrapper .other-logins a,
    .login-register .signup-form .form-wrapper .other-logins a {
        width: 100%;
        text-align: center;
    }

    .list-inline-wrapper .list--inline {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .p-top-70 {
        padding-top: 50px;
    }

    .p-bottom-70 {
        padding-bottom: 50px;
    }

    .p-top-105 {
        padding-top: 75px;
    }

    .p-bottom-75 {
        padding-bottom: 50px;
    }

    .content-block--15 {
        padding: 73px 0 62px;
    }

    .testimonial-carousel-six-wrapper {
        padding: 6.06667rem 0 5.86667rem 0;
    }

    .p-top-100 {
        padding-top: 70px;
    }

    .subscribe-seven {
        padding: 50px 0;
    }

    footer .footer__big {
        padding: 75px 0 20px;
    }

    .service-two {
        padding: 80px 0 50px;
    }

    .p-top-110,
    .p-top-115,
    .p-top-120 {
        padding-top: 80px;
    }

    .p-bottom-100,
    .p-bottom-105,
    .p-bottom-110,
    .p-bottom-120,
    .p-bottom-95 {
        padding-bottom: 80px;
    }

    .team-wrapper+.p-top-50 {
        padding-top: 0;
    }

    .subscribe-eight {
        padding: 80px 0 0;
    }

    .services_area.p-bottom-70 {
        padding-bottom: 30px;
    }

    .section-padding {
        padding: 80px 0;
    }

    .testimonials {
        padding-bottom: 40px;
    }

    .p-bottom-90 {
        padding-bottom: 70px;
    }

    .p-bottom-80 {
        padding-bottom: 50px;
    }

    .block-full-width,
    .block-full-width2 {
        min-height: auto;
        padding-bottom: 50px;
    }

    .block-full-width2 {
        padding-top: 50px;
    }

    .counter.counter--3 {
        padding: 80px 0 30px !important;
    }

    .testimonial-carousel-four {
        padding: 80px 0 70px;
    }

    .counter--1,
    .counter--2,
    .counter--3,
    .counter--4,
    .counter--6 {
        padding-bottom: 28px !important;
    }

    .subscribe-four {
        padding: 80px 0;
    }

    .testimonial6 .single_testimonial.p-bottom-50 {
        padding-bottom: 0;
    }

    .accordion-styles .accordion_four {
        margin-top: 50px;
    }

    .counter.counter--5 {
        padding: 80px 0 55px;
    }

    .splitted-banner {
        padding: 80px 0;
    }

    .p-bottom-55 {
        padding-bottom: 25px;
    }

    .logo-carousel {
        padding: 80px 0;
    }

    .m-top-110 {
        margin-top: 80px;
    }

    .section-padding2 {
        padding: 80px 0 50px;
    }

    .project-grid-3.section-padding2 {
        padding-bottom: 50px;
    }

    .m-bottom-120 {
        margin-bottom: 80px;
    }

    .information-box {
        padding: 80px 0 60px !important;
    }

    .action-btns ul li {
        margin-bottom: 0;
    }

    .action-btns ul li.share-btn {
        padding-bottom: 0;
    }

    .checkout-confirm {
        padding: 72px 0 80px;
    }
}

@media screen and (max-width: 479px) {
    html {
        font-size: 13px;
    }

    body {
        font-size: 14px;
    }

    .top_bar .top_bar--lang {
        display: none;
    }

    .top_bar .topbar_content {
        /* -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -ms-flex-pack: center !important; */
        justify-content: space-between !important;
    }

    .menu6 .ctabtn,
    .menu6 .search_module {
        display: none;
    }

    .menu1 .navbar-brand {
        -webkit-box-ordinal-group: 1 !important;
        -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
    }

    .menu1 .nav_right_content {
        display: none !important;
    }

    .menu5 {
        padding: 12px 0;
    }

    .menu5 .navbar-brand {
        -webkit-box-ordinal-group: 1 !important;
        -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
        order: 0 !important;
        width: 50%;
    }

    .menu5 .nav_right_content {
        display: none !important;
    }

    .navbar .navbar-brand {
        width: 40%;
    }

    .breadcrumb_area.breadcrumb2 {
        padding-top: 4rem;
    }

    .breadcrumb_area.breadcrumb2 .page_title {
        margin-bottom: 3rem;
    }

    .showcase-box .showcase-body,
    .showcase-box .showcase-header {
        padding: 2rem;
    }

    .cta-wrapper {
        padding: 6rem 0;
    }

    .card .card-body {
        padding: 2rem;
    }

    .card .card-footer {
        padding: 1.66rem;
    }

    .cta--nine:before,
    footer.footer6:before {
        content: none;
    }

    footer.footer6 {
        padding-top: 0;
    }

    footer .footer__bottom .footer__bottom-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    footer .footer__bottom .footer__bottom-content p {
        text-align: center;
        margin-bottom: 1rem;
    }

    .play-btn.play-btn--lg {
        width: 3.33333rem;
        height: 3.33333rem;
    }

    .play-btn.play-btn--lg .svg {
        width: 15px;
    }

    .nav-pills .nav-item {
        margin-bottom: 10px;
    }

    .coming-soon .cs-contents .cs-text {
        font-size: 2.33333rem;
    }

    .content-block--12 .highlighted-text .content {
        padding: 15px 20px;
    }

    .subscribe-form-two {
        padding: 0;
    }

    .subscribe-form-two .btn {
        position: static;
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        margin-top: 15px;
    }

    .subscribe-form-two .form-control {
        padding: 0 15px;
    }
}

@media (min-width: 480px) and (max-width: 575px) {
    html {
        font-size: 14px;
    }
}


.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 1.2rem;
    font-size: 14px;
    line-height: 30px;
}

.w-65 {
    width: 150px !important;
    object-fit: contain;
    height: auto;
}

.talkus {
    background: #0ac391;
    color: #fff !important;
    padding: 10px 25px;
    border-radius: 5px;
}

.bordermg {
    border-top-left-radius: 80px !important;
    border-bottom-right-radius: 80px !important;
}

.qr-codeimg img {
    padding: 0px;
    width: 150px;
    background: #fff;
    border-radius: 10px;
    border: none;
    margin-top: 0px;
    object-fit: cover;
}

.whyBoxUse {
    background-color: #0ac391;
    padding: 30px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px #0003;
    border-radius: 14px;
}

.whyBoxUse h3 {
    color: #fff;
}

.whyBoxUse p {
    color: #fff;
    margin-bottom: 0px;
    line-height: 23px;
}

/*******************************************
    18. Contact CSS Here 
*****************/

.pt-120 {
    padding-top: 120px;
}

.mb-40 {
    margin-bottom: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.back-btn.secondary:hover {
    color: #413aa4;
    background: #ffffff;
}

.back-btn.secondary {
    background: #413aa4;
    border: 1px solid #413aa4 !important;
    color: #ffffff;
    border: none;
}

.back-contact {
    background: url(../img/contact-bg.png) no-repeat center center;
    background-size: cover;
    padding-bottom: 120px;
}

.back-contact .back-sec-title .back-title {
    font-size: 40px;
    line-height: 1;
}

.back-contact .back-sec-title .back-subtitle {
    padding-bottom: 10px;
}

.back-contact .contact-form-part {
    min-width: 600px;
    background: #ffffff;
    padding: 60px 70px 70px 60px;
    margin: 72px 0 0 -140px;
    box-shadow: 0px 30px 60px 0px rgba(13, 30, 80, 0.1);
}

.back-contact .contact-form-part #contact-form .col-sm-12 {
    padding-right: 5px;
}

.back-contact .contact-form-part #contact-form .form-button {
    padding-top: 10px;
}

.back-contact .contact-form-part .form-field {
    margin-bottom: 20px;
    position: relative;
}

.back-contact .contact-form-part .form-field i {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
}

.back-contact .contact-form-part .form-field input {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    color: #666666;
    background: #f0f1f5;
    border: 2px solid #f0f1f5;
}

.back-contact .contact-form-part .form-field input:focus {
    background: #ffffff;
    border: 2px solid #413aa4;
    outline: none;
    box-shadow: none;
}

.back-contact .contact-form-part .form-field textarea {
    width: 100%;
    padding: 20px 30px;
    color: #666666;
    background: #f0f1f5;
    border: 2px solid #f0f1f5;
    resize: none;
}

.back-contact .contact-form-part .form-field textarea:focus {
    background: #ffffff;
    border: 2px solid #11aba0;
    outline: none;
    box-shadow: none;
}

.back-contact .contact-form-part .form-button button {
    outline: none;
    height: 48px;
    padding: 9px 30px;
    font-size: 500;
    text-transform: cabacktalize;
    cursor: pointer;
}

.back-contact .contact-form-part .form-button button:hover {
    box-shadow: unset;
}

.back-contact-box {
    background: url(../img/contact-bg2.jpg) no-repeat center top;
    background-size: cover;
    padding: 126px 0 130px;
}

.back-contact-box .contact-box {
    background: #ffffff;
    display: inline-block;
    width: 100%;
    padding: 46px 40px 40px 40px;
    text-align: center;
}

.back-contact-box .contact-box .bg-phone {
    background: #413aa4;
}

.back-contact-box .contact-box span {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.back-contact-box .contact-box span.icon-part {
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 100%;
    margin-bottom: 20px;
}

.back-contact-box .contact-box span.icon-part img {
    width: 52px;
    height: auto;
}

.back-contact-box .contact-box span.text-part a {
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    color: #0d1e50;
}

.back-contact-box .contact-box span.text-part a:hover {
    color: #11aba0;
}

.back-contact-box .contact-box-btn .contact-btn {
    font-size: 18px;
    font-weight: 500;
    padding: 20px 60px 16px;
}

.back-contact-box.back-contact-box-two {
    /* background: url(../img/contact-bg3.jpg) no-repeat center top; */
    background-size: cover;
}

.white-color {
    color: #ffffff !important;
}

.back-sec-title .back-subtitle {
    margin: 0 0 18px;
    font-size: 18px;
}

.primary-bg {
    background: #00c18d !important;
}

.teamimg {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
}

.display-5 {
    font-size: 30px;
    font-weight: 400 !important;
    line-height: 40px;
}

.default-section {
    padding: 100px 0 70px;
}

.features-col {
    margin-bottom: 30px;
}

.bdr-none {
    border: #EEEEEE !important;
}

.features-col {
    padding-left: 40px;
    border-left: 1px solid #dddddd;
}

.features-col img {
    margin-bottom: 20px;
    margin-top: 30px;
}

footer .footer__big .widget ul {
    text-align: center;
}

.knowteamimg {
    max-width: 80%;
    margin: 0px auto;
    display: block;
}


@media (min-width: 320px) and (max-width: 991px) {
    .back-contact .contact-form-part {
        min-width: 100%;
        background: #ffffff;
        padding: 20px;
        margin: 19px 0 0 0px;
        box-shadow: 0px 30px 60px 0px rgb(13 30 80 / 10%);
    }

    .back-contact .back-sec-title .back-title {
        font-size: 30px;
        line-height: 1;
    }

    .orderimg {
        order: -1;
    }

}


.lead {
    font-size: 16px;
}

.lead {
    color: #7d8282;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
}

.navbar {
    padding: 0rem;
}

.link_nav {
    padding-left: 30px !important;
}

.wAuto {
    width: auto;
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: rgb(255 255 255);
    color: var(--bs-table-hover-color);
}

.table-hover tbody tr:hover {
    background-color: rgb(255 255 255 / 50%);
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: inline-block;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: white;
    background-clip: border-box;
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    border: 1px solid transparent;
    box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
    border-radius: 0.3rem;
}

.card-header {
    background-color: rgb(255 255 255 / 3%);
}

.lendersignup .input-group {
    width: 50%;
}

.widthHundred {
    width: 100% !important;
}

.widthHundred .btn {
    line-height: 32px;
}

.media-list .card .card-body .card-title {
    margin-bottom: 0px;
}

.table-responsive .dropdown-item {
    padding: 10px 15px;
    padding-bottom: 5px;
}

.tabsbtm .nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.breadcrumb li {
    font-size: 18px;
}

.dropdown-item {
    padding: 10px 15px;
}

.main-contact-info-body .media-body {
    margin-left: 0px;
}

.bgwhite {
    background-color: #fff;
}

.accordion_four .accordion-single {
    margin-bottom: 5px;
}

.accordion_four .accordion-heading h6 a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 2.66667rem 1rem 2rem;
    background: #fff;
    color: #202428;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 1.46667rem;
    font-size: 1rem;
    font-weight: 500;
}

.accordion_four .accordion-heading h6 a[aria-expanded="true"] {
    background: #06b590;
    color: #fff;
}

.accordion_four .accordion-heading h6 a[aria-expanded="true"]:before {
    content: "\-";
    color: #fff;
    font-size: 26px;
}

.accordion_four .accordion-heading h6 a[aria-expanded="true"] i {
    color: #fff;
}

.accordion_four .accordion-heading h6 a:before {
    position: absolute;
    content: "\2b";
    font-family: LineAwesome;
    right: 2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #9192a3;
}

.accordion_four .accordion-heading h6 a i {
    font-size: 1.4rem;
    color: #06b590;
    margin-right: 1rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.accordion_four .accordion-contents {
    padding: 1.33rem 2rem 0.66rem;
}

.accordion_four .collapsed.bg-gray-light {
    background: #f5f4f7;
}

.VisibleOve {
    overflow: visible;
}

.fontSize24 h6 {
    font-size: 24px;
}

.NewCHeckBx {
    height: auto !important;
    margin: 0px auto;
    padding: 0px !important;
    margin-bottom: 0px !important;
}

.dno {
    display: none !important;
}

.dbolo {
    display: block !important;
}

.diclamerpage input {
    display: none;
}

.diclamerpage input {
    display: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E);
}

.diclamerpage .custom-control-input:checked~.custom-control-label::after {
    font-family: initial;
    left: 0px;
    content: "";
    font-size: inherit;
    text-align: center;
    top: 0px;
    font-weight: 600;
}

.digitalSignature .file-browserinput {
    display: none;
}